import React, { useState, useEffect, useRef, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import numeral from "numeral";
import moment from 'moment';
import { TitleCase, FormatPhone, } from "../common/functions"
import Numeral from "numeral";
import { AuthContext } from "../../context/AuthContext";
import axios from 'axios';
import AxioInstance from '../../axios/AxiosConfig';


export default function ModalManualCobrus(props) {
    const fileInput = useRef(null)
    const [img, setImg] = useState(null)
    const [description, setDescription] = useState("")
    const [cantidadCaracter, setCantidadCaracter] = useState(0)
    const { getAuthenticatedHeaders, } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);

    const [, setShowModalRecarga] = useState("");
    const [isSuperUser, setBrowserName] = useState("");

    const [user, GetUserByPhoneCcOrEmail] = useState("");
    const [,] = useState("");
    const [,] = useState("");
    const [alias, SetAlias] = useState("")
    const [bankAccount, setBankAccount] = useState({});
    const [bankAccounts, setBankAccounts] = useState([]);
    const [valor, setValor] = useState(0)
    const [number, setNumber] = useState(0)

    const [sistemaOP, setSistemaOP] = useState("");


    const today = new Date();

    const date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear() + ' - ' + today.getHours() + ':' + today.getMinutes() + ' ';
    function enteroTransform(n) {
        var entero = Math.round(n);
        return entero;
    }
    useEffect(() => {
        getBrowserInfo()
    }, [])
    const handleChangeBanks = (event) => {
        event.preventDefault();


        setBankAccount(event.target.value);

    };
    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setValor(amount)
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setNumber(withdrawAmount);
    };
    const caracteres = (e) => {

        setDescription(e)
        setCantidadCaracter(e.length)
    }
    useEffect(() => {
        search_method()
    }, [])
    const search_method = async () => {
        const response = await AxioInstance.post(`/api/all_payments/`, {
            headers: await getAuthenticatedHeaders(),
        });
        setBankAccounts(response.data.data.data);
    }


    const optionValueSelectBank = () => {
        let items = []

        for (let i = 0; i < bankAccounts.length; i++) {
            items.push(
                <option key={bankAccounts[i].id}
                    value={(bankAccounts[i].name)}>
                    {(bankAccounts[i].name)}
                </option>
            );
        }
        return items;
    };


    const getBrowserInfo = async () => {

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            setBrowserName('Opera');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            setBrowserName('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            setBrowserName('Safari');
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            setBrowserName('Firefox');
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
            setBrowserName('IE');
        } else {
            setBrowserName('Unknown');
        }

        if (navigator.appVersion.indexOf("Win") != -1) {
            setSistemaOP("Windows")
        }
        else if (navigator.appVersion.indexOf("Mac") != -1) {
            setSistemaOP("MacOS")
        }
        else if (navigator.appVersion.indexOf("X11") != -1) {
            setSistemaOP("UNIX")
        }
        else if (navigator.appVersion.indexOf("Linux") != -1) {
            setSistemaOP("Linux")
        }
        else if (navigator.appVersion.indexOf("Android") != -1) { setSistemaOP("Android") }
        else {
            setSistemaOP('Unknown')
        }
    }
    const Request = () => {

        const data = {
            note: description,
            img: img,
            url: user,
            PaymentMethod: bankAccount,
        }
        props.CobruManual(data)
    }

    function handleImageUpload(event) {
        const file = event.target.files[0];

        setImg(file)


    }

    const NamePayment = (x) => {
        let image = ''
        if (image == "visa") {
            image = require("../../assets/cobrus/payment_methods/visaRounded.png")
        } else if (image == "MasterCard") {
            image = require("../../assets/cobrus/payment_methods/mastercardRounded.png")
        } else if (image == "AMEX") {
            image = require("../../assets/cobrus/payment_methods/amexRounded.png")
        } else if (image == "Diners") {
            image = require("../../assets/cobrus/payment_methods/dinersRounded.png")
        } else if (image == "Unknown") {
            image = require("../../assets/cobrus/payment_methods/tcRounded.png")
        } else if (image == "pse") {
            image = require("../../assets/cobrus/payment_methods/pseRounded.png")
        } else if (image == "cobru") {
            image = require("../../assets/cobrus/payment_methods/cobruRounded.png")
        } else if (image == "efecty") {
            image = require("../../assets/cobrus/payment_methods/efectyRounded.png")
        } else if (image == "baloto") {
            image = require("../../assets/cobrus/payment_methods/balotoRounded.png")
        } else if (image == "NEQUI") {
            image = require("../../assets/cobrus/payment_methods/nequiRounded.png")
        } else if (image == "BTC") {
            image = require("../../assets/cobrus/payment_methods/btcRounded.png")
        } else if (image == "BCH") {
            image = require("../../assets/cobrus/payment_methods/bchRounded.png")
        } else if (image == "DAI") {
            image = require("../../assets/cobrus/payment_methods/daiRounded.png")
        } else if (image == "DASH") {
            image = require("../../assets/cobrus/payment_methods/dashRounded.png")
        } else if (image == "exito") {
            image = require("../../assets/cobrus/payment_methods/movilexitoRounded.png")
        } else if (image == "super_giros") {
            image = require("../../assets/cobrus/payment_methods/supergirosRounded.png")
        }

        return image
    }



    return (
        <>

            <>
                {<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="" >

                        <div className="flex-col  flex bg-blue-400 h-1/3x md:w-full  rounded border-solid shadow-lg  " style={{ background: "#ffffff" }}>
                            <p className="text-right pr-5 pt-3 text-xl cursor-pointer " onClick={() => props.setModalManuel(false)}>x</p>
                            <form className="w-full " >

                                <div className=" mx-3 my-6">
                                    <label className="mx-3">URL del Cobru</label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder="05qj9psv"
                                        autoFocus={false}
                                        value={user}
                                        onChange={(e) => GetUserByPhoneCcOrEmail(e.target.value)}
                                    />
                                    <p className="text-xs ml-2">{alias}</p>
                                </div>



                                <div className=" mx-3 my-6">
                                    <label className="mx-3">Medio de pago</label>
                                    <select
                                        className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                        name={bankAccount}
                                        defaultValue=""
                                        autoFocus={false}
                                        value={bankAccount}
                                        custom={bankAccount.toString()}
                                        onChange={handleChangeBanks}
                                    >
                                        <option hidden value="">Seleccionar</option>
                                        {bankAccounts.length <= 0 ? (
                                            <option value={bankAccount}>
                                                No hay cuentas registradas
                                            </option>
                                        ) : (
                                            optionValueSelectBank()
                                        )}
                                        ;
                                    </select>


                                </div>

                                <div className="w-full ">
                                    <div className="mx-3 my-6 ">
                                        <label className="mx-3">Evidencia</label>
                                        <input
                                            className="appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                            accept="image/*,application/pdf"
                                            type="file"
                                            ref={fileInput}
                                            autoFocus={false}
                                            onChange={(e) => handleImageUpload(e)}


                                        />


                                    </div>
                                </div>
                                <div className="w-full ">
                                    <div className="mx-3 my-6 m">
                                        <label className="mx-3 flex justify-between items-center"><span>Nota<span className="text-gray-700">{` (opcional)`}</span>  </span>{description.length !== 0 && <span className="text-gray-700 text-xs">{`${cantidadCaracter}/40`}</span>}</label>

                                        <textarea
                                            className="appearance-none block h-20  w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                            type="text"
                                            maxLength="40"
                                            placeholder="Cobru pagado manual"
                                            onChange={(e) => caracteres(e.target.value)}
                                        />


                                    </div>
                                </div>
                                <div className="w-full ">
                                    <div className="mx-3 my-6">
                                        {img != null && user != "" && loading == false && bankAccount != "" ?
                                            <button
                                                autoFocus={false}
                                                type="reset"
                                                className="w-full  bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off "
                                                onClick={(e) => Request()}
                                            > Pagar {number != 0 ? `$${number}` : ""}
                                            </button> : (
                                                <button
                                                    autoFocus={false}
                                                    className="w-full flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                                    type="button"

                                                >{!loading ? `Pagar ${number != 0 ? `$${number}` : ""}` : "Pagando..."}

                                                </button>)}

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>}
                <div className=" fixed inset-0 z-40 " style={{
                    background: 'rgba(47, 46, 44, 0.5)',
                    backdropFilter: 'blur(10px)',
                }}></div>
            </>
            )
        </>
    );
}