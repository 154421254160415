import React, { useContext } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import MisTarifas from '../../containers/pages/MisTarifas';
// views
import Resumen from '../../containers/pages/Resumen';
import Cobrar from '../../containers/pages/Cobrar';
import Retirar from '../../containers/pages/Retirar';
import Reporte from '../../containers/pages/Reporte';
import Colaboradores from '../../containers/pages/Colaboradores';
import Ayuda from '../../containers/pages/Ayuda';
import Integracion from '../../containers/pages/Integracion';
import Consultar from '../../containers/pages/Consultar';
import Recargar from "../../containers/pages/Recargar"
import Sidebar from '../Sidebar/Sidebar';
import Nav from '../NavigationBar';
/* import Enviar from '../../containers/pages/Enviar';
 */import Operacion from '../../containers/pages/Operacion';
import { AuthContext } from '../../context/AuthContext';
import Documentos from "../../containers/pages/Documentos"
import NewEnvios from '../../containers/pages/NewEnvios';
import ReportesDate from '../../containers/pages/ReportesDate';
import RetirosEfecty from '../../containers/pages/RetirosEfecty';
import Tarifas from '../../containers/pages/Tarifas';
/* import DownloadPdfWithdraw from '../../containers/pages/DownloadPdfWithdraw'; */

const NoMatch = () => {

    let location = useLocation();
    return (
        <div className="flex md:flex-row flex-wrap p-4">
            <div className="w-full bg-white p-4 text-center ">
                No encontramos nada para <code>{location.pathname}</code>
            </div>
        </div>
    );
}

const Routes = (props) => {
    const { LevelUser, isOperador, isValidator, isSuperUser } = useContext(AuthContext)


    const user = props.user;
    const state0 = props.state0;
    const state1 = props.state1;
    const state3 = props.state3;

    const pathname = props.pathname;
    const cobrus = props.cobrus;
        const totalBalance = props.totalBalance;
        /* const isCanViewDashboard = props.user.mother_account ? props.user.permission_dashboard : true;
    const isCanViewCobrus = props.user.mother_account ? props.user.permission_cobru : 3;
    const isCanViewWithdraw = props.user.mother_account ? props.user.permission_withdraw : 3;
    const isCanViewQuery = props.user.mother_account ? props.user.permission_query : true;
    const isCanViewReport = props.user.mother_account ? props.user.permission_reports : true;
    const isCanViewUser = props.user.mother_account ? props.user.permission_users : true; */
    const pendingTasks = props.pendingTasks;
    const balance_saved = props.balance_saved
    
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/15 bg-trixel-sidebar">
                    <Sidebar user={user} pathname={pathname} />
                </div>
                <div className="w-full md:w-1/84 bg-trixel-content p-2">
                    <Nav user={user} pathname={pathname} />
                    <Switch>
                        <Route exact path="/"  >
                            <Redirect to="/resumen" />
                        </Route>
                        <Route exact path="/mistarifas"  >
                            <MisTarifas totalBalance={totalBalance} />
                        </Route>
                       {/*  <Route  path="/detail_withdraw_pdf/:id" >
                            <DownloadPdfWithdraw />
                        </Route> */}
                        {/* <Route exact path="/" component={() => <Resumen cobrus={cobrus} totalBalance={totalBalance} canViewDashboard={isCanViewDashboard} />} /> */}
                        <Route exact path="/resumen"  >
                            <Resumen cobrus={cobrus} totalBalance={totalBalance} /* canViewDashboard={isCanViewDashboard} */ balance_saved={balance_saved} state0={state0} state1={state1} state3={state3} />
                        </Route>
                        <Route path="/cobrar" >
                            <Cobrar cobrus={cobrus} totalBalance={totalBalance} pendingTasks={pendingTasks} />
                        </Route>
                        <Route exact path="/enviar"  >
                            <NewEnvios task={pendingTasks} totalBalance={totalBalance} isSuperUser={false} />
                        </Route>
                        <Route path="/retirar"  >
                            <Retirar totalBalance={totalBalance} state0={state0} state1={state1} state3={state3} />
                        </Route>
                        <Route path="/consultar"  >
                            <Consultar /* canViewQuery={isCanViewQuery} */ />
                        </Route>
                        {
                            !!isOperador ? 
                            <Route path="/reportesdate"  >
                                <ReportesDate user={user} />
                            </Route>
                            :
                            <Route path="/reportes"  >
                                <Reporte /* canViewReport={isCanViewReport} */ />
                            </Route>
                        }
                        {
                            isOperador?
                            <>
                        <Route path="/efecty"  >
                            <RetirosEfecty user={user} />
                        </Route>
                        <Route path="/tarifas"  >
                            <Tarifas user={user} />
                        </Route>     
                            </>
                            :
                            null
                        }
                       

                        <Route path="/colaboradores"  >
                            <Colaboradores /* canViewUser={isCanViewUser} */ />
                        </Route>
                        <Route path="/ayuda" component={Ayuda} />
                        {LevelUser >= 2 && <Route path="/integracion" component={() => <Integracion user={user} />} />}
                        {LevelUser > 1 && <Route path="/recargar"  >
                            <Recargar user={user} />
                        </Route>}
                        {(isOperador || isValidator) ? <Route path="/documentos"  >
                            <Documentos user={user} />
                        </Route> : null}

                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default Routes;
