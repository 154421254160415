import React, { useState, useEffect, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import approve from "../../assets/images/approve.svg";
import cancel from "../../assets/images/cancel.svg";
import {
  FormatAmount,
  /* getAuthenticatedHeaders, */ FormatPhone,
  formatCurrency,
} from "../common/functions";
import moment from "moment";
import { AiOutlineFilePdf } from "react-icons/ai";
import AxioInstance from "../../axios/AxiosConfig";
import { ModalPassword } from "../../components/modals/ModalPassword";
import { ChangePassword } from "./clientes/ChangePassword";
import { ConfirmationEmailPhone } from "./clientes/ConfirmationEmailPhone";

export default function ModalClientes({ id, open, modal }) {
  const [copiar, setCopiar] = useState(false);
  const [browserName, setBrowserName] = useState("");
  const [data, setData] = useState([]);
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [showModal, setShowModal] = useState(open);
  const [sistemaOP, setSistemaOP] = useState("");
  const [error, setError] = useState("");

  const [color, setColor] = useState("");
  const [colorText, setColorText] = useState("");
  const { getAuthenticatedHeaders, others, isSuperUser, userDetailsContext } =
    useContext(AuthContext);
  const [showSaldos, setShowSaldos] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showComfirmEmail, setShowComfirmEmail] = useState(false)
  const [showComfirmPhone, setShowComfirmPhone] = useState(false)

  /*   useEffect(() => {
        DataRequest(id)

    }, []) */



  const DataRequest = async () => {
    try {
      const response = await AxioInstance.post(`/api/profile/detail`, {
        headers: await getAuthenticatedHeaders(),
        id: id,
      });

      if (response.data?.status === 200) {
        setData(response.data?.data?.detail_profile);
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const title = `REF-${id}`;

  const today = new Date();
  var ampm = today.getHours() >= 12 ? "pm" : "am";

  function formatDate(x) {
    let currentTimestamp = x - 18000000;

    /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
    let date = moment(x).format("DD/MM/YYYY HH:mm A");

    return date;
  }
 

  const typeDocument = (id) => {
    let type = "";
    if (id == 0) {
      type = "CC";
    } else if (id == 1) {
      type = "CE";
    } else if (id == 2) {
      type = "TI";
    } else if (id == 3) {
      type = "NIT";
    } else if (id == 4) {
      type = "PSP";
    }
    return type;
  };
  function CalcularDv(nit) {
    var arreglo, x, y, z, i, nit1, dv1;
    nit1 = nit.toString();
    if (isNaN(nit1)) {
      document.form1.dv.value = "X";
      alert(
        "Numero del Nit no valido, ingrese un número sin puntos, ni comas, ni guiones, ni espacios"
      );
    } else {
      arreglo = new Array(16);
      x = 0;
      y = 0;
      z = nit1.length;
      arreglo[1] = 3;
      arreglo[2] = 7;
      arreglo[3] = 13;
      arreglo[4] = 17;
      arreglo[5] = 19;
      arreglo[6] = 23;
      arreglo[7] = 29;
      arreglo[8] = 37;
      arreglo[9] = 41;
      arreglo[10] = 43;
      arreglo[11] = 47;
      arreglo[12] = 53;
      arreglo[13] = 59;
      arreglo[14] = 67;
      arreglo[15] = 71;
      for (i = 0; i < z; i++) {
        y = nit1.substr(i, 1);
        x += y * arreglo[z - i];
      }
      y = x % 11;
      if (y > 1) {
        dv1 = 11 - y;
      } else {
        dv1 = y;
      }

      return dv1;
    }
  }

  const typeDocumentPhoto = (id) => {
    let type = "";
    if (id == 1) {
      type = "Cédula (Frente)";
    } else if (id == 2) {
      type = "Cédula (Reverso)";
    } else if (id == 3) {
      type = "RUT";
    } else if (id == 4) {
      type = "Cédula (Frente)	";
    } else if (id == 5) {
      type = "Cédula (Reverso)";
    } else if (id == 6) {
      type = "RUT";
    } else if (id == 7) {
      type = "Cámara de Comercio";
    } else if (id == 12 || id == 13) {
      type = "Selfie";
    }
    return type;
  };
  const validateCedula = (docs) => {
    const cedula = docs?.filter((item) => {
      return (
        (item?.type_document == 1 ||
          item?.type_document == 2 ||
          item?.type_document == 4 ||
          item?.type_document == 5) &&
        item?.document_status === 2
      );
    });
    return cedula?.length >= 2;
  };

  const getPhotoProfile = () => {
    function verificaFormatoURL(url) {
      var expresionRegular = /uploads\/.*\.jpg$/;
      return expresionRegular.test(url);
    }

    if (verificaFormatoURL(data?.profile_picture)) {
      return data?.profile_picture;
    }

    let urlSelfie = data?.urls_documents?.find(
      (item) => item?.type_document === 12 || item?.type_document === 13
    )?.url;

    return urlSelfie || false;
  };

  const checkUserPassword = async (password) => {
    if (!password) {
      return;
    }
    const data = {
      username: userDetailsContext?.username,
      password: password,
    };
    AxioInstance.post(
      `/api/login`,
      { data },
      {
        headers: await getAuthenticatedHeaders(),
      }
    ).then((response) => {
      if (response.data?.status === 200 /* true */) {
        DataRequest(id);
        setShowInfo(true);
        /*                     setLoadingAction(true)
         */
      } else {
        setError("Contraseña incorrecta");
      }
    });
  };
  function quitarPrefijo(numero) {
    // Utilizamos una expresión regular para encontrar y eliminar el prefijo
    // El patrón busca una cadena seguida por un guion y luego captura el resto
    const patron = /^.*?-(.*)$/;
    const resultado = numero?.replace(patron, '$1');

    return resultado;
}
  const getColorBrand = () => {
    let color = '#777777'
    switch (data?.brand) {
        case 'MUTUAL':
            color= '#3d4bcc'
            break;
            case 'DONPAGO':
            color= '#ed3137'
            break;
        default:
            color= '#777777'
            break;
    }

    return color
  }
  return (
    <>
      {!showInfo && showModal ? (
        <ModalPassword
          handleOK={checkUserPassword}
          handleCancel={() => modal(false)}
          error={error}
        />
      ) : showInfo && showModal && data?.length !== 0 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-80 my-6 mx-auto max-h-full">
              {
                !showChangePassword&&!showComfirmEmail&&!showComfirmPhone?
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => modal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
                <div className="relative px-6 pb-1 flex-auto">
                  {copiar && <div className="copiado-modal">Copiado</div>}

                  <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                    {title}
                  </h3>
                  <div className=" justify-between  flex-row">
                    {
                      <div className="flex flex-row  items-center py-3 justify-between">
                        <div className="flex flex-row ">
                        

                          {getPhotoProfile() ? (
                            <a
                              href={getPhotoProfile()}
                              style={{
                                width: 100,
                                height: 100,
                                marginRight: 20,
                                borderRadius: 50,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="bg-gray-600 overflow-hidden"
                            >
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={getPhotoProfile()}
                              />
                            </a>
                          ) : (
                            <div
                              style={{
                                width: 100,
                                height: 100,
                                marginRight: 20,
                                borderRadius: 50,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="bg-gray-600"
                            >
                              <p
                                className="text-white"
                                style={{ fontSize: 56 }}
                              >
                                {data?.user.first_name?.charAt(0)}
                                {data?.user.last_name?.charAt(0)}
                              </p>
                            </div>
                          )}
                          <div>
                            <p className="text-gray-700 text-xs">Nombre</p>
                            <p className="text-gray-700 text-base">
                              {data?.user.first_name} {data?.user.last_name}
                            </p>
                            <p className="text-gray-700 text-xs">usuario</p>
                            <p className="text-gray-700 text-base">
                              {quitarPrefijo(data?.user.username)}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col">
                            {
                                data?.brand&&data?.brand!==''&&data?.brand!=='NONE'
                                ?
                                <div
                                className={`border-gray-700 mb-2`}
                                style={{ borderWidth: 1.5,borderColor:getColorBrand() }}
                                >
                                <p
                                  className={` text-gray-700 px-2 py-1 text-sm ml-0`}
                                style={{ borderWidth: 1.5,color:getColorBrand() }}

                                  >
                                  {data?.brand}
                                </p>
                              </div>
                                :
                                null
                            }
                       
                        <div
                          className={`border-green-500`}
                          style={{ borderWidth: 1.5 }}
                          >
                          <p
                            className={` text-green-500 px-2 py-1 text-sm ml-0`}
                            >
                            {"NIVEL"} {data?.level}
                          </p>
                        </div>
                              </div>
                      </div>
                    }
                  </div>
                  <div className="my-2 pl-2 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded">
                      <div className="flex items-center px-1 leading-7 margin-10px">
                        <button
                          className="rounded-full mr-4 text-sm w-6/12 text-left button-outline-off copy-cursor links"
                          onClick={() => setShowChangePassword(true)}
                        >
                          Cambiar Contraseña
                        </button>
                      </div>
                    </div>
                
                  {showSaldos ? (
                    <>
                    
                      <hr />
                      <div className="my-2 pl-2 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded flex flex-col  px-1 leading-7 margin-10px">
                        
                        <div className="flex flex-row w-full">
<div className="w-full flex flex-row items-center">

<img className="w-5 h-5 mr-1" src={require('../../assets/images/ColombiaIcon.png')}></img>
                            {FormatAmount(data?.balance_to_cop)+' COP'}
</div>
<div className="w-full flex flex-row items-center justify-end">
<img className="w-5 h-5 mr-1" src={require('../../assets/images/UsaIcon.png')}></img>
                            {FormatAmount(data?.balance_to_usd)+' USD'}
</div>

                        </div>
                      {/*   <div className="flex items-center px-1 leading-7 margin-10px w-full">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            Saldo 
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            <img className="w-5 h-5" src={require('../../assets/images/ColombiaIcon.png')}></img>
                            {FormatAmount(data?.balance_to_cop)}
                          </button>
                        </div>
                        <div className="flex items-center px-1 leading-7 margin-10px w-full">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            Saldo
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            <img className="w-5 h-5" src={require('../../assets/images/UsaIcon.png')}></img>
                            {FormatAmount(data?.balance_to_usd)}
                          </button>
                        </div> */}
                      </div>
                    </>
                  ) : (
                    <div className="my-2 pl-2 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded">
                    <div className="flex items-center px-1 leading-7 margin-10px">
                      <button
                        className="rounded-full mr-4 text-sm w-6/12 text-left button-outline-off copy-cursor links"
                        onClick={() => setShowSaldos(true)}
                      >
                        Ver saldos
                      </button>
                    </div>
                  </div>
                  )}

                  <hr />
                  {/* aqui */}

                  <div className="my-2 mb-1 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded">

                  <div className="flex items-center px-1 leading-7 margin-10px">
                      <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                        Tipo de persona
                      </p>
                      <button
                        style={{ width: "70%" }}
                        className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end"
                      >
                        {data?.type_person===2?"Jurídica":"Natural"}
                      </button>
                    </div>

                    <div className="flex items-center px-1 leading-7 margin-10px">
                      <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                        Documento
                      </p>
                      <button
                        style={{ width: "70%" }}
                        className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end"
                      >
                        <img
                          className="mr-1"
                          src={
                            validateCedula(data?.urls_documents)
                              ? approve
                              : cancel
                          }
                          width={15}
                          height={15}
                        />
                        {typeDocument(data?.document_type)}{" "}
                        {data?.document_number}
                      </button>
                    </div>

                    <div className="flex items-center px-1 leading-7 margin-10px">
                      <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                        Celular
                      </p>
                      <button
                        style={{ width: "70%" }}
                        className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end"
                        onClick={()=>{
                          if (!data?.phone_verified ) {
                            setShowComfirmPhone(true)
                            
                          }
                        }}
                      >
                        <img
                          className="mr-1"
                          src={data?.phone_verified ? approve : cancel}
                          width={15}
                          height={15}
                        />{" "}
                        {FormatPhone(data?.phone)}
                      </button>
                    </div>
                    <div className="flex items-center px-1 leading-7 margin-10px">
                      <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                        Correo
                      </p>
                      <button
                        style={{ width: "70%" }}
                        className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end"
                        onClick={()=>{
                          if (!data?.email_verified) {
                            setShowComfirmEmail(true)
                          }
                        }}

                      >
                        <img
                          className="mr-1"
                          src={data?.email_verified ? approve : cancel}
                          width={15}
                          height={15}
                        />{" "}
                        {data?.user.email}
                      </button>
                    </div>

                    {data?.urls_documents.length > 0 &&
                      data?.urls_documents.map((item, index) => (
                        <div className="flex items-center px-1 leading-7 margin-10px">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            {typeDocumentPhoto(item.type_document)}
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off  items-center flex justify-end"
                          >
                            {item.type_document === 3 ||
                            item.type_document === 6 ||
                            item.type_document === 7 ? (
                              <div className="flex flex-row">
                                <img
                                  className="mr-1"
                                  src={
                                    item.document_status === 2
                                      ? approve
                                      : cancel
                                  }
                                  width={15}
                                  height={15}
                                />
                                <a target="_blank" href={item.url}>
                                  <p className="links">Evidencia</p>
                                </a>
                              </div>
                            ) : (
                              <div className="flex flex-row">
                                <img
                                  className="mr-1"
                                  src={
                                    item.document_status === 2
                                      ? approve
                                      : cancel
                                  }
                                  width={15}
                                  height={15}
                                />
                                <a target="_blank" href={item.url}>
                                  <p className="links">Evidencia</p>
                                </a>
                              </div>
                            )}
                          </button>
                        </div>
                      ))}
                    <div className="flex items-center px-1 leading-7 margin-10px">
                      <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                        IP
                      </p>
                      <button
                        style={{ width: "70%" }}
                        className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                      >
                        {data?.ip}
                      </button>
                    </div>
                    {data?.referal_code && data?.referal_code !== "" ? (
                      <div className="flex items-center px-1 leading-7 margin-10px">
                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                          Referido
                        </p>
                        <button
                          style={{ width: "70%" }}
                          className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                        >
                          {data?.referal_code}
                        </button>
                      </div>
                    ) : null}
                    <hr />
                    <div className="my-2 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded">
                      {data?.country && data?.country !== "" ? (
                        <div className="flex items-center px-1 leading-7 margin-10px">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            País
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            {data?.country}
                          </button>
                        </div>
                      ) : null}
                      {data?.city && data?.city !== "" ? (
                        <div className="flex items-center px-1 leading-7 margin-10px">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            Ciudad
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            {data?.city}
                          </button>
                        </div>
                      ) : null}

                      {data?.state && data?.state !== "" ? (
                        <div className="flex items-center px-1 leading-7 margin-10px">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            Departamento
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            {data?.state}
                          </button>
                        </div>
                      ) : null}

                      {data?.longitude && data?.longitude !== "" ? (
                        <div className="flex items-center px-1 leading-7 margin-10px">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            Longitud
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            {data?.longitude}
                          </button>
                        </div>
                      ) : null}

                      {data?.longitude && data?.longitude !== "" ? (
                        <div className="flex items-center px-1 leading-7 margin-10px">
                          <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                            Latitud
                          </p>
                          <button
                            style={{ width: "70%" }}
                            className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor "
                          >
                            {data?.latitude}
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <hr />

                    {
                      <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                          Registro
                        </p>
                        <p className="rounded-full text-sm w-6/12 text-right">
                          {moment(data?.date_created).format(
                            "YYYY-MM-DD HH:mm:ss A"
                          )}
                        </p>
                      </div>
                    }
                  </div>
                </div>
                <hr
                  style={{
                    width: 480,
                    background: "white",
                    borderColor: "white",
                  }}
                />
                {
                  <div className=" justify-center items-center">
                    <p className=" text-center py-4 text-gray-700  text-sm">
                      {}
                    </p>
                  </div>
                }
                <div className="text-2xl centrar-contenido"></div>
                <div className="items-center justify-end p-2 border-gray-300 rounded-b">
                  <p className="text-gray-700 text-center text-xs font-hairline"></p>
                </div>
              </div>
              :
              null
              }

              {
                showChangePassword&&!showComfirmEmail&&!showComfirmPhone
                ?
                <ChangePassword brand={data?.brand} handleClose={setShowChangePassword} idUser={data?.id}/>
                :
                null
              }
               {
                !showChangePassword&&showComfirmEmail&&!showComfirmPhone
                ?
                <ConfirmationEmailPhone brand={data?.brand} handleClose={setShowComfirmEmail} email={data?.user?.email} handleFinish={()=>DataRequest()}/>
                :
                null
              }
              {
                !showChangePassword&&!showComfirmEmail&&showComfirmPhone
                ?
                <ConfirmationEmailPhone brand={data?.brand} handleClose={setShowComfirmPhone} phone={data?.phone} handleFinish={()=>DataRequest()}/>
                :
                null
              }

              {/* Finn */}
            </div>
          </div>
          <div
            className=" fixed inset-0 z-40 "
            style={{
              background: "rgba(47, 46, 44, 0.5)",
              backdropFilter: "blur(10px)",
            }}
          ></div>
        </>
      ) : (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-pop-up my-6 mx-auto max-w-md">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => modal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
                <div className="relative px-6 pb-1 flex-auto">
                  {copiar && <div className="copiado-modal">Copiado</div>}

                  <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                    {title}
                  </h3>
                  <div
                    style={{
                      padding: "24px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {<CircularProgress color="red" size={75} />}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" fixed inset-0 z-40 "
            style={{
              background: "rgba(47, 46, 44, 0.5)",
              backdropFilter: "blur(10px)",
            }}
          ></div>
        </>
      )}
    </>
  );
}
