import React, { useState, useEffect, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import numeral from "numeral";
import { AuthContext } from "../../context/AuthContext";
import { FormatPhone, FormatAmount } from "../common/functions"
import useGeolocation from "react-hook-geolocation";

import moment from 'moment';
import File from '../../assets/files/cobru_plantilla_envios.xlsx';
import Dropzone from 'react-dropzone';
import { FaFileExcel } from "react-icons/fa";
import Numeral from "numeral";
import axios from "axios";
import AxioInstance from '../../axios/AxiosConfig';


export default function ModalEnviar(props) {
    const { latitude, longitude } = useGeolocation();

    const [alias, SetAlias] = useState("")
    const [loading, setLoading] = useState(false)
    const { getAuthenticatedHeaders, } = useContext(AuthContext);

    const [isLoadingDrop, setIsLoadingDrop] = useState(false);
    const [personal, setPersonal] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [description, setDescription] = useState('');

    const [monto, setMonto] = useState(0);
    const [ammount, setammount] = useState(0);

    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setammount(amount)
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setMonto(withdrawAmount);
    };



    const request = () => {
        setLoading(true)
        const data = {
            latitude: latitude,
            longitude: longitude,
            to_user: usuario.toString().replace(/\D/g, ""),
            amount: ammount,
            description: description
        }
        props.RequestSend(data)
    }
    const GetUserByPhoneCcOrEmail = async (value) => {
        setUsuario(value)
        let user = ''

        if (value[0] == '(') {
            user = value.toString().replace(/\D/g, "");
        } else {
            user = value
        }

        const data = {
            search_method: "phone",
            search_value: user.toString().toLowerCase().replace(/ /g, ""),

        }
        if (user.length > 6) {


            const response = await AxioInstance.post(`/api/get_user_by_phone_cc_email/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });
            SetAlias(response.data.data.alias)
        }
        /*  return response.error ? null : response.message; */
    }

    return (
        <>

            <>

                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                >
                    <div className="relative w-pop-up my-6 mx-auto max-w-md">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-6">
                            <div className="flex md:flex-row flex-wrap  w-full">
                                <div className="w-full md:w-full pb-2 pr-2 text-center text-gray-200">
                                    <div className="rounded overflow-hidden  bg-white">
                                        <div className="px-2 py-8">
                                            <div className="font-bold text-xl mb-2 text-black">
                                                {FormatAmount(props.totalBalance.balance)}
                                            </div>
                                            <p className="text-gray-700 text-sm">Saldo Disponible</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.setShowModal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                            <div className="flex md:flex-row flex-wrap  w-full">

                                {personal && <div className="w-full md:w-full bg-white p-4 text-center text-gray-200  mr-2">

                                    <div className="text-center py-4">
                                        <FaFileExcel size="2em" className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-green-600" />
                                        <a className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700" href={File} target="blank">
                                            <svg className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700 fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                            <span>Descargar aquí plantilla Envios </span>
                                        </a>

                                        <div className="mt-5 border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 cursor-pointer">
                                            <Dropzone
                                                onDrop={props.onDrop}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                                {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {!isDragActive && acceptedFiles.length == 0 && '¡Haga clic aquí o arrastre un archivo xlsx para cargarlo!!'}
                                                        {isDragActive && !isDragReject && "¡Sueltalo!"}
                                                        {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                                        <ul className="list-group mt-2">
                                                            {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                                <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                                    se subio el archivo: {acceptedFile.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                )}
                                            </Dropzone>
                                            {isLoadingDrop &&
                                                <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </div>


                                </div>}
                                {!personal &&
                                    <div className="flex md:flex-row   w-full">
                                        <div className="w-full md:w-full bg-white p-4 t">

                                            <div className="w-full ">
                                                <div className=" mx-3 mb-6 mt-3 flex-col flex items-center ext-left  ">
                                                    <label className="mx-3 text-left w-full">Usuario</label>
                                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        placeholder="(301) 548-3222"
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        autoFocus={false}
                                                        value={FormatPhone(usuario)}
                                                        onChange={(e) => GetUserByPhoneCcOrEmail(e.target.value)}
                                                    />
                                                    <p className="text-xs text-left ml-2 w-full">{alias}</p>
                                                    <label className="mx-3 text-left w-full  mt-3">Monto</label>
                                                    <input
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        id="withdraw_amount"
                                                        value={"$" + monto}
                                                        onChange={handleChangeWithDrawAmount}
                                                        type="tel"
                                                        keyboard="numeral"
                                                        placeholder="0"
                                                        pattern="[0-9]*"

                                                    ></input>

                                                    <label className="mx-3 text-left w-full mt-3">Descripción</label>
                                                    <input className="appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        placeholder="Envio a carlos (opcional)"
                                                        type="text"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                    {ammount > 0 && usuario.length > 8 ?
                                                        <button
                                                            autoFocus={false}
                                                            type="reset"
                                                            className="w-full mt-6  bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off "
                                                            onClick={(e) => request(e)}
                                                        > Enviar {monto != 0 ? `$${monto}` : ""}
                                                        </button> : (
                                                            <button
                                                                autoFocus={false}
                                                                className="w-full mt-6 flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                                                type="button"

                                                            >{!loading ? `Enviar ${monto != 0 ? `$${monto}` : ""}` : "enviando..."}

                                                            </button>)}

                                                </div>
                                            </div>


                                        </div>


                                    </div>
                                }
                            </div>
                            {personal && <p onClick={() => setPersonal(false)} className="font-body text-lg links text-center py-2 cursor-pointer">Volver</p>}
                            {!personal && <p onClick={() => setPersonal(true)} className="font-body text-lg links text-center py-2 cursor-pointer">Envios en lote</p>}


                            <div className="items-center justify-end p-2 border-gray-300 rounded-b">

                            </div>
                        </div>
                    </div>
                </div>

                <div className=" fixed inset-0 z-40 " style={{
                    background: 'rgba(47, 46, 44, 0.5)',
                    backdropFilter: 'blur(10px)',
                }}></div>
            </>
            )

        </>


    );
}