import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import numeral from "numeral";
import moment from 'moment';

export default function ModalRecarga(props) {


    const [browserName, setBrowserName] = useState("");

    const [sistemaOP, setSistemaOP] = useState("");
    const userIp = props.info.userIp
    const title = "Recarga exitosa";
    const ref = props.retiroPk;

    const today = new Date();
    var ampm = today.getHours() >= 12 ? 'pm' : 'am';
    const comision_retiro = props.info.fee;
    const comision_iva_retiro = props.info.fee_iva;
    const gmf = props.info.gmf;
    const payed_amount = props.info.payed_amount;
    const amount = props.info.amount;

    const date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear() + ' - ' + today.getHours() + ':' + today.getMinutes() + ' ' + ampm;
    function enteroTransform(n) {
        var entero = Math.round(n);
        return entero;
    }
    useEffect(() => {
        getBrowserInfo()
    }, [])
    function FormatCOPNumber(numbers, commas = false) {
        try {
            let number = Math.round(numbers);
            //algunos inputs de la aplicacion envian textos con "$" para formatearlos,
            //pero estos no se les puede hacer parse int. Por eso lo quitamos si no funciona.
            if (number.toString().includes("$")) {
                number = number.toString().replace(/\D/g, "");
            }
            //si number esta vacio en este momento devolvemos "$0"
            if (!number) {
                return "$0"
            }

            //esta linea mira si debemos agregar un signo negativo al numero cuando es menor a 0
            let prefix = parseInt(number) >= 0 ? "$" : "-$";
            if (!commas) {
                return prefix + numeral(number.toString().replace(/\D/g, "")).format('0,0').replace(/,/g, '.');
            }
            else {
                return prefix + numeral(number).format('0,0');
            }
        }
        catch (error) {

            return "";

        }
    }
    const getBrowserInfo = async () => {

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            setBrowserName('Opera');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            setBrowserName('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            setBrowserName('Safari');
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            setBrowserName('Firefox');
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
            setBrowserName('IE');
        } else {
            setBrowserName('Unknown');
        }

        if (navigator.appVersion.indexOf("Win") != -1) {
            setSistemaOP("Windows")
        }
        else if (navigator.appVersion.indexOf("Mac") != -1) {
            setSistemaOP("MacOS")
        }
        else if (navigator.appVersion.indexOf("X11") != -1) {
            setSistemaOP("UNIX")
        }
        else if (navigator.appVersion.indexOf("Linux") != -1) {
            setSistemaOP("Linux")
        }
        else if (navigator.appVersion.indexOf("Android") != -1) { setSistemaOP("Android") }
        else {
            setSistemaOP('Unknown')
        }
    }

    return (
        <>

            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                >
                    <div className="relative w-pop-up my-6 mx-auto max-w-md">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.setShowModal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                            <div className="relative px-6 pb-1 flex-auto">

                                <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">{title}</h3>
                                <div className="flex justify-center items-center mt-4 mb-8">
                                    <img src={require('../../assets/images/approve.svg')} style={{ width: 75, height: 75 }} />
                                </div>

                                <div className="my-4 py-2 mb-1  text-lg leading-relaxed border border-solid text-gray-700 rounded">
                                    {props.info.pk !== null && <div className="flex items-center px-1 leading-7">
                                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">Referencia</p>
                                        <p className="rounded-full text-sm w-6/12 text-right">{props.info.pk}</p>
                                    </div>}
                                    {props.info.name !== null && <div className="flex items-center px-1 leading-7">
                                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">Nombre</p>
                                        <p className="rounded-full text-sm w-6/12 text-right">{props.info.name}</p>
                                    </div>}
                                    <div className="flex items-center px-1 leading-7">
                                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">Usuario</p>
                                        <p className="rounded-full text-sm w-6/12 text-right">{props.info.owner}</p>
                                    </div>
                                    <div className="flex items-center px-1 leading-7">
                                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">Banco</p>
                                        <p className="rounded-full text-sm w-6/12 text-right">{props.info.bank_withdraw}</p>
                                    </div>
                                    <div className="flex items-center px-1 leading-7">
                                        <p className="rounded-full mr-4 text-sm w-6/12 text-left">Monto</p>
                                        <p className="rounded-full text-sm w-6/12 text-right">{FormatCOPNumber(props.info.amount)}</p>
                                    </div>
                                    {props.info.date_created !== null && <div className="flex items-center px-1 leading-7">
                                        <p className="rounded-full mr-4 text-sm w-4/12 text-left">Fecha</p>
                                        <p className="rounded-full text-sm w-8/12 text-right">{moment(props.info.date_created).format('YYYY-MM-DD HH:mm:ss A')}</p>
                                    </div>}

                                </div>
                            </div>
                            <div className="items-center justify-end p-2 border-gray-300 rounded-b">

                            </div>
                        </div>
                    </div>
                </div>
                <div className=" fixed inset-0 z-40 " style={{
                    background: 'rgba(47, 46, 44, 0.5)',
                    backdropFilter: 'blur(10px)',
                }}></div>
            </>
            )
        </>
    );
}