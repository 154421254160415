import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch,Route } from "react-router-dom";
import axios from "axios";

import Dashboard from "./containers/layouts/Dashboard";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import FunctionContext from "./context/FuntionsContext";
import AuthContext from "./context/AuthContext";
import ModalDescripcion from "./containers/layouts/ModalDescripcion";
const dataModal = {
  pk: 585246,
  owner_id: 22571,
  owner_state: 4,
  type: "Retiro a tercero",
  owner_first_name: "REMITEE S.A.",
  owner_last_name: "",
  document_number: 3371550424,
  date_created: 1721679160021.164,
  date_deposited: 1721681136725.764,
  date_rejected: null,
  date_consigned: 1721679493880.153,
  account_bank: "Nequi",
  account_state: 2,
  account_holder_name: "Luz Dary Tamayo Vazquez",
  account_number: "3136233811",
  account_type: 0,
  payed_amount: 790000,
  amount: 796730,
  state: 2,
  bank_dispersion: "Cobre-Bancolombia",
  note: "A001-Abonado en entidad financiera.",
  account_holder_document: "43104169",
  account_holder_document_type: 0,
  account_date_registered: 1721248964182.832,
  description: "",
};
export default function App() {
  return (
    <AuthContext.Provider>
      <FunctionContext.Provider>
        <div className="font-body text-trixel-color">
          <Router>
            <Switch>
            {/*   <Route
                path="/modal_withdraw_detail"
                component={() =>
                  <ModalDescripcion open={true} data={dataModal} />
                }
              /> */}
              <PublicRoute component={Login} path="/login" exact />
              <PrivateRoute component={Dashboard} path="/:path?" exact />
              
            </Switch>
          </Router>
        </div>
      </FunctionContext.Provider>
    </AuthContext.Provider>
  );
}
