import React, { useContext, useState } from "react";
import { FormatPhone } from "../../common/functions";
import { AuthContext } from "../../../context/AuthContext";
import AxioInstance from "../../../axios/AxiosConfig";
import { CircularProgress } from "@material-ui/core";

import approve from "../../../assets/images/approve.svg";
import cancel from "../../../assets/images/cancel.svg";

export const ConfirmationEmailPhone = ({
  phone = null,
  email = null,
  brand = 'NONE',
  handleClose = () => { },
  handleFinish = () => { }
}) => {
  const { getAuthenticatedHeaders, } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false);
  const [statusChange, setStatusChange] = useState(null)

  const handleConfirmation = async () => {

    let bodyReq = { headers: await getAuthenticatedHeaders(), }
    if (brand) {
      bodyReq.brand = brand
    }
    if (phone) {
      bodyReq.phone = phone
    }
    if (email) {
      bodyReq.email = email
    }
    setIsLoading(true)
    const response = await AxioInstance.post(`/api/validate_profile/`, bodyReq);
    setIsLoading(false)
    if (response?.data.status == 200) {
      setStatusChange(true)
    } else {
      console.log('falso');
      setStatusChange(false)
    }

  };

  return (
    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <button
        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
        onClick={() => {
          handleClose(false)
          if (statusChange) {
            handleFinish()
          }
        }}
      >
        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
          ×
        </span>
      </button>

      {
        !isLoading
          ?
          <>
            {
              statusChange === null
                ?
                <div className="relative px-6  flex-auto">
                  <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                    Validar{" "}
                    {email !== null ? "Correo" : phone !== null ? "Celular" : ""}
                  </h3>

                  <div className="my-3" />

                  <p className="text-gray-600 text-lg text-center">{email !== null ? email : phone !== null ? FormatPhone(phone) : ""}</p>
                  <div className="w-full flex justify-center py-4">
                    <button
                      className="bg-green-600 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 rounded-full"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      onClick={handleConfirmation}
                    >
                      Confirmar
                    </button>
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 rounded-full"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      onClick={() => handleClose(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
                :
                null
            }
            {
              statusChange === true
                ?
                <div className="relative px-6 pb-1 flex-auto">
                  <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                    ¡Listo!
                  </h3>
                  <div
                    style={{
                      padding: "24px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <img src={approve} className="w-20 h-20" />
                  </div>
                </div>
                :
                null
            }
            {
              statusChange === false
                ?
                <div className="relative px-6 pb-1 flex-auto">
                  <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                    ¡Ups!
                  </h3>

                  <div
                    style={{
                      padding: "24px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: 'column'
                    }}
                  >
                    <img src={cancel} className="w-20 h-20" />
                    <p className="text-red-500 text-sm italic mt-2">Hubo un problema validando el {email !== null ? "correo" : phone !== null ? "celular" : ""}</p>

                  </div>
                </div>
                :
                null
            }

          </>
          :
          <div className="relative px-6 pb-1 flex-auto">
            <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
              Validando...
            </h3>
            <div
              style={{
                padding: "24px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {<CircularProgress color="red" size={75} />}
            </div>
          </div>
      }
    </div>
  );
};

