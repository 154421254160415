import React, {useContext }from 'react';
import { Route, Redirect } from 'react-router-dom';
import Dashboard from '../containers/layouts/Dashboard';
import {getUserLogin} from '../containers/common/functions';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({component: Component, ...rest}) => {
    const { isAuth } = useContext(AuthContext)
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            /* getUserLogin() */isAuth ?
                <Dashboard {...props}>
                    <Component {...props}  />
                </Dashboard>
            : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )} />
    );
};

export default PrivateRoute;
