import React, { useState, useEffect, useContext, useRef } from "react";
import Numeral from "numeral";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { FunctionContext } from "../../context/FuntionsContext";
import AxioInstance from "../../axios/AxiosConfig";
import ModalCumplimiento from "../../containers/layouts/ModalCumplimiento";

import { TitleCase, FormatAmount } from "../common/functions";

import { FormatPhone } from "../common/functions";
import moment from "moment";
import { FORMAT } from "../../helpers";
import Banner from "../../components/Banner";
import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BsCheckCircle } from "react-icons/bs";
import ModalDocumentos from "../layouts/ModalDocumentos";
import cancel from "../../assets/images/cancel.svg";
import approve from "../../assets/images/approve.svg";
import ModalAntecedentes from "../layouts/ModalAntecedentes";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import { AiOutlineFilePdf } from "react-icons/ai";
import useGeolocation from "react-hook-geolocation";

const Documentos = (props) => {
  const DEV = localStorage.getItem("DEV");
  const { erroBanner, setErrorBanner } = useState(false);
  const { latitude, longitude } = useGeolocation();
  const [msn, setMsn] = useState("");
  const [LoadingSarlaft, setLoadingSarlaft] = useState(false);
  const [ModalSarlaft, setModalSarlaft] = useState(false);
  const [DataSarlaft, setDataSarlaft] = useState(false);

  const [Reverso, setReverso] = useState(null);
  const [Frontal, setFrontal] = useState(null);
  const [selfi, setSelfi] = useState(null);
  const [RUT, setRUT] = useState(null);
  const [CamaraComercio, setCamaraComercio] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);
  const [numFilas, setNumFilas] = useState([1]);
  const {
    buscarDocument,
    setBuscarDocument,
    buscador,
    setBuscador,
    buscar,
    setBuscar,
    setRenderDataDocuments,
    renderDataDocuments,
  } = useContext(FunctionContext);
  const [imagen, setImagen] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [ShowModal, setShowModal] = useState(false);
  const [ShowModalAtencedentes, setShowModalAtencedentes] = useState(false);
  const [dataAtencedentes, setDataAtencedentes] = useState({});

  const [pagination, setPagination] = useState(true);
  const [Hover1, setHover1] = useState(false);
  const [Hover2, setHover2] = useState(false);
  const [Hover3, setHover3] = useState(false);
  const [Hover4, setHover4] = useState(false);
  const [Hover5, setHover5] = useState(false);
  const [IDS, setIDS] = useState(null);
  const [dataModal, setDataModal] = useState([]);
  const [newPerPage, setPerPage] = useState(1);
  const [status, setStatus] = useState("");
  const [id, setID] = useState("");
  const [page, setPage] = useState(1);
  const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);
  const [DataDocumentos, setDataDocumentos] = useState([]);
  const handlePageChange = (page) => {
    newRequestPage(page);
  };

  useEffect(() => {
    if (buscar) {
      listDocuments();
    }
  }, [buscar]);
  useEffect(() => {
    listDocuments();
  }, [renderDataDocuments]);

  async function RefreshAccessToken(
    contentType = { "Content-Type": "application/json" }
  ) {
    let response = await fetch(`https://panel.validu.co/token/refresh/`, {
      method: "POST",
      headers: {
        ...contentType,
        Accept: "*/*",
        "X-Api-Key": "7tGAnJEt.mYLI45qvqeKEI9kMG681i9TQ636q0FWn",
      },
      body: JSON.stringify({
        refresh: localStorage.getItem("refresh_token_validu"),
      }),
    });

    if (response.status === 200) {
      let responseJson = await response.json();
      localStorage.setItem("access_token_validu", responseJson.access);
      localStorage.setItem("token_created_validu", new Date());
      return responseJson.access;
    } else {
      return false;
    }
  }
  async function GetAccessToken() {
    let currentTime = new Date(),
      token_created = Date.parse(localStorage.getItem("token_created_validu")),
      minutes_since = diff_minutes(currentTime, new Date(token_created));
    if (minutes_since > 14) {
      return await RefreshAccessToken();
    } else {
      return localStorage.getItem("access_token_validu");
    }
  }
  const diff_minutes = function (dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const QueryIdentidad = async (
    data,
    contentType = { "Content-Type": "application/json" }
  ) => {
    setLoading(true);
    try {
      let response = await fetch(
        `https://panel.validu.co/api/id_records/${data.document_number}?` +
          `name=${data.first_name}&` +
          `last_name=${data.last_name}&` +
          `document_type=` +
          `${
            data.document_type == 0
              ? "cc"
              : data.document_type == 1
              ? "ce"
              : data.document_type == 2
              ? "ti"
              : data.document_type == 3
              ? "nit"
              : data.document_type == 4
              ? "pa"
              : data.document_type == 5
              ? "ppt"
              : ""
          }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            ...contentType,
            "X-Api-Key": "7tGAnJEt.mYLI45qvqeKEI9kMG681i9TQ636q0FWn",
            Authorization: "Bearer " + (await GetAccessToken()),
          },
        }
      );

      setDataAtencedentes(await response.json());
      setShowModalAtencedentes(true);
      /*  return await response.json(); */
    } catch (error) {
      console.error(error);
      return { message: "ERROR_SERVER", error: true };
    }
    setLoading(false);
  };

  const newRequestPage = async (x) => {
    setFrontal(null);
    setReverso(null);
    setRUT(null);
    setSelfi(null);
    setCamaraComercio(null);
    setIsLoading(true);
    setPending(true);
    let data = {
      page: x,
    };
    if (props.brand) {
      data.brand = props.brand;
    }
    data.exclude_brand = true;
    try {
      const response = await AxioInstance.post(
        `/api/list_documents_by_profile/`,
        { headers: await getAuthenticatedHeaders(), data }
      );
      setPage(x);
      setPerPage(1);
      if (response.data.status === 200) {
        response.data.data.results[0].documents.map((item, i) => {
          if (item.type_document.id === 1 || item.type_document.id === 4) {
            setFrontal(i);
          } else if (
            item.type_document.id === 2 ||
            item.type_document.id === 5
          ) {
            setReverso(i);
          } else if (
            item.type_document.id === 3 ||
            item.type_document.id === 6
          ) {
            setRUT(i);
          } else if (item.type_document.id === 7) {
            setCamaraComercio(i);
          } else if (
            item.type_document.id === 12 ||
            item.type_document.id === 13 ||
            item.type_document.id === 10
          ) {
            setSelfi(i);
          }
        });
        setTotalRows(response.data.data.count);
        setDataDocumentos(response.data.data.results);
      }
    } catch (error) {}
    setPending(false);
    setIsLoading(false);
  };
  const fetchSarlaft = async (document, typeDocument) => {
    setLoadingSarlaft(true);
    const response = await AxioInstance.post(`/api/reporte_isolution/`, {
      headers: await getAuthenticatedHeaders(),
      pageNum: page ? page : 1,
      perPage: 10,
      document: document,
      document_type: typeDocument,
    });
    if (response.data.status === 200) {
      let final = JSON.parse(response.data.data.data.Entity);
      if (final.length == undefined) {
        setDataSarlaft(final);
      } else {
        setDataSarlaft(final);
      }
      setLoadingSarlaft(false);
      setModalSarlaft(true);
    }

    setLoadingSarlaft(false);
  };
  const columnas = () => {
    const columns = [
      {
        name: "Datos",
        cell: (row) => (
          <div className="col-span-4 row-span-3  rounded w-full h-full px-2 py-2 bg-white ">
            <p className="text-sm" style={{ color: "rgb(119, 119, 119)" }}>
              Nombre completo
            </p>
            <div className=" flex flex-row justify-start ">
              <div>
                <span
                  title={row.active ? "Activo" : "Inactivo"}
                  style={{
                    marginTop: 5,
                    display: "flex",
                    width: 14,
                    height: 14,
                  }}
                  className={
                    "rounded-full " +
                    (row.active === false
                      ? "Color-red ml-2"
                      : "bg-green-500 ml-2")
                  }
                />
              </div>
              <p
                className="text-lg mb-3"
                style={{ marginLeft: 4 }}
              >{`${row.first_name}\n ${row.last_name}`}</p>
            </div>
            <p
              className="text-sm not-italic	"
              style={{ color: "rgb(119, 119, 119)" }}
            >
              Usuario
            </p>
            <a
              target="_blank"
              href={`${DEV}admin/api/profile/?q=${row.username}`}
              onClick={() => copy(row.username)}
              className=" text-lg  cursor-pointer ml-2 mb-3"
            >
              {row.username}
            </a>
            <p className="text-sm mt-3" style={{ color: "rgb(119, 119, 119)" }}>
              Documento
            </p>
            {!!row.document_number ? (
              <p
                onClick={() => copy(row.document_number)}
                className="copy-cursor text-lg mb-3"
              >
                {row.document_type == 0
                  ? "cc"
                  : row.document_type == 1
                  ? "ce"
                  : row.document_type == 2
                  ? "ti"
                  : row.document_type == 3
                  ? "nit"
                  : row.document_type == 4
                  ? "pa"
                  : row.document_type == 5
                  ? "ppt"
                  : ""}{" "}
                {FORMAT.FormatDocument(row.document_number.toString())}
              </p>
            ) : (
              <p className="text-lg mb-3">no encontrado</p>
            )}
            <p className="text-sm" style={{ color: "rgb(119, 119, 119)" }}>
              {" "}
              Celular
            </p>
            <div className="flex flex-row items-center mb-3 ml-2  ">
              {row.phone_verified ? (
                <img
                  className="flex items-center"
                  src={approve}
                  width={15}
                  height={15}
                />
              ) : (
                <img
                  className="flex items-center"
                  src={cancel}
                  width={15}
                  height={15}
                />
              )}
              {!!row.phone ? (
                <p
                  onClick={() => copy(row.phone)}
                  className="copy-cursor text-lg "
                >
                  {FORMAT.FormatPhoneNumber(row.phone.toString())}
                </p>
              ) : (
                <p className="text-lg mb-3">no encontrado</p>
              )}
            </div>

            <p className="text-sm" style={{ color: "rgb(119, 119, 119)" }}>
              Correo electrónico
            </p>
            <div className="flex flex-row items-center mb-3 ml-2  ">
              {row.email_verified ? (
                <img
                  className="flex items-center"
                  src={approve}
                  width={15}
                  height={15}
                />
              ) : (
                <img
                  className="flex items-center"
                  src={cancel}
                  width={15}
                  height={15}
                />
              )}
              <p className="text-lg  ">{row.email}</p>
            </div>
            <p className="text-sm" style={{ color: "rgb(119, 119, 119)" }}>
              Cumplimiento
            </p>

            <div className="flex flex-row items-center mb-3 ml-2  ">
              {row.by_provider ? (
                <img
                  className="flex items-center"
                  src={approve}
                  width={15}
                  height={15}
                />
              ) : (
                <img
                  className="flex items-center"
                  src={cancel}
                  width={15}
                  height={15}
                />
              )}
              <p
                onClick={() =>
                  row.by_provider == true
                    ? fetchSarlaft(row.document_number, row.document_type)
                    : console.log("nada")
                }
                className={`${
                  row.by_provider == true
                    ? "text-lg   cursor-pointer links"
                    : "text-lg  "
                }`}
              >
                {LoadingSarlaft ? "Cargando..." : "Sarlaft"}
              </p>
            </div>

            <button
              disabled={Loading}
              className={
                " text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mb-3 bg-green-600"
              }
              onClick={() => QueryIdentidad(row)}
              key="0"
            >
              {!Loading ? <span>Revisar antecedentes</span> : "Consultando..."}
            </button>
          </div>
        ),
        sortable: true,
      },
      {
        name: "Selfie",
        cell: (row) => (
          <>
            {row.documents[selfi] && row.documents[selfi].url !== "" ? (
              <div
                onClick={() => AbrirModal(row.documents, selfi)}
                onMouseEnter={() => setHover1(true)}
                onMouseLeave={() => setHover1(false)}
                className="  w-full h-full   relative"
                style={{
                  backgroundColor: "#000000",
                }}
              >
                {Hover1 && (
                  <div
                    className="cursor-pointer duration-150"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)",
                      display: "flex",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      filter: 0.1,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size="100px"
                      className="text-green-600 ml-3  absolute"
                      on
                    />
                  </div>
                )}
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={`${row.documents[selfi].url}`}
                />
                {row.documents[selfi].document_status == 2 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={approve}
                    width={40}
                    height={40}
                  />
                )}
                {row.documents[selfi].document_status == 3 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={cancel}
                    width={40}
                    height={40}
                  />
                )}
              </div>
            ) : (
              <div>
                <p className="text-lg">aún no se sube esta evidencia</p>
              </div>
            )}
          </>
        ),

        sortable: true,
      },
      {
        name: "Cedula Frente",
        cell: (row) => (
          <>
            {row.documents[Frontal] && row.documents[Frontal].url !== "" ? (
              <div
                onClick={() => AbrirModal(row.documents, Frontal)}
                onMouseEnter={() => setHover3(true)}
                onMouseLeave={() => setHover3(false)}
                className="  w-full h-full   relative"
                style={{
                  backgroundColor: "#000000",
                }}
              >
                {Hover3 && (
                  <div
                    className="cursor-pointer duration-150"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)",
                      display: "flex",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      filter: 0.1,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size="100px"
                      className="text-green-600 ml-3  absolute"
                      on
                    />
                  </div>
                )}
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={`${row.documents[Frontal].url}`}
                />
                {row.documents[Frontal].document_status == 2 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={approve}
                    width={40}
                    height={40}
                  />
                )}
                {row.documents[Frontal].document_status == 3 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={cancel}
                    width={40}
                    height={40}
                  />
                )}
              </div>
            ) : (
              <div>
                <p className="text-lg">aún no se sube esta evidencia</p>
              </div>
            )}
          </>
        ),
        sortable: true,
      },
      {
        name: "Cedula Reverso",
        cell: (row) => (
          <>
            {row.documents[Reverso] && row.documents[Reverso].url !== "" ? (
              <div
                onClick={() => AbrirModal(row.documents, Reverso)}
                onMouseEnter={() => setHover2(true)}
                onMouseLeave={() => setHover2(false)}
                className="  w-full h-full   relative"
                style={{
                  backgroundColor: "#000000",
                }}
              >
                {Hover2 && (
                  <div
                    className="cursor-pointer duration-150"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)",
                      display: "flex",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      filter: 0.1,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size="100px"
                      className="text-green-600 ml-3  absolute"
                      on
                    />
                  </div>
                )}
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={`${row.documents[Reverso].url}`}
                />
                {row.documents[Reverso].document_status == 2 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={approve}
                    width={40}
                    height={40}
                  />
                )}
                {row.documents[Reverso].document_status == 3 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={cancel}
                    width={40}
                    height={40}
                  />
                )}
              </div>
            ) : (
              <div>
                <p className="text-lg">aún no se sube esta evidencia</p>
              </div>
            )}
          </>
        ),
        sortable: true,
      },

      {
        name: "RUT",
        cell: (row) => (
          <>
            {row.documents[RUT] && row.documents[RUT].url !== "" ? (
              <div
                onClick={() => AbrirModal(row.documents, RUT)}
                onMouseEnter={() => setHover4(true)}
                onMouseLeave={() => setHover4(false)}
                className="  w-full h-full   relative"
                style={{}}
              >
                {Hover4 && (
                  <div
                    className="cursor-pointer duration-150"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)",
                      display: "flex",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      filter: 0.1,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size="100px"
                      className="text-green-600 ml-3  absolute"
                      on
                    />
                  </div>
                )}
                {row.documents[RUT].url[row.documents[RUT].url.length - 1] ==
                "g" ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={`${row.documents[RUT].url}`}
                  />
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <AiOutlineFilePdf
                      size="100px"
                      style={{ color: "rgb(119, 119, 119)" }}
                    />
                  </div>
                )}

                {row.documents[RUT].document_status == 2 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={approve}
                    width={40}
                    height={40}
                  />
                )}
                {row.documents[RUT].document_status == 3 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={cancel}
                    width={40}
                    height={40}
                  />
                )}
              </div>
            ) : (
              <div>
                <p className="text-lg">aún no se sube esta evidencia</p>
              </div>
            )}
          </>
        ),
        sortable: true,
      },
      {
        name: "Camara de Comercio",
        cell: (row) => (
          <>
            {row.documents[CamaraComercio] &&
            row.documents[CamaraComercio].url !== "" ? (
              <div
                onClick={() => AbrirModal(row.documents, CamaraComercio)}
                onMouseEnter={() => setHover5(true)}
                onMouseLeave={() => setHover5(false)}
                className="  w-full h-full   relative"
                style={{}}
              >
                {Hover5 && (
                  <div
                    className="cursor-pointer duration-150"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)",
                      display: "flex",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      filter: 0.1,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size="100px"
                      className="text-green-600 ml-3  absolute"
                      on
                    />
                  </div>
                )}
                {row.documents[CamaraComercio].url[
                  row.documents[CamaraComercio].url.length - 1
                ] == "g" ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={`${row.documents[CamaraComercio].url}`}
                  />
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <AiOutlineFilePdf size="100px" className="text-gray-600 " />
                  </div>
                )}
                {row.documents[CamaraComercio].document_status == 2 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={approve}
                    width={40}
                    height={40}
                  />
                )}
                {row.documents[CamaraComercio].document_status == 3 && (
                  <img
                    className="mr-2 absolute  flex top-0 right-0	mt-2"
                    src={cancel}
                    width={40}
                    height={40}
                  />
                )}
              </div>
            ) : (
              <div>
                <p className="text-lg">aún no se sube esta evidencia</p>
              </div>
            )}
          </>
        ),
        sortable: true,
      },
    ];
    let final = [];
    columns.map((item, i) => {
      if (item.name === "Datos") {
        final.push(item);
      }
      if (selfi !== null && item.name === "Selfie") {
        final.push(item);
      }
      if (Frontal !== null && item.name === "Cedula Frente") {
        final.push(item);
      }
      if (Reverso !== null && item.name === "Cedula Reverso") {
        final.push(item);
      }
      if (RUT !== null && item.name === "RUT") {
        final.push(item);
      }
      if (CamaraComercio !== null && item.name === "Camara de Comercio") {
        final.push(item);
      }
    });

    /*  if (selfi === null) {
             columns.splice(1, 1)
         }
         if (RUT === null) {
 
             columns.splice(4, 1)
             if (CamaraComercio === null) {
                 columns.splice(4, 1)
             }
         }
         if (CamaraComercio === null) {
             columns.splice(5, 1)
         } */

    return final;
  };

  const Circular = () => (
    <div style={{ padding: "24px" }}>
      {isLoading && <CircularProgress size={75} />}
    </div>
  );

  const CambioStatus = async (status, id, note) => {
    if (latitude == null || longitude == null) {
      setShowBanner(true);
      setShowModal(false);
      setErrorBanner(true);
      setMsn(
        `Para realizar esta acción, debes aceptar los permisos de ubicación del navegador. `
      );
      return;
    }

    const data = {
      status: status,
      id: id,
      note: note,
      long: longitude,
      lati: latitude,
    };
    try {
      const response = await AxioInstance.post(
        `/api/Document/update_status_documents/`,
        {
          headers: await getAuthenticatedHeaders(),
          data,
        }
      );
      if (response.data.status === 200) {
        setShowModal(false);
        setMsn("se cambio el estado del documento");
        setShowBanner(true);
        newRequestPage(page);
      } else {
        setErrorBanner(true);
        setMsn("no se pudo cambio el estado del documento");
        setShowBanner(true);
      }
    } catch (error) {}
  };
  const AbrirModal = async (data, x) => {
    setIDS(x);
    await setDataModal(data);
    setShowModal(true);
  };
  const copy = (text) => {
    var id = "el-id-del-textarea";
    var existsTextarea = document.getElementById(id);

    if (!existsTextarea) {
      var textarea = document.createElement("textarea");
      textarea.id = id;
      // Coloca el textarea en el borde superior izquierdo
      textarea.style.position = "fixed";
      textarea.style.top = 0;
      textarea.style.left = 0;
      textarea.style.textTransform = "lowercase";
      // Asegurate que las dimensiones del textarea son minimas, normalmente 1px
      // 1em no funciona porque esto generate valores negativos en algunos exploradores
      textarea.style.width = "1px";
      textarea.style.height = "1px";

      // No se necesita el padding
      textarea.style.padding = 0;

      // Limpiar bordes
      textarea.style.border = "none";
      textarea.style.outline = "none";
      textarea.style.boxShadow = "none";

      // Evitar el flasheo de la caja blanca al renderizar
      textarea.style.background = "transparent";
      document.querySelector("body").appendChild(textarea);

      existsTextarea = document.getElementById(id);
    } else {
    }

    existsTextarea.value = text;
    existsTextarea.select();

    try {
      var status = document.execCommand("copy");
      if (!status) {
        console.error("No se pudo copiar el texto");
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (Hover1 == true) {
      setHover2(false);
      setHover3(false);
    } else if (Hover2 == true) {
      setHover1(false);
      setHover3(false);
    } else if (Hover3 == true) {
      setHover1(false);
      setHover2(false);
    }
    if (ShowModal == true) {
      setHover1(false);
      setHover2(false);
      setHover3(false);
    }
  }, [Hover1, Hover2, Hover3, ShowModal]);

  const listDocuments = async () => {
    setIsLoading(true);
    setPending(true);
    setFrontal(null);
    setReverso(null);
    setRUT(null);
    setSelfi(null);
    setCamaraComercio(null);
    var username = "";
    var document_number = "";
    if (buscarDocument.length == 1) {
      username = buscarDocument.toString().replace(/\D/g, "");
    } else {
      document_number = buscarDocument.toString().replace(/\D/g, "");
    }
    let data = {
      page: 1,
      username,
      document_number,
    };
    if (props.brand) {
      data.brand = props.brand;
    }
    data.exclude_brand = true;

    try {
      const response = await AxioInstance.post(
        `/api/list_documents_by_profile/`,
        { headers: await getAuthenticatedHeaders(), data }
      );
      setPage(1);
      setPerPage(1);
      if (response.data.status === 200) {
        response.data.data.results[0].documents.map((item, i) => {
          if (item.type_document.id === 1 || item.type_document.id === 4) {
            setFrontal(i);
          } else if (
            item.type_document.id === 2 ||
            item.type_document.id === 5
          ) {
            setReverso(i);
          } else if (
            item.type_document.id === 3 ||
            item.type_document.id === 6
          ) {
            setRUT(i);
          } else if (item.type_document.id === 7) {
            setCamaraComercio(i);
          } else if (
            item.type_document.id === 12 ||
            item.type_document.id === 13
          ) {
            setSelfi(i);
          }
        });
        setTotalRows(response.data.data.count);
        setDataDocumentos(response.data.data.results);
      }
    } catch (error) {}
    setPending(false);
    setIsLoading(false);
  };

  /* const checkUserPassword = async () => {

        if (!password) {
            return;
        }
        axios.post(`/api/login`, { username, password }, {
            headers: await getAuthenticatedHeaders(),
        })
            .then(response => {
                if (response.data.status === 200) {
                    aceptarOeliminar()
                    setShowModal(false);
                    setLoadingAction(true)
                } else {
                    setShowModal(true);
                    setError("Contraseña incorrecta");
                }
            })
    } */
  const paginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
  };

  return (
    <>
      <div className="flex flex-col">
        {showBanner && (
          <Banner
            title={"Proceso realizado"}
            description={msn}
            setShowBanner={setShowBanner}
            error={erroBanner}
          />
        )}
        {ModalSarlaft && (
          <ModalCumplimiento data={DataSarlaft} setModal={setModalSarlaft} />
        )}

        {ShowModal && (
          <ModalDocumentos
            modal={setShowModal}
            setStatus={setStatus}
            setID={setID}
            Data={dataModal}
            CambioStatus={CambioStatus}
            setIDS={setIDS}
            IDS={IDS}
          />
        )}
        {ShowModalAtencedentes && (
          <ModalAntecedentes
            modal={setShowModalAtencedentes}
            Data={dataAtencedentes}
            DataDocumentos={DataDocumentos}
          />
        )}
        <DataTable
          paginationRowsPerPageOptions={numFilas}
          data={DataDocumentos}
          noHeader={true}
          columns={columnas()}
          paginationServer
          pagination={pagination}
          paginationComponentOptions={paginationOptions}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          paginationPerPage={newPerPage}
          progressPending={pending}
          progressComponent={<Circular />}
        />
      </div>
    </>
  );
};

export default Documentos;
