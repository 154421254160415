import React, { useState } from 'react';
import styled from 'styled-components';
import variablesDomain from '../helpers/variablesDomain';

const ToggleContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  padding-left: 1rem;
    padding-right: 1rem;

    padding-bottom: 1rem;
`;

const ToggleInput = styled.input`
  display: none;
`;

const ToggleSlider = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${props => (props.checked ? variablesDomain.colorPrimary : '#ccc')};
  border-radius: 10px;
  position: relative;
  transition: background-color 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: ${props => (props.checked ? '20px' : '1px')};
    transition: left 0.3s;
  }
`;

const ToggleText = styled.p`
  margin-left: 10px;
  font-size: 0.875rem;
  color:#a0aec0;
`;

function ToggleExample(props) {
  const [isProd, setIsProd] = useState(true);

  const handleToggle = () => {

    setIsProd(!isProd);
    props.cambiarDEVorPROD(isProd)
  };

  return (
    <ToggleContainer>
      <ToggleInput
        type="checkbox"
        checked={isProd}
        onChange={handleToggle}
      />
      <ToggleSlider checked={isProd} />
      <ToggleText>{isProd ? 'Ambiente de producción' : 'Ambiente de pruebas'}</ToggleText>
    </ToggleContainer>
  );
}

export default ToggleExample;
