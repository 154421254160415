import React, { useState, useEffect, useContext, useCallback } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import Dropzone from 'react-dropzone';
import { FaFileExcel } from "react-icons/fa";
import { /* token, */ isEmpty, FormatDocument } from '../common/functions';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import File from '../../assets/files/Envios_Lotes.xlsm';
import Select, { StylesConfig } from 'react-select';
import { defaultTheme } from 'react-select';
import { FaSearch } from "react-icons/fa"
import AxioInstance from '../../axios/AxiosConfig';


export default function ModalAddBlackList({ setShowBanner, getListBlackList, modal, setBank, Bank, BankAccountsList = [], bankAccount, setBankAccount, optionValueSelectBanks, BankType, setBankType, BankAcount, setBankAcount, loading, consultar, number, FORMAT, setBannerContent, setBannerError, isLoading = false }) {

    const [Data, setData] = useState([])


    const [filter, setFilter] = useState('')

    const [TypeDocument, setTypeDocument] = useState('')
    const [NumDocument, setNumDocument] = useState('')
    const [Nota, setNota] = useState('')

    const [banco, setBanco] = useState('')
    const [lote, setLote] = useState(false)
    const [nombre, setNombre] = useState('')
    const [TypeCuenta, setTypeCuenta] = useState('')
    const [NumCuenta, setNumCuenta] = useState('')
    const [error, setError] = useState('')
    const [banks, setBanks] = useState('')


    const { getAuthenticatedHeaders, getAccessToken, permissions } = useContext(AuthContext)

    useEffect(() => {
        document.addEventListener("keydown", escFunction);
    }, []);







    const AgregarCuenta = async () => {
        const data = {
            document_number: NumDocument,
            note: Nota !== "" ? Nota : "Habeas Data"
        }
        try {
            const response = await AxioInstance.post(`/api/blacklist/`, {
                headers: await getAuthenticatedHeaders(),
                data: data
            });

            if (response.data.status === 200) {
                let data = response.data.data.result;
                if (data === "OK") {
                    getListBlackList()
                    setShowBanner(true)
                    modal(false)
                }
            }
        } catch (error) {

        }

    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            modal(false)
        }

    })




    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                <div className="" >

                    <div className="flex-col  flex bg-blue-400 h-1/3x md:w-full  rounded border-solid shadow-lg  w-full" style={{ background: "#ffffff" }}>
                        <button
                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => modal(false)}
                        >
                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                        <div className="w-full " style={{ minWidth: 300, }}>
                            <div>
                                <h3 className="text-2xl font-bold text-trixel-popup centrar-contenido">
                                    {'Agregar a Blacklist'}
                                </h3>
                            </div>


                            <div className=" mx-3 mb-6 mt-6">
                                <p></p>
                                <div className="w-full ">
                                    <div className=" mx-3 mb-6 mt-3">
                                        <label className="mx-3">Documento</label>
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            placeholder=""
                                            type="tel"
                                            value={NumDocument}
                                            onChange={(e) => setNumDocument(e.target.value)}
                                        />
                                        {error == "nombre" && <p>Tienes que poner tu nombre completo</p>}

                                    </div>
                                </div>
                                <div className="w-full ">
                                    <div className=" mx-3 mb-6 mt-3">
                                        <label className="mx-3">Nota</label>
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            placeholder="Habeas Data"
                                            type="tel"
                                            value={Nota}
                                            onChange={(e) => setNota(e.target.value)}
                                        />
                                        {error == "nombre" && <p>Tienes que poner tu nombre completo</p>}

                                    </div>
                                </div>
                                {NumDocument !== "" ?
                                    <button
                                        autoFocus={false}
                                        type="reset"
                                        className="w-full  bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off "
                                        onClick={() => AgregarCuenta()}
                                    > Agregar
                                    </button> : (
                                        <button
                                            autoFocus={false}
                                            className="w-full flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                            type="button"

                                        >{!loading ? `Agregar` : "Agregando..."}

                                        </button>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className=" fixed inset-0 z-40 " style={{
                background: 'rgba(47, 46, 44, 0.5)',
                backdropFilter: 'blur(10px)',
            }}></div>
        </>
    );
}
