import React from 'react';
import ReactDOM from 'react-dom';
import './assets/main.css';
import './assets/customStyle.css';
import App from './App';
import 'bootstrap-daterangepicker/daterangepicker.css';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>  
      <App />
  </React.StrictMode>,
  rootElement
);