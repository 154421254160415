import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import approve from "../../../assets/images/approve.svg";
import cancel from "../../../assets/images/cancel.svg";
import { AuthContext } from "../../../context/AuthContext";
import AxioInstance from "../../../axios/AxiosConfig";

export const ChangePassword = ({ handleClose = () => { },idUser=null }) => {
  const { getAuthenticatedHeaders, } = useContext(AuthContext)

  const [changePassword, setChangePassword] = useState("");
  const [confirmChangePassword, setConfirmChangePassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [statusChange, setStatusChange] = useState(null)


  const handleChangePassword =async () => {
    if (changePassword !== confirmChangePassword||changePassword=='') {
      setErrorMessage("Las contraseñas no coinciden");
      return;
    }
    setErrorMessage("");
    let bodyReq = { headers: await getAuthenticatedHeaders(), 
       "password":changePassword,
      "password_confirmation": confirmChangePassword
  }
    if (idUser) {
      bodyReq.pk = idUser
    }
    
    setIsLoading(true)
    const response = await AxioInstance.post(`/api/change_password/`, bodyReq);
    
    if (response?.data.status == 200) {
      setStatusChange(true)
    } else {
      console.log('falso');
      setStatusChange(false)
    }
    setIsLoading(false)


    

  };

  return (
    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <button
        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
        onClick={() => handleClose(false)}
      >
        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
          ×
        </span>
      </button>
      {
        isLoading
        ?
        (
          <div className="relative px-6 pb-1 flex-auto">
          <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
            Cambiando contraseña...
          </h3>
          <div
            style={{
              padding: "24px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {<CircularProgress color="red" size={75} />}
          </div>
        </div>
        )
        :
        null
      }
      {!isLoading&&statusChange===null ? (
        <div className="relative px-6  flex-auto">
          <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
            Cambiar contraseña
          </h3>

          <div className="my-3" />

          <input
            aria-label="Password"
            name="changePassword"
            value={changePassword}
            onChange={(e) => setChangePassword(e.target.value.replace(/\s/g, ""))}
            type="password"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
            placeholder="Nueva contraseña"
          ></input>

          <div className="my-2" />
          <input
            aria-label="Password"
            name="confirmChangePassword"
            value={confirmChangePassword}
            onChange={(e) => setConfirmChangePassword(e.target.value.replace(/\s/g, ""))}
            type="password"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
            placeholder="Repetir contraseña"
          ></input>
          {errorMessage !== "" ? (
            <div className="relative flex justify-center py-3 px-4 mx-auto w-auto">
              <p className="text-red-500 text-xs italic">{errorMessage}</p>
            </div>
          ) : null}
          <div className="w-full flex justify-center py-4">
            <button
              className="bg-green-600 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 rounded-full"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={handleChangePassword}
            >
              Confirmar
            </button>
          </div>
        </div>
      ) : 
      null
      }
      {
              statusChange === true
                ?
                <div className="relative px-6 pb-1 flex-auto">
                <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                  ¡Listo!
                </h3>

                <div
                  style={{
                    padding: "24px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: 'column'
                  }}
                >
                  <img src={approve} className="w-20 h-20" />
                  <p className="text-gray-600 text-sm italic mt-2">La contraseña se ha cambiado con éxito.</p>

                </div>
              </div>
                :
                null
            }
            {
              statusChange === false
                ?
                <div className="relative px-6 pb-1 flex-auto">
                <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                  ¡Ups!
                </h3>

                <div
                  style={{
                    padding: "24px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: 'column'
                  }}
                >
                  <img src={cancel} className="w-20 h-20" />
                  <p className="text-red-500 text-sm italic mt-2">Hubo un problema cambiando la contraseña</p>

                </div>
              </div>
                :
                null
            }
    </div>
  );
};

