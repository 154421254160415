import React, { useState, useContext } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { localeDataPicker } from '../common/functions';
import axios from 'axios';
import Banner from '../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';

import AxioInstance from '../../axios/AxiosConfig';
import variablesDomain from '../../helpers/variablesDomain';
import ReportWithDate from '../../components/ReportWithDate';

//import { getAuthenticatedHeaders} from '../common/functions';

const Reporte = (props) => {


    const getPermissions = localStorage.getItem('userDetails')

    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState("transactions");
    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));
    const ranges = {
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    const locale = localeDataPicker;
    const maxDate = moment();
    const [label, setLabel] = useState("");
    const csvLink = React.useRef();
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)

    //const canViewReport = props.canViewReport;
    const canViewReport = permissions.permission_reports;

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);


    const handleEventSelect = (event) => {
        setValue(event.target.value);
    }

    const handleEvent = (event, picker) => {
        if (event.type === 'apply') {
            setStart_date(picker.startDate);
            setEnd_date(picker.endDate);
        }
    }

    const handleClickReport = (event) => {
        if (value === "THIRD_PARTY_WITHDRAW_PENDINTES") {
            GenerarCSV(0)
            return
        } else if (value === "THIRD_PARTY_WITHDRAW_PROCESO") {
            GenerarCSV(1)
            return
        } else if (value === "THIRD_PARTY_WITHDRAW_CONSIGNADOS") {
            GenerarCSV(3)
            return
        }
        callApiReport(start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'), value);
        event.preventDefault();

    }

    const callApiReport = async (start_date, end_date, type) => {
        setIsLoading(true);
        let urlReq = `/api/reports`
        if (type === 'download_other_payment_for_user') {
            urlReq = `/api/reports/buys`
        }
        AxioInstance.post(urlReq, { start_date, end_date, type, headers: await getAuthenticatedHeaders(), })
            .then(response => {

                if (response.data.status === 200) {
                    if (response.data.statusText === "OK") {
                        setErrorBanner(false)
                        setShowBanner(true)
                    }

                    /*  setData(response.data.data);
                     csvLink.current.link.click(); */
                    setIsLoading(false);
                }

            }).catch(error => setIsLoading(false), setError(error));

    }
    const GenerarCSV = async (state) => {

        setIsLoading(true);
        const response = await AxioInstance.post(`/api/report_by_state_in_csv`, {
            headers: await getAuthenticatedHeaders(),
            state: state,

        });



        if (response.data.status === 200) {


            setErrorBanner(false)
            setShowBanner(true)

            //setCurrentCobrus(final);

        }
        else {
            setErrorBanner(true)
            setShowBanner(true)




        }
        setIsLoading(false);

    };
    const generateMonthlyDateArray = () => {
        const months = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ];

        const year = moment().year();
        let dateArray = [];

        for (let i = 0; i < 12; i++) {
            let date_start = moment([year, i, 1]); // Primer día del mes
            let date_end = moment(date_start).endOf("month"); // Último día del mes

            dateArray.push({
                date_start: date_start.format("YYYY-MM-DD"),
                date_end: date_end.format("YYYY-MM-DD"),
                month: months[i],
            });
        }

        return dateArray;
    };

    const handleExtractoReport = async (
        valueReport,
        start_dateReport,
        end_dateReport,
        sendEmailReport,
        setIsLoadingReport
    ) => {
        setIsLoadingReport(true);
        /*         setDescriptionBanner("");
         */
        let date = generateMonthlyDateArray().find(
            (item) => item.month === valueReport
        );
        JSON.parse(getPermissions)
        const body = {
            date_start: date.date_start,
            date_end: date.date_end,

            headers: await getAuthenticatedHeaders(),
            send_email: sendEmailReport ? "True" : "False",
            currency_code: 'COP',
            email: JSON.parse(getPermissions)?.email,

        }
        console.log("🚀 ~ Reporte ~ body:", body)
        AxioInstance.post(`/api/reports/balance_movement`, body)

            .then((response) => {

                if (response.data.status === 200) {
                    if (response?.data?.data?.message) {
                        /* setDescriptionBanner(response?.data?.data?.message); */
                    }
                    if (response.data.statusText === "OK") {
                        setErrorBanner(false);
                        setShowBanner(true);
                    }
                    if (!sendEmailReport) {
                        /* setDescriptionBanner("Tu reporte fue generado"); */

                        handleClickDowloaded(response?.data?.data, valueReport);
                    }

                    setIsLoadingReport(false);
                } else {
                    if (response?.data?.data?.message) {
                        /* setDescriptionBanner(response?.data?.data?.message); */
                    } else {
                        /* setDescriptionBanner("Hubo un problema con el reporte"); */
                    }
                    setIsLoadingReport(false);
                    setErrorBanner(true);

                    setError(error);
                }
            })
            .catch((error) => {
                setIsLoadingReport(false);
                setError(error);
            });
    };
    const handleClickDowloaded = (DATA, type) => {
        const contenidoCSV = DATA;

        const blob = new Blob([contenidoCSV], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const enlaceDescarga = document.createElement("a");
        enlaceDescarga.href = url;
        enlaceDescarga.download = `${type}-${moment().format("DD/MM/YYYY")}.csv`;

        document.body.appendChild(enlaceDescarga);
        enlaceDescarga.click();

        document.body.removeChild(enlaceDescarga);
        URL.revokeObjectURL(url);
    };
    return canViewReport ? (
        <>
            {showBanner && <Banner
                title={'Proceso realizado'}
                description={!errorBanner ? `Tu reporte esta en proceso y una vez finalizado el proceso será enviado a ${JSON.parse(getPermissions).email}` : "Ups ah ocurrido un error"}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}
            <div className="flex md:flex-row flex-wrap p-4 bg-white p-4">

                <div className="w-full h-12">
                    <p className="font-body text-lg text-gray-800 py-2">Reporte de movimientos</p>
                </div>
                <div className="w-full md:w-2/6 bg-white p-4 text-center">
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3 px-2 py-4">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                Tipo de reporte
                            </label>
                        </div>
                        <div className="md:w-2/3 px-2 py-4">
                            <div className="relative">
                                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" onChange={handleEventSelect} value={value}>
                                    <option value="transactions">Todos</option>
                                    <option value="COBRU">Cobru</option>
                                    <option value="RELOAD">Recarga</option>
                                    <option value="WITHDRAW">Envío</option>
                                    <option value="THIRD_PARTY_WITHDRAW">Retiros depositados</option>
                                    <option value="THIRD_PARTY_WITHDRAW_PENDINTES">Retiros pendientes</option>
                                    <option value="THIRD_PARTY_WITHDRAW_PROCESO">Retiros en proceso</option>
                                    <option value="THIRD_PARTY_WITHDRAW_CONSIGNADOS">Retiros consignados</option>
                                    <option value="download_other_payment_for_user">Compras</option>


                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-2/6 bg-white p-4 text-center ">
                    <div className="max-w-sm rounded overflow-hidden">
                        <div className="px-2 py-4">
                            <div className="font-bold text-xl mb-2 mt-3 text-black">
                                {(value !== "THIRD_PARTY_WITHDRAW_PENDINTES" && value !== "THIRD_PARTY_WITHDRAW_PROCESO" && value !== "THIRD_PARTY_WITHDRAW_CONSIGNADOS") && <DateRangePicker
                                    initialSettings={{
                                        startDate: start_date,
                                        endDate: end_date,
                                        ranges: ranges,
                                        locale: locale,
                                        maxDate: maxDate
                                    }}
                                    onEvent={handleEvent}
                                >
                                    <button>
                                        <div className="px-1 text-gray-700 float-left">
                                            <BsFillCalendarFill />
                                        </div>
                                        <span className="font-bold text-xs float-left">{label}</span>
                                        <div className="float-left text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                    </button>
                                </DateRangePicker>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-2/6 bg-white p-4 text-center">
                    <div className="max-w-sm rounded overflow-hidden">
                        <div className="px-2 py-4">
                            <div className="font-bold text-xl mb-2 text-black">
                                <button className={`bg-${variablesDomain.classNameColor} hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm`} onClick={handleClickReport} disabled={isLoading}>{(isLoading) ? 'Generando...' : 'Generar Reporte'}</button>
                                {/* <CSVLink
                                    data={data}
                                    filename={'cobru-' + label + '.csv'}
                                    className="hidden"
                                    ref={csvLink}
                                    target="_blank"
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReportWithDate
                showOptions={true}
                valueCheckInput={false}
                showDateSelect={false}
                labelOptionReport={"Mes"}
                title="Extracto"
                optionsReport={[
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                ].map((item) => {
                    return { label: item, value: item };
                })}
                selectSecondary={false}
                labelOptionReportSecundary="Tipo"
                handleClickButton={handleExtractoReport}
            />
        </>
    ) : (
        <NoView />
    )

}

export default Reporte;