import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import moment from 'moment';
import OtpInput from 'react-otp-input';
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ModalOTP(props) {
    const [otp, setOtp] = useState('');

    const [contador, setContador] = useState(59)
    const [browserName, setBrowserName] = useState("");
    const [showModal, setShowModal] = useState(true);
    const [sistemaOP, setSistemaOP] = useState("");

    const [color, setColor] = useState("");
    const [colorText, setColorText] = useState("");

    const newRequest = () => {
        setContador(59)
        props.constOTPRequest()
    }


    useEffect(() => {
        if (contador > 0) {
            setTimeout(() => {
                setContador(contador - 1)
            }, 1000);
        }

    }, [contador])
    useEffect(() => {
        if (props.code.length === 4) {
            props.handleSubmit()
        }

    }, [props.code])



    function cortarCorreo(x) {

        let arroba = x.indexOf('@')
        let antes = x.substring(0, arroba)
        let despues = x.substring(arroba, x.length)

        return `${antes}•••${despues}`
    }
    function cortarPhone(x) {
        let antes = x.substring(0, 3)
        let despues = x.substring(3, x.length)
        return `${antes}•••${despues}`
    }
    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-pop-up my-6 mx-auto max-w-md">
                            {!props.isLoading ? <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="relative px-6 pb-1 flex-auto">


                                    <h3 className="mt-8 text-2xl font-bold text-trixel-popup text-center centrar-contenido">
                                        Confirma tu código de ingreso enviado a:
                                    </h3>
                                    <h3 className="text-2xl font-bold text-trixel-popup text-center centrar-contenido">
                                        {cortarCorreo(props.mascara?.email_mask)}
                                    </h3>
                                    <h3 className="mb-4 text-2xl font-bold text-trixel-popup text-center centrar-contenido">
                                        {/* {cortarPhone(props.mascara?.phone_mask)} */}
                                        {props.mascara?.country_code}•••{props.mascara?.phone_mask}

                                    </h3>








                                </div>
                                <OtpInput
                                    value={props.code}
                                    onChange={props.setCode}
                                    numInputs={4}
                                    inputStyle={{ backgroundColor: "#f6f5f6", margin: 2, width: 40, height: 50, borderRadius: 15 }}
                                    containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    renderInput={(props) => <input {...props} />}
                                />
                                {contador == 0 && <button style={{ marginTop: 10 }} onClick={() => newRequest()}>Reenviar</button>}
                                {contador != 0 && < p style={{ textAlign: 'center', marginTop: 10 }}> 0:{contador > 9 ? "" : "0"}{contador}</p>}
                                <div className=" justify-center items-center">

                                </div>
                                <div className="text-2xl centrar-contenido">

                                </div>
                                <div className="items-center justify-end p-2 border-gray-300 rounded-b">
                                    <p className="text-gray-700 text-center text-xs font-hairline">

                                    </p>
                                </div>
                            </div> : <div className="flex md:flex-row flex-wrap p-4">
                                <div className="w-full">
                                    <div style={{ height: "50vh" }}>
                                        <div className="h-full w-full flex items-center justify-center">
                                            <CircularProgress size={75} />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            ) : null
            }
        </>
    );
}
