import React, { useState, useEffect, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import Dropzone from 'react-dropzone';
import { FaFileExcel } from "react-icons/fa";
import { /* token, */ isEmpty } from '../common/functions';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import File from '../../assets/files/Envios_Lotes.xlsm';
import AxioInstance from '../../axios/AxiosConfig';
import approve from "../../assets/images/approve.svg"
import cancel from "../../assets/images/cancel.svg"
import CircularProgress from "@material-ui/core/CircularProgress";
import variablesDomain from "../../helpers/variablesDomain";

export default function ModalRecuperarPassword({ setModal, password_recovery_request, setError, error }) {
    const [Data, setData] = useState([])
    const [TypeDocument, setTypeDocument] = useState("0")
    const [NumDocument, setNumDocument] = useState('')
    const [banco, setBanco] = useState("0")
    const [lote, setLote] = useState(false)
    const [nombre, setNombre] = useState('')
    const [TypeCuenta, setTypeCuenta] = useState("0")
    const [cambio, setCambio] = useState(false)

    const [correo, setCorreo] = useState('')

    const [errorEmail, setErrorEmail] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const { getAuthenticatedHeaders, getAccessToken, permissions } = useContext(AuthContext)



    const validateEmail = (email) => {
        // Verificar si el correo electrónico no está vacío, undefined y contiene un @
        if (email && email.trim().length > 0 && email.includes('@')) {
            // Divide el correo electrónico en dos partes: antes y después del @
            const [localPart, domainPart] = email.split('@');

            // Verifica si ambas partes son válidas
            if (localPart.length > 0 && domainPart.length > 0) {
                // Verifica si la parte de dominio contiene un punto
                if (domainPart.includes('.')) {
                    return (true);
                }
            }
        }
        return false
    };

    const validations = async (e) => {
        setIsLoading(true)
        const result = validateEmail(e)
        if (result === true) {

            const response = await password_recovery_request(e)

        } else {
            setErrorEmail("Por favor, ingresa un correo electrónico válido.")
        }
        setTimeout(() => {
            setCambio(true)
            setIsLoading(false)
        }, 500);


    }
    const close = () => {
        setModal(false)
        setError("")
        setCambio(false)
    }





    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                {isLoading == false && <div className=" flex items-center justify-center" >

                    <div className="flex-col  flex bg-blue-400 h-1/3x   rounded border-solid shadow-lg " style={{ background: "#ffffff", width: "60%" }}>
                        <p className="text-right pr-5 pt-3 text-xl cursor-pointer " onClick={() => close()}>x</p>


                        {!cambio && <div className="w-full ">
                            <div className="flex flex-col ">

                                <label className="mx-3 text-2xl font-bold" >¿Olvidaste tu contraseña?</label>
                                <label className="mx-3 text-lg">Recibe en tu correo electrónico las instrucciones para restaurar tu contraseña</label>
                            </div>
                            <div className=" mx-3 my-6">

                                {/* <label className="mx-3">Correo</label> */}
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    placeholder="Correo"
                                    type="email"
                                    value={(correo)}
                                    onChange={(e) => setCorreo(e.target.value)}
                                />
                                {(error !== "") && (
                                    <div className="relative flex justify-center py-3 px-4 mx-auto w-auto">
                                        <p className="text-red-500 text-xs italic">{errorEmail}</p>
                                    </div>
                                )}
                                <button
                                    onClick={() => validations(correo)}
                                    className={`mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-full text-white focus:outline-none active:bg-indigo-700 transition duration-150 ease-in-out bg-${variablesDomain.classNameColor}`}
                                >
                                    {"Enviar Correo"}
                                </button>
                            </div>
                        </div>}


                        {cambio && error == "" && !isLoading && <div className="w-full ">
                            <div className="flex flex-col items-center">

                                <img className='mb-2' src={approve} width={80} height={80} />
                                <label className="mx-3 text-lg text-center">Te hemos enviado las instrucciones para cambiar tu contraseña a tu correo electrónico.

                                </label>
                            </div>
                            <div className=" mx-3 my-6">



                            </div>
                        </div>}

                        {cambio && (error == "Correo no encontrado" || error == "Problemas con el servicio") && !isLoading && <div className="w-full ">
                            <div className="flex flex-col items-center">

                                <img className='mb-2' src={cancel} width={80} height={80} />
                                <label className="mx-3 text-lg text-center " style={{ width: 500 }}>{`${error}`}</label>
                            </div>
                            <div className=" mx-3 my-6">


                            </div>
                        </div>}


                    </div>


                </div>}

                {isLoading == true && <div className="w-full ">
                    <div className="flex flex-col items-center">
                        <CircularProgress size={45} />
                    </div>
                </div>

                }
            </div >


            <div className=" fixed inset-0 z-40 " style={{
                background: 'rgba(47, 46, 44, 0.5)',
                backdropFilter: 'blur(10px)',
            }}></div>
        </>
    );
}
