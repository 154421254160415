import React, { useState, useRef, useEffect, useCallback } from "react";

import { AiOutlineFilePdf } from "react-icons/ai";





export default function ModalEvidencia(props) {
    const [loaded, setLoaded] = useState(true)
    const ref = useRef()
    useEffect(() => {
        document.addEventListener("keydown", escFunction);
    }, []);
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            props.setModal(false)

        }

    })



    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={() => props.setModal(false)}>
                {props.img && <div className="relative w-auto my-6 mx-auto ">
                    <div style={{ width: "900px", height: !loaded ? '800px' : 'auto', }} className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-transparent outline-none focus:outline-none">
                        {/* <button
                            className="p-1 ml-auto bg-white border-0 rounded-full mx-4 my-4 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute " style={{ right: 0 }}
                            onClick={() => props.setModal(false)}
                        >
                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button> */}

                        {loaded &&
                            <img src={props.img} ref={ref} onError={(e) => {
                                e.target.onerror = null
                                e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Stadtbild_M%C3%BCnchen.jpg/2560px-Stadtbild_M%C3%BCnchen.jpg"
                                setLoaded(false)
                            }
                            } />}
                        {!loaded &&
                            <div className="h-full  bg-white items-center justify-center flex">
                                <iframe src={props.img} className="w-full " style={{ height: '800px' }} frameborder="0" >

                                </iframe>
                            </div>

                        }



                    </div>
                </div>}
            </div>


        </>

    );
}