import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import AxioInstance from '../../axios/AxiosConfig';

//import { getAuthenticatedHeaders } from '../../containers/common/functions';
import { AuthContext } from '../../context/AuthContext';


function Collaborator(props) {


    const { getAuthenticatedHeaders } = useContext(AuthContext)
    const { register, handleSubmit, reset, errors, getValues } = useForm();
    const onSubmit = (data, e) => {

        createCollaborator(data);
        e.target.reset();
    }

    const createCollaborator = async (data) => {
        AxioInstance.post(`/api/create/collaborator`, { data, headers: await getAuthenticatedHeaders() })
            .then(response => {

                if (response.data.pk) {

                    window.location.reload();
                }
            }).catch(error => {


            }
            );

    }

    const hiddenForm = () => {
        props.isToggleHidden();
        reset();
    }

    return (
        <div className="w-full p-4 pt-2 bg-white p-4 shadow-lg">

            <form className="w-full" onSubmit={handleSubmit(onSubmit)} >
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                            Nombres
                        </label>
                        <input className={"appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" + (errors.firstname ? " border-red-500" : "")} id="grid-first-name" type="text" placeholder="Nombre"
                            name="firstname"
                            ref={register({ required: true })}
                        />
                        {errors.firstname && <p className="text-red-500 text-xs italic">Campo requerido</p>}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Apellidos
                        </label>
                        <input className={"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" + (errors.lastname ? " border-red-500" : "")} id="grid-last-name" type="text" placeholder="Apellido"
                            name="lastname"
                            ref={register({ required: true })}
                        />
                        {errors.lastname && <p className="text-red-500 text-xs italic">Campo requerido</p>}

                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                            Correo Electrónico
                        </label>
                        <input className={"appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" + (errors.email ? " border-red-500" : "")} id="grid-email" type="text" placeholder="correo@xxx.com"
                            name="email"
                            ref={register({
                                required: "Correo electrónico requerido",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Correo electrónico invalido"
                                },
                            })}
                        />
                        {errors.email && (
                            <p className="text-red-500 text-xs italic">
                                {errors.email.message}
                            </p>
                        )}

                    </div>
                    {/*  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-phone">
                            Telefono
                        </label>
                        <input className={"appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" + (errors.phone ? " border-red-500" : "")} id="grid-phone" type="text" placeholder="300000000" 
                            name="phone"
                            ref={register ({ 
                                required: "Telefono requerido",
                                
                            })}
                        />
                         {errors.phone && (
                            <p className="text-red-500 text-xs italic">
                                {errors.phone.message}
                            </p>
                        )}

                    </div> */}
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-title">
                            Titulo
                        </label>
                        <input name="title" ref={register({ required: true })} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-title" type="text" placeholder="Sr" />
                        {errors.title && <p className="text-red-500 text-xs italic">Campo requerido</p>}
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-cobrus">
                            Permisos para Cobrar
                        </label>
                        <div className="relative">
                            <select name="permission_cobru" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-cobrus">
                                <option value="0">No permitido</option>
                                <option value="1">Puede ver</option>
                                <option value="3">Puede ver y crear</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-withdraw">
                            Permisos para Retirar
                        </label>
                        <div className="relative">
                            <select name="permission_withdraw" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-withdraw">
                                <option value="0">No permitido</option>
                                <option value="1">Puede ver</option>
                                <option value="3">Puede ver y crear</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-dashboard">
                            Acceso al dashboard
                        </label>
                        <div className="relative">
                            <select name="permission_dashboard" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-dashboard">
                                <option value="true">SI</option>
                                <option value="false">NO</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-reports">
                            Acceso a reportes
                        </label>
                        <div className="relative">
                            <select name="permission_reports" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-reports">
                                <option value="false">NO</option>
                                <option value="true">SI</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-users">
                            Acceso a usuarios
                        </label>
                        <div className="relative">
                            <select name="permission_users" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-users">
                                <option value="false">NO</option>
                                <option value="true">SI</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-consult">
                            Acceso a Consulta
                        </label>
                        <div className="relative">
                            <select name="permission_query" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-consult">
                                <option value="false">NO</option>
                                <option value="true">SI</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-withdraw">
                            Permisio de Colaboradores
                        </label>
                        <div className="relative">
                            <select name="permission_send" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-withdraw">
                                <option value={0}>No permitido</option>
                                <option value={1}>Puede ver</option>
                                <option value={3}>Puede ver y crear</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-active">
                            Activo
                        </label>
                        <div className="relative">
                            <select name="active" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                <option value="true">SI</option>
                                <option value="false">NO</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-active">
                            Permisos para crear colaboradores
                        </label>
                        <div className="relative">
                            <select name="permission_create_colaborador" ref={register} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-withdraw">
                                <option value="true">SI</option>
                                <option value="false">NO</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="flex flex-wrap -mx-3 mb-6">


                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                            Contraseña
                        </label>
                        <input ref={register({ required: "Contraseña requerida!" })} name="password" autocomplete="new-password" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" placeholder="******************" />
                        <p className="text-gray-600 text-xs italic">Fuerte y segura...</p>
                        {errors.password && (
                            <p className="text-red-500 text-xs italic">{errors.password.message}</p>
                        )}
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-confirPass">
                            Confirmar Contraseña
                        </label>
                        <input className={"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"} id="grid-zip" type="password" placeholder="******************"
                            name="password_confirmation"
                            ref={register({
                                required: "Por favor confirmar contraseña!",
                                validate: {
                                    matchesPreviousPassword: value => {
                                        const { password } = getValues();
                                        return password === value || "Las contraseñas deben coincidir!";
                                    }
                                }
                            })}
                        />
                        {errors.passwordConfirmation && (
                            <p className="text-red-500 text-xs italic">
                                {errors.passwordConfirmation.message}
                            </p>
                        )}
                    </div>
                </div>
                {/*  <button
                    type="text"
                    className="flex-shrink-0 ml-3 bg-green-600 hover:bg-green-600 border-green-600 hover:border-green-600 text-sm border-4 text-white py-1 px-2 rounded-full float-left"
                    onClick={() => hiddenForm()}
                >
                    Cancelar
                </button> */}
                <button
                    type="submit"
                    className="flex-shrink-0 bg-green-600 hover:bg-green-600 border-green-600 hover:border-green-600 text-sm border-4 text-white py-2 px-4 rounded-full float-right"

                >
                    Guardar
                </button>
            </form>
        </div>
    );
}

export default Collaborator;