import React from 'react';

const NoView = () => {

    return (
        <>
            <div className="flex md:flex-row flex-wrap p-4">
                <div className="w-full bg-white p-4 text-center ">
                    <div className="rounded overflow-hidden">
                        <p>NO TIENES PERMISOS PARA VER ESTA SECCIÓN</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoView;