import React, { useState, useEffect } from "react";
import { IoMdDownload } from "react-icons/io"
import pending from "../../assets/images/pending-tasks.png"
import moment from 'moment';
import { RiUploadCloudLine } from "react-icons/ri";



export default function ModalUpload({ data, AgregarArchivo, downloadDispersion }) {

    function formatDate(x) {
        // get current timestamp
        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }

    return (
        <>
            <div className="object-right-bottom absolute right-0 flex  mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10  pt-4  flex-col overflow-auto items-center" style={{ width: "30rem" }}>
                <label className="cursor-pointer flex-row flex justify-center items-center bg-trixel-green w-3/4 text-white rounded-full py-1 mb-2" >
                    <input type="file" className="hidden " multiple onChange={(e) => AgregarArchivo(e)}
                        accept="text/plain"

                        autoFocus={false}
                    />
                    <RiUploadCloudLine title="Subir archivo del banco" size="30px" className="mr-4" />
                    <p>Subir archivo del banco</p>

                </label>
                <div style={{ width: "100%" }}>

                    {data.map((item, i) => i < 7 ? (<div className="flex flex-row justify-between  my-4 items-center">
                        <p className="ml-4">Respuesta</p>
                        <p>{formatDate(item.date_created)}</p>
                        {item.file !== null ?
                            <IoMdDownload className="mr-4 cursor-pointer" style={{ width: 25, height: 25, color: "#2E86C1" }} onClick={() => downloadDispersion(item)} /> : <div className=""><img className="mr-4" src={pending} style={{ width: 25, height: 25 }} /></div>
                        }
                    </div>
                    ) : null)}
                    {/* <button className=" text-trixel-green rounded-b-lg py-2">Mas</button> */}
                </div>
            </div>


        </>

    );
}