import React, { useState, useContext, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { FormatAmount, formatoDinero, localeDataPicker } from '../common/functions';
import axios from 'axios';
import Banner from '../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
import Dropzone from 'react-dropzone';
import { /* token, */ isEmpty } from '../common/functions';
import File from '../../assets/files/cobru_plantilla_envios.xlsx';

import AxioInstance from '../../axios/AxiosConfig';
import { CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { FunctionContext } from '../../context/FuntionsContext';
import ModalDescripcionSend from '../layouts/ModalDescripcionSend';
import { FaFileExcel } from 'react-icons/fa';
import ModalEnviar from '../layouts/ModalEnviar';
import Numeral from 'numeral';

//import { getAuthenticatedHeaders} from '../../common/functions';
const filterSwaps = { pending: 'PENDING', approved: 'APPROVED', failed: 'FAILED' }
const NewEnvios = (props) => {
    const { isSuperUser } = props
    const [totalBalance, setTotalBalance] = useState(props.totalBalance);

    const DEV = localStorage.getItem('DEV');

    const getPermissions = localStorage.getItem('userDetails')
    const [msg, setMsg] = useState("")
    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [data, setData] = useState([]);
    const [ModalCheck, setModalCheck] = useState(false);
    const [ModalCheckData, setModalCheckData] = useState([]);
    const [pending, setPending] = useState(true);
    const [bannerError, setBannerError] = useState(false);
    const [description, setDescription] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [isLoadingDrop, setIsLoadingDrop] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [monto, setMonto] = useState('');

    const [value, setValue] = useState("transactions");
    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));
    const ranges = {
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    const locale = localeDataPicker;
    const maxDate = moment();
    const [label, setLabel] = useState("");
    const csvLink = React.useRef();

    const { getAuthenticatedHeaders, permissions, getAccessToken } = useContext(AuthContext)

    const { reloadRetiros, ReloadSwaps, buscar, buscarEnvios, optionSearchSend, EnviarLayout, setEnviarLayout, } = useContext(FunctionContext)

    const canViewReport = permissions.permission_reports;

    const [page, setPage] = useState(1)
    const [newPerPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [listWithdraw, setListWithdraw] = useState([]);
    const [pagination, setPagination] = useState(true);
    const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [numFilas, setNumFilas] = useState([]);
    const [bannerContent, setBannerContent] = useState({
        title: '',
        description: '',
    });
    const [activeFilter, setActiveFilter] = useState(null)

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);





    const getListMovements = async (numPage = 1, paramsReq = {}) => {
        setPending(true);

        try {
            const params = {
                headers: await getAuthenticatedHeaders(),
                pageNum: numPage,
                perPage: newPerPage,
                ...paramsReq
            }

            const response = await AxioInstance.post(isSuperUser ? '/api/sends_superuser' : `/api/sends`, params);


            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)
                const pendings = response.data.data

                let final = !pendings ? [] : pendings
                setListWithdraw(final);
                setListWithdrawFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPending(true);
        try {
            const response = await AxioInstance.post(isSuperUser ? '/api/sends_superuser' : `/api/sends`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: newPerPage,
                filter: activeFilter
            });
            if (response.data.status === 200) {
                const pendings = response.data.data
                let final = !pendings ? [] : pendings
                setPerPage(newPerPage)
                setListWithdraw(final);
                setListWithdrawFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }
    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                pending &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );

    const formatStatus = (status) => {
        let newFormatStatus = ''
        switch (status) {
            case filterSwaps.approved:
                newFormatStatus = 'Aprobado'
                break;
            case filterSwaps.pending:
                newFormatStatus = 'Pendiente'
                break;
            case filterSwaps.failed:
                newFormatStatus = 'Rechazada'
                break;
            default:
                newFormatStatus = ''
                break;
        }
        return newFormatStatus
    }
    function quitarPrefijo(numero) {
        // Utilizamos una expresión regular para encontrar y eliminar el prefijo
        // El patrón busca una cadena seguida por un guion y luego captura el resto
        const patron = /^.*?-(.*)$/;
        const resultado = numero?.replace(patron, '$1');

        return resultado;
    }
    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'Color-red'
        } else if (state === 3) {
            color = 'bg-green-500'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        }
        return color
    }
    const columns = [
        {
            name: 'REF',
            sortable: true,
            selector: 'pk',
            grow: 1,
            cell: row => (

                <div className='flex-row flex items-center justify-start  '>
                    <p className='ml-0'>{row.pk}</p>
                </div>
            )
        },
        {
            name: 'USUARIO',
            grow: 1,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">

                    <span> {row.method === 2 || row.method === 3 ? (row.to_user) : row.method === 1 ? (row.from_user) : (row.to_user)}</span>
                </div>
            )
        },
        {
            name: 'NOMBRE',
            grow: 1,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">

                    <span> {row?.to_alias || ''}</span>
                </div>
            )
        },

        {
            name: 'DEBITADO',
            grow: 2,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {formatoDinero(row.amount)}</span>
                </div>
            )
        },
        {
            name: 'ABONO',
            grow: 2,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {formatoDinero(row.payed_amount !== undefined ? row.payed_amount : row.amount)}</span>
                </div>
            )
        },
        {
            name: 'FECHA DE ENVIO',

            grow: 3,
            sortable: true,
            selector: 'date',
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className="text-gray-700 py-3  flex items-center"> {moment(row.date).format('DD/MM/YYYY HH:mm A')} </span>
                </div>
            )
        },
        {
            name: 'METODO',
            grow: 3,
            wrap: true,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className='ml-3'>{row.method == 2 ? 'Baloto' : row.method == 3 ? 'Efecty' : row.method == 1 ? 'GDE' : 'Billetera'}</span>
                </div>
            )
        },

        /* {
            name: 'MENSAJE',
            grow: 4,
            wrap: true,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className='ml-3'> {row.description || ''}</span>
                </div>
            )
        }, */

    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    const getSearch = async () => {
        if (buscarEnvios?.length !== 0) {

            setPending(true);
            let paramsReq = {}
            //<option value={2}>Documento</option>
            //<option value={3}>Usuario</option>

            if (parseInt(optionSearchSend) === 2) {
                paramsReq.send_id = buscarEnvios
            }
            if (parseInt(optionSearchSend) === 3) {
                paramsReq.document_number = buscarEnvios
            }
            setToggledClearRows(!toggledClearRows)
            getListMovements(1, paramsReq)

        } else {
            setToggledClearRows(!toggledClearRows)
            getListMovements(1)
        }
    }
    const onDrop = async (acceptedFiles) => {
        setShowBanner(false)
        let banner = {}
        if (!isEmpty(acceptedFiles)) {
            const DEV = localStorage.getItem('DEV');

            setIsLoadingDrop(true)
            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            formData.append('typeTask', 'send')
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
                'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

            };

            axios.post(DEV + 'panel/', formData, { headers }, {
            }).then(res => {

                if (res.data.result === "OK") {
                    banner = {
                        title: 'Ok',
                        description: 'Envios en proceso'
                    }

                    setIsLoadingDrop(false)
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(false)
                    setReloadData(!reloadData)
                } else {
                    banner = {
                        title: 'Ocurrio un error',
                        description: 'No se pudo realizar la operacion',
                    }
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(true)
                    setReloadData(!reloadData)

                }
            }).catch((e) => {
                banner = {
                    title: 'Ocurrio un error',
                    description: 'No se pudo realizar la operacion',
                }
                setBannerContent(banner)
                setShowBanner(true)
                setBannerError(true)
                setReloadData(!reloadData)

            })
        }
        setIsLoadingDrop(false)
    }


    const RequestSend = async (data) => {
        try {
            setModalCheckData(data)
            let banner = {}

            const response = await AxioInstance.post(`/api/send/`, {
                headers: await getAuthenticatedHeaders(),
                data
            });

            if ((response.data.status === 200 || response.data.status === 201)) {
                if (response.data.data.error == "USER_TO_DOES_NOT_EXIST") {
                    banner = {
                        title: 'Ocurrio un error',
                        description: 'El usuario al que estás enviando no existe.',
                    }
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(true)
                    setReloadData(!reloadData)
                } else if (response.data.data.error == "NOT_ENOUGH_BALANCE") {
                    banner = {
                        title: 'Ocurrio un error',
                        description: 'No tienes suficiente saldo para hacer el envío.',
                    }
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(true)
                    setReloadData(!reloadData)
                } else {

                    setModalCheckData({...data,pk:response.data.data?.pk})

                    setModalCheck(true)
                    setReloadData(!reloadData)
                }

            } else {
                banner = {
                    title: 'Ocurrio un error',
                    description: 'No se pudo realizar la operacion',
                }
                setBannerContent(banner)
                setShowBanner(true)
                setBannerError(true)
                setReloadData(!reloadData)
            }
            setEnviarLayout(false)
        } catch (error) {

        }
    };
    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setMonto(amount)
        if (amount > 999) {

        }
        let current_balance = parseInt(totalBalance.toString().replace(/\D/g, ""));
        if (amount > current_balance) {
            amount = current_balance;

        } else if (amount < amountValue) {
            amount = amountValue;

        }
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setMonto(withdrawAmount);
    };
    const getBalance = async () => {
        try {
            const response = await AxioInstance.post(`/api/getBalance`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                let pendingBalance = "$0";
                let pendingSaved = "$0";
                let data = response.data.data;

                if (data.pendingBalance > 0) {
                    pendingBalance = FormatAmount(Math.round(data.pendingBalance));
                };
                if (data.saved > 0) {
                    pendingSaved = FormatAmount(Math.round(data.saved));
                };

                setTotalBalance({
                    balance: (data.balance),
                    pendingBalance: pendingBalance,
                    pendingSaved: pendingSaved
                })

            }

        } catch (error) {

        }
    };

    useEffect(() => {
        getSearch()
    }, [buscar]);
    useEffect(() => {
        getBalance()
        setToggledClearRows(!toggledClearRows)
        getListMovements(1);
    }, [ReloadSwaps, activeFilter, reloadData])

    useEffect(() => {
        lasfilas()
    }, [totalRows]);


    return canViewReport ? (
        <>
            {/* {showBanner && <Banner
                title={'Proceso realizado'}
                description={msg}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />} */}
            {showBanner && bannerContent && (
                <div className="w-65">
                    <Banner title={bannerContent.title} error={bannerError} description={bannerContent.description} setShowBanner={setShowBanner} />
                </div>
            )
            }

            {EnviarLayout &&
                <div className="flex md:flex-row flex-wrap  w-1/2">
                    <div className="w-full md:w-full pb-2 pr-2 text-center text-gray-200">
                        <div className="rounded overflow-hidden shadow-lg bg-white">
                            <div className="px-2 py-8">
                                <div className="font-bold text-xl mb-2 text-black">
                                    {totalBalance.balance}
                                </div>
                                <p className="text-gray-700 text-sm">Saldo Disponible</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-full bg-white p-4 text-center text-gray-200 shadow-lg mr-2">
                        <p className="font-body text-lg text-gray-800 text-left py-2">Envios en lote</p>
                        <div className="text-center py-4">
                            <FaFileExcel size="2em" className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-green-600" />
                            <a className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700" href={File} target="blank">
                                <svg className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700 fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                <span>Descargar aquí plantilla Envios </span>
                            </a>

                            <div className="mt-5 border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 cursor-pointer">
                                <Dropzone
                                    onDrop={onDrop}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {!isDragActive && acceptedFiles.length == 0 && '¡Haga clic aquí o arrastre un archivo xlsx para cargarlo!!'}
                                            {isDragActive && !isDragReject && "¡Sueltalo!"}
                                            {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                            <ul className="list-group mt-2">
                                                {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                    <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                        se subio el archivo: {acceptedFile.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    )}
                                </Dropzone>
                                {isLoadingDrop &&
                                    <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </div>


                    </div>


                </div>}
            {EnviarLayout &&
                <div className="flex md:flex-row shadow-lg  w-1/2 ">
                    <div className="w-full md:w-full bg-white p-4 text-center ">
                        <p className="font-body text-lg text-gray-800 text-left py-2">Envios personales</p>
                        <div className="w-full ">
                            <div className=" mx-3 mb-6 mt-3 flex-col flex items-center ">
                                <label className="mx-3">Número de cuenta</label>
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    placeholder="Usuario"
                                    type="text"
                                    value={usuario}
                                    onChange={(e) => setUsuario(e.target.value)}
                                />
                                <label className="mx-3">Número de cuenta</label>
                                <input
                                    className="appearance-none mt-3 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="withdraw_amount"
                                    value={"$" + monto}
                                    onChange={handleChangeWithDrawAmount}
                                    type="tel"
                                    keyboard="numeral"
                                    placeholder="0"
                                    pattern="[0-9]*"

                                ></input>
                                <label className="mx-3">Número de cuenta</label>
                                <input className="appearance-none mt-3 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    placeholder="Descripción"
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                <button
                                    className="w-full mt-3 flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                    type="button"

                                >
                                    {"Enviar"}
                                </button>
                            </div>
                        </div>


                    </div>


                </div>}
            {EnviarLayout && <ModalEnviar setShowModal={setEnviarLayout} totalBalance={totalBalance} onDrop={onDrop} RequestSend={(e) => RequestSend(e)} />}

            {ModalCheck && <ModalDescripcionSend setShowModal={setModalCheck} data={ModalCheckData} />}

            <DataTable
                title={``}
                noHeader={true}
                clearSelectedRows={toggledClearRows}
                paginationResetDefaultPage={toggledClearRows}
                data={listWithdrawFilter}
                columns={columns}
                selectableRowsHighlight={true}
                fixedHeader={true}
                noDataComponent={"No hay movimientos disponibles"}
                pagination={true}
                paginationComponentOptions={paginationOptions}
                progressPending={pending}
                progressComponent={<Circular />}
                paginationTotalRows={totalRows}
                paginationPerPage={newPerPage}
                paginationServer
                paginationRowsPerPageOptions={numFilas}
                onChangePage={getListMovements}
                onChangeRowsPerPage={handlePerRowsChange}
            />
        </>
    ) : (
        <NoView />
    )

}

export default NewEnvios;

