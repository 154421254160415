import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import numeral from "numeral";

import { FormatPhone } from "../common/functions"
import moment from "moment";

export default function ModalDescripcionDebitar(props) {


    function FormatCOPNumber(numbers, commas = false) {
        try {
            let number = Math.round(numbers);
            //algunos inputs de la aplicacion envian textos con "$" para formatearlos,
            //pero estos no se les puede hacer parse int. Por eso lo quitamos si no funciona.
            if (number.toString().includes("$")) {
                number = number.toString().replace(/\D/g, "");
            }
            //si number esta vacio en este momento devolvemos "$0"
            if (!number) {
                return "$0"
            }

            //esta linea mira si debemos agregar un signo negativo al numero cuando es menor a 0
            let prefix = parseInt(number) >= 0 ? "$" : "-$";
            if (!commas) {
                return prefix + numeral(number.toString().replace(/\D/g, "")).format('0,0').replace(/,/g, '.');
            }
            else {
                return prefix + numeral(number).format('0,0');
            }
        }
        catch (error) {

            return "";

        }
    }



    const Close = () => {
        props.setUsernameFrom("")
        props.setModal(false)

    }



    return (
        <>
            {(
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                    >
                        <div className="relative w-pop-up my-6 mx-auto max-w-md">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => Close()}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">
                                    <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">{`Debitado a ${props.usernameFrom}`}</h3>
                                    <div className="flex justify-center items-center mt-4 mb-8">
                                        <img src={require('../../assets/images/approve.svg')} style={{ width: 75, height: 75 }} />
                                    </div>

                                    <div className="my-4 py-2 mb-1 text-gray-600 text-lg leading-relaxed border border-solid rounded">
                                        {/*    <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">De</p>
                                            <p className="rounded-full text-sm w-6/12 text-right">{FormatPhone(props.data.username_to)}</p>
                                        </div> */}
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">Fecha</p>
                                            <p className="rounded-full text-sm w-6/12 text-right">{moment().format('DD/MM/YYYY HH:mm A')}</p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">Usuario</p>
                                            <p className="rounded-full text-sm w-6/12 text-right">{FormatPhone(props.data.username_from)}</p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">Monto</p>
                                            <p className="rounded-full text-sm w-6/12 text-right">{FormatCOPNumber(props.data.amount)}</p>
                                        </div>

                                        {props.data.description && <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">Descripción</p>
                                            <p className="rounded-full text-sm w-6/12 text-right">{(props.data.description)}</p>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            )}
        </>
    );
}