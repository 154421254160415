import React, { useState, useEffect, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import Dropzone from 'react-dropzone';
import { FaFileExcel } from "react-icons/fa";
import { /* token, */ isEmpty } from '../common/functions';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import File from '../../assets/files/Envios_Lotes.a9244447.xlsm';
import AxioInstance from '../../axios/AxiosConfig';


export default function ModalAddRetiros({ handleChangeBankss, setShowModalAgg, refreshRequest, FormatDocument, showModalAgg, setBank, Bank, BankAccountsList = [], bankAccount, setBankAccount, optionValueSelectBanks, BankType, setBankType, BankAcount, setBankAcount, loading, consultar, number, FORMAT, setBannerContent, setShowBanner, setBannerError }) {
    const [Data, setData] = useState([])
    const [TypeDocument, setTypeDocument] = useState("0")
    const [NumDocument, setNumDocument] = useState('')
    const [banco, setBanco] = useState("0")
    const [lote, setLote] = useState(false)
    const [nombre, setNombre] = useState('')
    const [TypeCuenta, setTypeCuenta] = useState("0")
    const [NumCuenta, setNumCuenta] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const { getAuthenticatedHeaders, getAccessToken, permissions } = useContext(AuthContext)


    const onDrop = async (acceptedFiles) => {
        setShowBanner(false)
        let banner = {}
        if (!isEmpty(acceptedFiles)) {


            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            formData.append('typeTask', 'withdraw')
            const DEV = localStorage.getItem('DEV');

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
                'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

            };

            axios.post(DEV + 'panel/', formData, { headers }, {
            }).then(res => {
                if (res.data.result === "OK") {
                    banner = {
                        title: 'Ok',
                        description: 'Retiros en proceso'
                    }
                    setShowModalAgg(false)
                    refreshRequest()
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(false)

                } else {
                    banner = {
                        title: 'Ocurrio un error',
                        description: 'No se pudo realizar la operacion',
                    }
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(true)


                }

            }).catch((e) => {
                banner = {
                    title: 'Ocurrio un error',
                    description: 'No se pudo realizar la operacion',
                }
                setBannerContent(banner)
                setShowBanner(true)
                setBannerError(true)


            })
        }

        setShowModalAgg(false)
    }



    const validations = () => {
        if (nombre.includes(' ')) {

            if (TypeDocument != '' && NumDocument != '' && TypeCuenta != '' && NumCuenta != '' && banco != '') {
                setError('')
                setData({
                    account_holder_name: nombre,
                    account_type: TypeCuenta,
                    account_holder_document: NumDocument,
                    account_holder_document_type: TypeDocument,
                    account_number: NumCuenta,
                    bank_name: banco
                })

                consultar({
                    account_holder_name: nombre,
                    account_type: TypeCuenta,
                    account_holder_document: NumDocument,
                    account_holder_document_type: TypeDocument,
                    account_number: NumCuenta,
                    bank_name: banco
                })
            } else {
                setError('Debes llenar todos los campos')
            }
        } else {
            setError('nombre')

        }
    }

    function formatNumber(e) {
        let hola = e.toString().replace(/\D/g, "");
        return hola;
    }

    const FormatoExcel = (e) => {
        let texto = e.toString()

    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                <div className="" >

                    <div className="flex-col  flex bg-blue-400 h-1/3x   rounded border-solid shadow-lg " style={{ background: "#ffffff", width: "79%" }}>
                        <p className="text-right pr-5 pt-3 text-xl cursor-pointer " onClick={() => setShowModalAgg(false)}>x</p>





                        {!lote && <form className="w-full " >
                            <div className="w-full ">
                                <div className=" mx-3 mb-6 mt-3">
                                    <label className="mx-3">Nombre completo</label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder=""
                                        type="text"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                    {error == "nombre" && <p>Tienes que poner tu nombre completo</p>}

                                </div>
                            </div>
                            <div className="flex flex-row">

                                <div className=" mx-3 w-1/2 ">
                                    <label className="mx-3 ">Tipo de Documento</label>
                                    <select
                                        className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                        name={TypeDocument}
                                        defaultValue=""
                                        autoFocus={false}
                                        value={TypeDocument}
                                        custom={TypeDocument.toString()}
                                        onChange={(e) => setTypeDocument(e.target.value)}
                                    >

                                        <option value="0">Cédula</option>
                                        <option value="1">Cédula de Extranjería</option>
                                        <option value="3">NIT</option>
                                        <option value="2">Tarjeta de Identidad</option>
                                        <option value="4">Pasaporte</option>
                                        <option value="5">Permiso de Protección Temporal</option>
                                    </select>


                                </div>
                                <div className="w-1/2 mx-3">

                                    <label className="mx-3">Número de Documento</label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder=""
                                        maxLength={16}
                                        value={TypeDocument !== "4" ? FORMAT.FormatDocument(NumDocument) : NumDocument}
                                        onChange={(e) => setNumDocument(e.target.value)}
                                    />


                                </div>
                            </div>






                            <div className="flex flex-row  mt-3">

                                <div className=" mx-3 w-1/2">
                                    <label className="mx-3">Banco</label>
                                    <select
                                        className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                        name={banco}
                                        defaultValue=""
                                        autoFocus={false}
                                        value={banco}
                                        custom={banco.toString()}
                                        onChange={(e) => setBanco(e.target.value)}
                                    >
                                        {BankAccountsList.length <= 0 ? (
                                            <option value={bankAccount}>
                                                Cargando...
                                            </option>
                                        ) : (
                                            optionValueSelectBanks()
                                        )}
                                        ;
                                    </select>


                                </div>
                                <div className="mx-3  w-1/2">
                                    <label className="mx-3">Tipo de Cuenta</label>
                                    <select
                                        className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                        name={TypeCuenta}
                                        defaultValue=""
                                        autoFocus={false}
                                        value={TypeCuenta}
                                        custom={TypeCuenta.toString()}
                                        onChange={(e) => setTypeCuenta(e.target.value)}
                                    >
                                        <option value="0">Ahorro</option>
                                        <option value="1">Corriente</option>
                                    </select>


                                </div>

                            </div>




                            <div className="w-full ">
                                <div className=" mx-3 my-6">
                                    <label className="mx-3">Número de cuenta</label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder=""
                                        type="tel"
                                        maxLength={16}
                                        value={formatNumber(NumCuenta)}
                                        onChange={(e) => setNumCuenta(e.target.value)}
                                    />

                                </div>
                            </div>



                            <div className="w-full ">
                                <div className="mx-3 my-6">
                                    {TypeDocument != '' && NumDocument != '' && TypeCuenta != '' && NumCuenta != '' && banco != '' && loading == false ?
                                        <button
                                            autoFocus={false}
                                            type="reset"
                                            className="w-full  bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off "
                                            onClick={() => validations()}
                                        > Agregar
                                        </button> : (
                                            <button
                                                autoFocus={false}
                                                className="w-full flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                                type="button"

                                            >{!loading ? `Agregar cuenta` : "Agregando..."}

                                            </button>)}
                                    {<div className="justify-center items-center w-full flex">
                                        <button
                                            autoFocus={false}
                                            type="reset"
                                            className=" w-1/2 mt-4 text-sm border-4  py-2 rounded-full button-outline-off links border-transparent"
                                            onClick={() => setLote(!lote)}
                                        > {!lote ? "Subir lote de cuentas" : "Agregar cuenta de banco"}
                                        </button>
                                    </div>}
                                </div>
                            </div>

                        </form>}
                        {lote &&
                            <div className="text-center py-4 justify-center items-center flex flex-col px-2">


                                <div className="mt- border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 w-10/12 justify-center items-center flex  cursor-pointer h-full"
                                    style={{ height: "200px" }}
                                >
                                    <Dropzone
                                        onDrop={onDrop}
                                        className="w-10/12"

                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                        {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                            <div {...getRootProps()} >
                                                <input {...getInputProps()} />
                                                {!isDragActive && '¡Haga clic aquí o arrastre un archivo xlsx para cargarlo!!'}
                                                {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                                <ul className="list-group mt-2">
                                                    {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                        <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                            {acceptedFile.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        )}
                                    </Dropzone>
                                    {isLoading &&
                                        <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                                <FaFileExcel size="2em" className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-green-600 mt-8" />
                                <a className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700" href={File} target="_blank">
                                    <svg className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700 fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                    <span>Descargar aquí la plantilla</span>
                                </a>

                            </div>}
                    </div>
                </div>
            </div >

            <div className=" fixed inset-0 z-40 " style={{
                background: 'rgba(47, 46, 44, 0.5)',
                backdropFilter: 'blur(10px)',
            }}></div>
        </>
    );
}
