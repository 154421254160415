import React, { useState, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BsFillCalendarFill } from "react-icons/bs";
import moment from "moment";
import { localeDataPicker } from "../common/functions";
import axios from "axios";
import Banner from "../../components/Banner";
import { CSVLink } from "react-csv";
import NoView from "../layouts/NoView";
import { AuthContext } from "../../context/AuthContext";
//import { getAuthenticatedHeaders} from '../common/functions';
import AxioInstance from "../../axios/AxiosConfig";
import ReportWithDate from "../../components/ReportWithDate";

const ReportesDate = (props) => {
  const getPermissions = localStorage.getItem("userDetails");

  const [showBanner, setShowBanner] = useState(false);
  const [errorBanner, setErrorBanner] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState("transactions");
  const [start_date, setStart_date] = useState(moment().startOf("month"));
  const [end_date, setEnd_date] = useState(moment().endOf("month"));
  const ranges = {
    Ayer: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Últimos 7 Dias": [moment().subtract(6, "days"), moment()],
    "Últimos 30 Días": [moment().subtract(29, "days"), moment()],
    "Último Mes": [moment().startOf("month"), moment().endOf("month")],
    "Mes Anterior": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  const locale = localeDataPicker;
  const maxDate = moment();
  const [label, setLabel] = useState("");
  const csvLink = React.useRef();
  const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);
  const [descriptionBanner, setDescriptionBanner] = useState("");
  //const canViewReport = props.canViewReport;
  const canViewReport = permissions.permission_reports;

  React.useEffect(() => {
    let start = start_date.format("MMM D YYYY");
    let end = end_date.format("MMM D YYYY");
    let label = start + " - " + end;
    if (start === end) {
      label = start;
    }
    setLabel(label);
  }, [start_date, end_date]);

  const handleEventSelect = (event) => {
    setValue(event.target.value);
  };

  const handleEvent = (event, picker) => {
    if (event.type === "apply") {
      setStart_date(picker.startDate);
      setEnd_date(picker.endDate);
    }
  };

  const handleClickReport = (event) => {
    callApiReport(
      start_date.format("YYYY-MM-DD"),
      end_date.format("YYYY-MM-DD"),
      value
    );
    event.preventDefault();
  };

  const callApiReport = async (date_start, date_end, type) => {
    setIsLoading(true);
    AxioInstance.post(`/api/reports/nequi`, {
      date_start,
      date_end,
      headers: await getAuthenticatedHeaders(),
    })
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.statusText === "OK") {
            setErrorBanner(false);
            setShowBanner(true);
          }
          setData(response.data.data.data.reportURL);

          downloadCSV(response.data.data.data.reportURL);
          setData(response.data.data.data.reportURL);

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError(error);
        }
      })
      .catch((error) => setIsLoading(false), setError(error));
  };

  const downloadCSV = async (data) => {
    const link = document.createElement("a");
    if (data == null) return;
    const filename = `${"cobru-" + label + ".csv"}`;
    link.setAttribute("href", data);

    link.setAttribute("download", filename);
    link.click();
    //window.location.reload();
  };
  const handleAccountingReport = async (
    valueReport,
    start_dateReport,
    end_dateReport,
    sendEmailReport,
    setIsLoadingReport
  ) => {
    setIsLoadingReport(true);
    setDescriptionBanner("");

    AxioInstance.post(`/api/reports/accounting`, {
      date_start: start_dateReport.format("YYYY-MM-DD"),
      date_end: end_dateReport.format("YYYY-MM-DD"),
      brand: "NONE",
      headers: await getAuthenticatedHeaders(),
      type_movement: valueReport,
      send_email: sendEmailReport ? "True" : "False",
    })

      .then((response) => {
        console.log("🚀 ~ handleAccountingReport ~ response:", response);

        if (response.data.status === 200) {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          }
          if (response.data.statusText === "OK") {
            setErrorBanner(false);
            setShowBanner(true);
          }
          if (!sendEmailReport) {
            setDescriptionBanner("Tu reporte fue generado");

            handleClickDowloaded(response?.data?.data, valueReport);
          }

          setIsLoadingReport(false);
        } else {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          } else {
            setDescriptionBanner("Hubo un problema con el reporte");
          }
          setIsLoadingReport(false);
          setErrorBanner(true);

          setError(error);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        setError(error);
      });
  };
  const handleReportWithdraw = async (
    valueReport,
    start_dateReport,
    end_dateReport,
    sendEmailReport,
    setIsLoadingReport,
    valueInput
  ) => {
    setIsLoadingReport(true);
    setDescriptionBanner("");

    AxioInstance.post(`/api/reports/download_withdraw_for_amount`, {
      date_start: start_dateReport.format("YYYY-MM-DD"),
      date_end: end_dateReport.format("YYYY-MM-DD"),
      amount: valueInput,
      headers: await getAuthenticatedHeaders(),
      username: valueInput,
    })
      .then((response) => {
        if (response.data.status === 200) {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          }
          if (response.data.statusText === "OK") {
            setErrorBanner(false);
            setShowBanner(true);
          }
          if (!sendEmailReport) {
            setDescriptionBanner("Tu reporte fue generado");

            handleClickDowloaded(response?.data?.data, valueReport);
          }

          setIsLoadingReport(false);
        } else {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          } else {
            setDescriptionBanner("Hubo un problema con el reporte");
          }
          setIsLoadingReport(false);
          setErrorBanner(true);

          setError(error);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        setError(error);
      });
  };

  const handleReportUiaf = async (
    valueReport,
    start_dateReport,
    end_dateReport,
    sendEmailReport,
    setIsLoadingReport,
    amount,
    type
  ) => {
    setIsLoadingReport(true);
    setDescriptionBanner("");
    let date = generateMonthlyDateArray().find(
      (item) => item.month === valueReport
    );

    AxioInstance.post(`/api/reports/report_uiaf`, {
      date_start: date.date_start,
      date_end: date.date_end,
      headers: await getAuthenticatedHeaders(),
      transaction: type === 'Transacciones'
    })
      .then((response) => {
        if (response.data.status === 200) {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          }
          if (response.data.statusText === "OK") {
            setErrorBanner(false);
            setShowBanner(true);
          }
          if (!sendEmailReport) {
            setDescriptionBanner("Tu reporte fue generado");

            handleClickDownloadedTxt(
              response?.data?.data,
              "Reporte_UIAF" + "_" + date.month
            );
          }
          setIsLoadingReport(false);
        } else {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          } else {
            setDescriptionBanner("Hubo un problema con el reporte");
          }
          setIsLoadingReport(false);
          setErrorBanner(true);

          setError(error);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        setError(error);
      });
  };

  const handleAccountingReportForUser = async (
    valueReport,
    start_dateReport,
    end_dateReport,
    sendEmailReport,
    setIsLoadingReport,
    valueInput
  ) => {
    setIsLoadingReport(true);
    setDescriptionBanner("");

    AxioInstance.post(`/api/reports/mutu`, {
      date_start: start_dateReport.format("YYYY-MM-DD"),
      date_end: end_dateReport.format("YYYY-MM-DD"),
      type: valueReport,
      headers: await getAuthenticatedHeaders(),
      username: valueInput,
    })

      .then((response) => {
        console.log("🚀 ~ handleAccountingReport ~ response:", response);

        if (response.data.status === 200) {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          }
          if (response.data.statusText === "OK") {
            setErrorBanner(false);
            setShowBanner(true);
          }
          if (!sendEmailReport) {
            setDescriptionBanner("Tu reporte fue generado");

            handleClickDowloaded(response?.data?.data, valueReport);
          }

          setIsLoadingReport(false);
        } else {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          } else {
            setDescriptionBanner("Hubo un problema con el reporte");
          }
          setIsLoadingReport(false);
          setErrorBanner(true);

          setError(error);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        setError(error);
      });
  };

  const handleClickDowloaded = (DATA, type) => {
    const contenidoCSV = DATA;

    const blob = new Blob([contenidoCSV], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const enlaceDescarga = document.createElement("a");
    enlaceDescarga.href = url;
    enlaceDescarga.download = `${type}-${moment().format("DD/MM/YYYY")}.csv`;

    document.body.appendChild(enlaceDescarga);
    enlaceDescarga.click();

    document.body.removeChild(enlaceDescarga);
    URL.revokeObjectURL(url);
  };

  const handleClickDownloadedTxt = (DATA, type) => {
    const contenidoTxt = DATA;

    const blob = new Blob([contenidoTxt], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const enlaceDescarga = document.createElement("a");
    enlaceDescarga.href = url;
    enlaceDescarga.download = `${type}-${moment().format("DD-MM-YYYY")}.txt`;

    document.body.appendChild(enlaceDescarga);
    enlaceDescarga.click();

    document.body.removeChild(enlaceDescarga);
    URL.revokeObjectURL(url);
  };
  const typesReportForUser = [
    { value: "SENDS", label: "Envíos" },
    { value: "COBRUS", label: "Cobrus" },
    { value: "RELOADS", label: "Recargas" },
    { value: "WITHDRAWALS", label: "Retiros" },
    { value: "other_payments", label: "Compras" },
  ];

  const generateMonthlyDateArray = () => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const year = moment().year();
    let dateArray = [];

    for (let i = 0; i < 12; i++) {
      let date_start = moment([year, i, 1]); // Primer día del mes
      let date_end = moment(date_start).endOf("month"); // Último día del mes

      dateArray.push({
        date_start: date_start.format("YYYY-MM-DD"),
        date_end: date_end.format("YYYY-MM-DD"),
        month: months[i],
      });
    }

    return dateArray;
  };

  const handleExtractoReport = async (
    valueReport,
    start_dateReport,
    end_dateReport,
    sendEmailReport,
    setIsLoadingReport,
    amount,
    type, email
  ) => {
    setIsLoadingReport(true);
    /*         setDescriptionBanner("");
     */
    let date = generateMonthlyDateArray().find(
      (item) => item.month === type
    );
    const body = {
      date_start: start_dateReport ? start_dateReport.format("YYYY-MM-DD") : date.date_start,
      date_end: end_dateReport ? end_dateReport.format("YYYY-MM-DD") : date.date_end,
      username: amount,
      headers: await getAuthenticatedHeaders(),
      send_email: sendEmailReport ? "True" : "False",
      currency_code: 'COP',
      email: email !== '' && sendEmailReport ? email : JSON.parse(getPermissions)?.email,

    }
    console.log("🚀 ~ ReportesDate ~ body:", body)
    AxioInstance.post(`/api/reports/balance_movement`, body)

      .then((response) => {

        if (response.data.status === 200) {
          if (response?.data?.data?.message) {
            /* setDescriptionBanner(response?.data?.data?.message); */
          }
          if (response.data.statusText === "OK") {
            setErrorBanner(false);
            setShowBanner(true);
          }
          if (!sendEmailReport) {
            setDescriptionBanner("Tu reporte fue generado");

            handleClickDowloaded(response?.data?.data, valueReport);
          } else {
            setDescriptionBanner(`El reporte será enviado a: ${email !== '' && sendEmailReport ? email : JSON.parse(getPermissions)?.email}`);

          }

          setIsLoadingReport(false);
        } else {
          if (response?.data?.data?.message) {
            setDescriptionBanner(response?.data?.data?.message);
          } else {
            setDescriptionBanner("Hubo un problema con el reporte");
          }
          setIsLoadingReport(false);
          setErrorBanner(true);

          setError(error);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        setError(error);
      });
  };

  return canViewReport ? (
    <>
      {showBanner && (
        <Banner
          title={"Proceso realizado"}
          description={descriptionBanner}
          setShowBanner={setShowBanner}
          error={errorBanner}
        />
      )}
      {/*         <div className="flex md:flex-row flex-wrap bg-white p-4">
          <div className="w-full h-12">
            <div className="w-full md:w-2/6 bg-white p-4 text-center ">
              <p className="font-body text-lg text-gray-800 text-left py-2">
                Reporte de Nequi
              </p>
            </div>
          </div>

          <div className="w-full md:w-2/6 bg-white p-4 text-left ">
            <div className="max-w-sm rounded overflow-hidden">
              <div className="px-2 py-4">
                <div className="font-bold text-xl mb-2 mt-3 text-black">
                  <DateRangePicker
                    initialSettings={{
                      startDate: start_date,
                      endDate: end_date,
                      ranges: ranges,
                      locale: locale,
                      maxDate: maxDate,
                    }}
                    onEvent={handleEvent}
                  >
                    <button>
                      <div className="px-1 text-gray-700 float-left">
                        <BsFillCalendarFill />
                      </div>
                      <span className="font-bold text-xs float-left">
                        {label}
                      </span>
                      <div className="float-left text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </button>
                  </DateRangePicker>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/6 bg-white p-4 text-center">
            <div className="max-w-sm rounded overflow-hidden">
              <div className="px-2 py-4">
                <div className="font-bold text-xl mb-2 text-black">
                  <a href={data} target={"_blank"}>
                    <button
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm"
                      onClick={handleClickReport}
                      disabled={isLoading}
                    >
                      {isLoading ? "Generando..." : "Generar "}
                    </button>
                    <CSVLink
                      data={data}
                      filename={"cobru-" + label + ".csv"}
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      <ReportWithDate
        title="Reportes contables"
        optionsReport={[
          { label: "Todos", value: "todo" },
          { label: "Cobru", value: "cobru" },
          { label: "Recarga", value: "recarga" },
          { label: "Retiro", value: "retiro" },
          { label: "Chequera", value: "chequera" },
          { label: "Recarga a dispositivo", value: "dispositivo" },
          {
            label: "--------------------------------------",
            value: null,
            isDisabled: true,
          },
          { label: "Exogena", value: "exogena" },
          {
            label: "--------------------------------------",
            value: null,
            isDisabled: true,
          },
          { label: "Crypto", value: "crypto" },
          { label: "Gmf", value: "gmf" },
          { label: "CUSD", value: "CUSD" },
          { label: "Ventas de Cryptos", value: "sales_crypto" },
          { label: "Transacciones de Cryptos", value: "transactions_crypto" },
        ]}
        handleClickButton={handleAccountingReport}
      />
      <ReportWithDate
        showCheckInput={false}
        valueCheckInput={false}
        showInput
        title="Reportes por usuario"
        optionsReport={typesReportForUser}
        handleClickButton={handleAccountingReportForUser}
        placeholderInput="Usuario"
      />
      <ReportWithDate
        showCheckInput={false}
        showOptions={false}
        valueCheckInput={false}
        showInput
        title="Reporte de retiros"
        optionsReport={[{ value: "RetirosPorMontos", label: "" }]}
        handleClickButton={handleReportWithdraw}
        placeholderInput="$0"
        isAmountInput
        labelOptionReport="Monto"
      />
      <ReportWithDate
        showCheckInput={false}
        showOptions={true}
        valueCheckInput={false}
        showDateSelect={false}
        labelOptionReport={"Mes"}
        title="Reporte UIAF"
        optionsReport={[
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ].map((item) => {
          return { label: item, value: item };
        })}
        selectSecondary={true}
        optionsReportSecundary={[{ label: 'Clientes', value: "Clientes" }, { label: 'Transacciones', value: "Transacciones" }]}
        labelOptionReportSecundary="Tipo"
        handleClickButton={handleReportUiaf}

      />

      {/*       <ReportWithDate

        showOptions={false}
        valueCheckInput={false}
        showInput
        showDateSelect={false}
        showOptionSecundary
        labelOptionReport="Usuario"
        labelOptionReportSecundary={"Mes"}
        title="Extracto"
        optionsReportSecundary={[
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ].map((item) => {
          return { label: item, value: item };
        })}
        selectSecondary={true}
        handleClickButton={(valueReport,
          start_dateReport,
          end_dateReport,
          sendEmailReport,
          setIsLoadingReport,
          amount,
          type) => {

          handleExtractoReport(valueReport,
            null,
            null,
            sendEmailReport,
            setIsLoadingReport,
            amount,
            type)
        }
        }
      /> */}
      <ReportWithDate

        showOptions={false}
        valueCheckInput={false}
        showInput
        showDateSelect={true}
        showOptionSecundary
        labelOptionReport="Usuario"
        title="Extracto"

        handleClickButton={(valueReport,
          start_dateReport,
          end_dateReport,
          sendEmailReport,
          setIsLoadingReport,
          amount,
          type, email) => {

          handleExtractoReport(valueReport,
            start_dateReport,
            end_dateReport,
            sendEmailReport,
            setIsLoadingReport,
            amount,
            type, email)
        }
        }
        showSecondInput
        placeholderInput="Usuario"
        placeholderSecondInput="Correo"
        dateSelectRanges={{
          Enero: [
            moment().month(0).startOf("month"), // Enero es el mes 0
            moment().month(0).endOf("month"),
          ],
          Febrero: [
            moment().month(1).startOf("month"),
            moment().month(1).endOf("month"),
          ],
          Marzo: [
            moment().month(2).startOf("month"),
            moment().month(2).endOf("month"),
          ],
          Abril: [
            moment().month(3).startOf("month"),
            moment().month(3).endOf("month"),
          ],
          Mayo: [
            moment().month(4).startOf("month"),
            moment().month(4).endOf("month"),
          ],
          Junio: [
            moment().month(5).startOf("month"),
            moment().month(5).endOf("month"),
          ],
          Julio: [
            moment().month(6).startOf("month"),
            moment().month(6).endOf("month"),
          ],
          Agosto: [
            moment().month(7).startOf("month"),
            moment().month(7).endOf("month"),
          ],
          Septiembre: [
            moment().month(8).startOf("month"),
            moment().month(8).endOf("month"),
          ],
          Octubre: [
            moment().month(9).startOf("month"),
            moment().month(9).endOf("month"),
          ],
          Noviembre: [
            moment().month(10).startOf("month"),
            moment().month(10).endOf("month"),
          ],
          Diciembre: [
            moment().month(11).startOf("month"),
            moment().month(11).endOf("month"),
          ],
        }}
      />
    </>

  ) : (
    <NoView />
  );
};

export default ReportesDate;




/* transaccional */