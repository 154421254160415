import React, { useState, useEffect, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BsFillCalendarFill } from "react-icons/bs";
import Apexcharts from "react-apexcharts";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import { localeDataPicker, FormatAmount } from "../../common/functions";
import NoView from "../../layouts/NoView";
import { AuthContext } from "../../../context/AuthContext";
import AxioInstance from "../../../axios/AxiosConfig";
import { async } from "q";
import { CircularProgress } from "@material-ui/core";
const movementType = {
  recargas: 'RELOADCOP',
  retiros: 'RETIROS',
  envios: 'SENDS',
  cambios: 'SWAPS',
  recargasUSD:'RecargasUSD',
  enviosUSD:'EnviosUSD',
  retirosDepositados:'withdraw_depositados',
  singlePay:'association'
};
const ResumenMutu = (props) => {
  const [cobrus, setCobrus] = useState(props.cobrus);
  const [state0, setState0] = useState(props.state0);
  const [state1, setState1] = useState(props.state1);
  const [state3, setState3] = useState(props.state3);
  const [amount0, setAmount0] = useState(0);
  const [amount1, setAmount1] = useState(0);
  const [amount3, setAmount3] = useState(0);
  const [totalAmount, setTotalAmount] = useState("$0");

  const [totalCobrus, setTotalCobrus] = useState("$0");
  const [balance, setBalance] = useState(props.totalBalance.balance);
  const [pendingBalance, setPendingBalance] = useState(
    props.totalBalance.pendingBalance
  );
  const [pendingSaved, setPendingSaved] = useState(
    props.totalBalance.pendingSaved
  );
  const [balance_saved, setbalance_saved] = useState("$0");
  const [pendingTasks, setPendingTasks] = useState([]);
  const [labelArea, setLabelArea] = useState("");
  const [labelPie, setLabelPie] = useState("");
  const [labelBox, setLabelBox] = useState("");
  const [labelCobrus, setLabelCobrus] = useState("");
  const [startDate, setStartDate] = useState(() => moment().startOf("month"));
  const [endDate, setEndDate] = useState(() => moment().endOf("month"));
  const [maxDate, setMaxDate] = useState(() => moment());
  const [labelFecha, setLabelFecha] = useState("");

  const [recargasBalance, setRecargasBalance] = useState(0);
  const [cambiosBalance, setCambiosBalance] = useState(0);
  const [enviosBalance, setEnviosBalance] = useState(0);
  const [retirosBalance, setRetirosBalance] = useState(0);
  const [loadingRecargas, setloadingRecargas] = useState(false);
  const [loadingCambios, setloadingCambios] = useState(false);
  const [loadingEnvios, setloadingEnvios] = useState(false);
  const [loadingRetiros, setloadingRetiros] = useState(false);

  const [recargasUsdBalance, setRecargasUsdBalance] = useState(0);
  const [cambiosUsdBalance, setCambiosUsdBalance] = useState(0);
  const [enviosUsdBalance, setEnviosUsdBalance] = useState(0);
  const [loadingBalances, setloadingBalances] = useState(false);
  const [RetirosUSDBalance, setRetirosUSDBalance] = useState(0)
  const [loadingRetirosUSD, setloadingRetirosUSD] = useState(false)
  const [loadingEnviosUsd, setloadingEnviosUsd] = useState(false)
  const [movement_countRecargas, setMovement_countRecargas] = useState(0)
  const [movement_countCambios, setMovement_countCambios] = useState(0)
  const [movement_countEnvios, setMovement_countEnvios] = useState(0)
  const [movement_countRetiros, setMovement_countRetiros] = useState(0)
  const [movement_countRecargasUsd, setMovement_countRecargasUsd] = useState(0)
  const [movement_countEnviosUsd, setMovement_countEnviosUsd] = useState(0)
  const [movement_countRetirosDepositado, setMovement_countRetirosDepositado] = useState(0)
  const [loadingRetirosDepositados, setloadingRetirosDepositados] = useState(false)
  const [retirosDepositadosBalance, setRetirosDepositadosBalance] = useState(0);

  const [movement_countSinglePay, setMovement_countSinglePay] = useState(0)
  const [loadingSinglePay, setloadingSinglePay] = useState(false)
  const [singlePayBalance, setSinglePayBalance] = useState(0);

  const [loadingUsersBalance, setLoadingUsersBalance] = useState(false)
  const [usersBalance, setUsersBalance] = useState({users: '', total_balance_to_cop: '0', total_balance_to_usd: '0'});
  
  const getInitialBalances = async (START_DATE,END_DATE) => {
    setloadingRecargas(true);
    setloadingCambios(true);
    setloadingRetiros(true);
    setloadingEnvios(true);
    /* setloadingRetirosUSD(true) */
    setloadingEnviosUsd(true)
    setloadingRetirosDepositados(true);
    setloadingSinglePay(true);





    AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.recargas,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countRecargas(movementCount)
        setRecargasBalance(totalFee);
      }
      setloadingRecargas(false);
    });

    AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.cambios,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countCambios(movementCount)
        setCambiosBalance(totalFee);
      }
      setloadingCambios(false);
    });

    await AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.envios,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countEnvios(movementCount)

        setEnviosBalance(totalFee);
      }
      setloadingEnvios(false);
    });

    AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.retiros,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countRetiros(movementCount)

        setRetirosBalance(totalFee);
      }

      setloadingRetiros(false);
    });
    AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.retirosDepositados,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countRetirosDepositado(movementCount)

        setRetirosDepositadosBalance(totalFee);
      }

      setloadingRetirosDepositados(false);
    });

    
    /* AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.recargasUSD,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countRecargasUsd(movementCount)

        console.log("data.data.data", totalFee);
        setRetirosUSDBalance(totalFee);
      }

      setloadingRetirosUSD(false);
    });
 */
    AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.enviosUSD,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countEnviosUsd(movementCount)

        setEnviosUsdBalance(totalFee);
      }

      setloadingEnviosUsd(false);
    });


    await AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.singlePay,
      date_start: START_DATE.format("YYYY-MM-DD"),
      date_end: END_DATE.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countSinglePay(movementCount)

        setSinglePayBalance(totalFee);
      }
      setloadingSinglePay(false);
    });

    return;
    setloadingBalances(true);
    const recargas = await AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.recargas,
      date_start: startDate.format("YYYY-MM-DD"),
      date_end: endDate.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    });
    /*         .then(({data})=>{
            if (data?.status==200) {
                const totalFee=data?.data?.data?.total_fee
                console.log('data.data.data',totalFee)
                setRecargasBalance(totalFee)
            }
        }); */
    if (recargas.data?.status == 200) {
      const totalFee = recargas.data?.data?.data?.total_fee;
      setRecargasBalance(totalFee);
    }
    const cambios = await AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.cambios,
      date_start: startDate.format("YYYY-MM-DD"),
      date_end: endDate.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    });
    /*         .then(({data})=>{
            if (data?.status==200) {
                const totalFee=data?.data?.data?.total_fee
                console.log('data.data.data',totalFee)
                setCambiosBalance(totalFee)
            }
        }); */
    if (cambios.data?.status == 200) {
      const totalFee = cambios.data?.data?.data?.total_fee;
      console.log("data.data.data", totalFee);
      setCambiosBalance(totalFee);
    }

    const envios = await AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.envios,
      date_start: startDate.format("YYYY-MM-DD"),
      date_end: endDate.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    });
    /*    .then(({data})=>{
            if (data?.status==200) {
                const totalFee=data?.data?.data?.total_fee
                console.log('data.data.data',totalFee)
                setEnviosBalance(totalFee)
            }
        }); */
    if (envios.data?.status == 200) {
      const totalFee = envios.data?.data?.data?.total_fee;
      console.log("data.data.data", totalFee);
      setEnviosBalance(totalFee);
    }

    const retiros = await AxioInstance.post(`/api/get_total_fee_movement/`, {
      headers: await getAuthenticatedHeaders(),
      movementType: movementType.retiros,
      date_start: startDate.format("YYYY-MM-DD"),
      date_end: endDate.format("YYYY-MM-DD"),
      brand: "MUTUAL",
    });
    /*         .then(({data})=>{
            if (data?.status==200) {
                const totalFee=data?.data?.data?.total_fee
                console.log('data.data.data',totalFee)
                setRetirosBalance(totalFee)
            }
        }); */
    if (retiros.data?.status == 200) {
      const totalFee = retiros.data?.data?.data?.total_fee;
      console.log("data.data.data", totalFee);
      setRetirosBalance(totalFee);
    }
    setloadingBalances(false);
    /*  const recargas = await AxioInstance.post(`/api/get_total_fee_movement/`, {
            headers: await getAuthenticatedHeaders(),
            movementType:movementType.recargas
        });
        const envios = await AxioInstance.post(`/api/get_total_fee_movement/`, {
            headers: await getAuthenticatedHeaders(),
            movementType:movementType.envios
        });
        const cambios = await AxioInstance.post(`/api/get_total_fee_movement/`, {
            headers: await getAuthenticatedHeaders(),
            movementType:movementType.cambios
        }); */
  };
  const getBalanceAllUsers= async() => {
    setLoadingUsersBalance(true)
    AxioInstance.post(`/api/total_balance_profile/`, {
      headers: await getAuthenticatedHeaders(),
      brand: "MUTUAL",
    }).then(({ data }) => {
      if (data?.status == 200) {
        let finalData=data?.data?.data
        setUsersBalance({...usersBalance,total_balance_to_cop:finalData?.total_balance_to_cop, total_balance_to_usd:finalData?.total_balance_to_usd})
       /*  const totalFee = data?.data?.data?.total_fee;
        const movementCount=data?.data?.data?.movement_count;
        setMovement_countEnviosUsd(movementCount)

        console.log("data.data.data", totalFee);
        setEnviosUsdBalance(totalFee); */
      }
      setLoadingUsersBalance(false)
     /*  setloadingEnviosUsd(false); */
    });
  }
  const ranges = {
    Hoy: [moment(), moment()],
    "Últimos 7 Dias": [moment().subtract(6, "days"), moment()],
    "Últimos 30 Días": [moment().subtract(29, "days"), moment()],
    "Último Mes": [moment().startOf("month"), moment().endOf("month")],
    "Mes Anterior": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  //CHART DATA
  const [series, setSeries] = useState([
    {
      name: "RECAUDOS",
      data: [],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    title: {
      text: "Recaudos",
      align: "center",
    },
    tooltip: {
      theme: "dark",
    },
    labels: [],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      opposite: false,
    },
    legend: {
      horizontalAlign: "right",
    },
  });
  //PIE CHART CONFIG
  const [seriesPie, setSeriesPie] = useState([]);
  const [optionsPie, setOptionsPie] = useState({
    chart: {
      width: 400,
      type: "pie",
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      floating: false,
      fontSize: "10px",
      offsetX: 0,
      offsetY: 0,
    },
    labels: ["Baloto", "Efecty", "PSE", "Tarjeta de Credito", "Cobru", "Otros"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
            left: 0,
          },
        },
      },
    ],
  });

  const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);
  const canViewResumen = permissions.permission_dashboard;

  useEffect(() => {
    if (!isEmpty(cobrus)) {
      updateDataChartSale(startDate, endDate, cobrus);
      updateDataChartPaymentMethod(startDate, endDate, cobrus);
      updateTotalDataChartCobrusPaid(startDate, endDate, cobrus);
    }
  }, [startDate, endDate, cobrus]);


  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const handleEvent = (event, picker) => {
    if (event.type === "apply") {
      setStartDate(picker.startDate);
      setEndDate(picker.endDate);
      getInitialBalances(picker.startDate,picker.endDate)
      updateDataChartSale(startDate, endDate, cobrus);
    }
  };

  const handleEventPaymentMethod = (event, picker) => {
    if (event.type === "apply") {
      setStartDate(picker.startDate);
      setEndDate(picker.endDate);
      updateDataChartPaymentMethod(startDate, endDate, cobrus);
    }
  };

  const handleEventCobrusPaid = (event, picker) => {
    if (event.type === "apply") {
      setStartDate(
        picker.startDate
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      );
      setEndDate(
        picker.endDate
          .subtract(5, "hours")
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      );

      updateTotalDataChartCobrusPaid(startDate, endDate, cobrus);
    }
  };
  const getBalance = async () => {
    try {
      const response = await AxioInstance.post(`/api/getBalance`, {
        headers: await getAuthenticatedHeaders(),
      });

      if (response.data.status === 200) {
        let pendingBalance = "$0";
        let pendingSaved = "$0";
        let data = response.data.data;

        if (data.pendingBalance > 0) {
          pendingBalance = FormatAmount(Math.round(data.pendingBalance));
        }
        if (data.saved > 0) {
          pendingSaved = FormatAmount(Math.round(data.saved));
        }

        setBalance(FormatAmount(data.balance));
      }
    } catch (error) {}
  };
  const updateTotalDataChartCobrusPaid = (startDate, endDate, cobrus) => {
    let startCobrus = startDate.format("MMM D YYYY");
    let endCobrus = endDate.format("MMM D YYYY");
    let labelCobrus = startCobrus + " - " + endCobrus;
    if (startCobrus === endCobrus) {
      setLabelCobrus(startCobrus);
    } else {
      setLabelCobrus(labelCobrus);
    }

    let totalAmount = 0;
    let items = _.filter(cobrus, function (o) {
      return (
        o.date_payed >= startDate && o.date_payed <= endDate && o.state === 3
      );
    });

    let totalCobrus = items.length;

    items.forEach((element) => {
      let digits = element.amount.toString().replace(/\D/g, "");
      totalAmount += parseInt(digits);
    });

    totalAmount = FormatAmount(totalAmount);
    totalCobrus = items.length;

    setTotalAmount(totalAmount);
    setTotalCobrus(totalCobrus);
  };

  /* useEffect(() => {
        getBalance()
        getState(0)
        getState(1)
        getState(3)
    }, []) */
  React.useEffect(() => {
    let start = startDate.format("MMM D YYYY");
    let end = endDate.format("MMM D YYYY");
    let label = start + " - " + end;
    if (start === end) {
      label = start;
    }
    setLabelFecha(label);
  }, [startDate, endDate]);

  const updateDataChartSale = (startDate, endDate, cobrus) => {
    let startArea = startDate.format("MMM D YYYY");
    let endArea = endDate.format("MMM D YYYY");
    let labelArea = startArea + " - " + endArea;
    if (startArea === endArea) {
      setLabelArea(startArea);
    } else {
      setLabelArea(labelArea);
    }

    let labels = [];
    let amounts = [];
    let items = _.filter(cobrus, function (o) {
      return (
        o.date_payed >= startDate && o.date_payed <= endDate && o.state === 3
      );
    });

    items.forEach((element) => {
      amounts.push(parseInt(element.amount.replace(/\D/g, "")));
      labels.push(moment(element.date_created._i).format("YYYY-MM-DD"));
    });

    setSeries([
      {
        name: "RECAUDOS",
        data: amounts,
      },
    ]);
    setOptions({
      labels: labels,
    });
  };

  const updateDataChartPaymentMethod = (startDate, endDate, cobrus) => {
    let startPie = startDate.format("MMM D YYYY");
    let endPie = endDate.format("MMM D YYYY");
    let labelPie = startPie + " - " + endPie;
    if (startPie === endPie) {
      setLabelPie(startPie);
    } else {
      setLabelPie(labelPie);
    }

    const items = _.filter(cobrus, (o) => {
      return (
        o.date_payed >= startDate &&
        o.date_payed <= endDate /* && o.state === 3 */
      );
    });

    /*  let items = _.filter(cobrus, function(o){
             return (o.fields.date_payed >= startDate && o.fields.date_payed <= endDate && o.fields.state === 3)
         }); */

    let baloto = 0;
    let efecty = 0;
    let pse = 0;
    let tc = 0;
    let cobru = 0;
    let otros = 0;

    items.forEach((element) => {
      switch (element.payment_method) {
        case "baloto":
          baloto++;
          break;
        case "efecty":
          efecty++;
          break;
        case "credit_card":
          tc++;
          break;
        case "pse":
          pse++;
          break;
        case "cobru":
          cobru++;
          break;
        default:
          otros++;
      }
    });

    setSeriesPie([baloto, efecty, pse, tc, cobru, otros]);
  };
  const getState = async (state) => {
    try {
      const response = await AxioInstance.post(`/api/get_state/`, {
        headers: await getAuthenticatedHeaders(),
        state: state,
      });
      if (response.data.status === 200) {
        if (response.data.data.data[0].state === 0) {
          setAmount0(response.data.data.data[0].total_amount);
          setState0(response.data.data.data[0].total_count);
        } else if (response.data.data.data[0].state === 1) {
          setAmount1(response.data.data.data[0].total_amount);
          setState1(response.data.data.data[0].total_count);
        } else {
          setAmount3(response.data.data.data[0].total_amount);
          setState3(response.data.data.data[0].total_count);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBalanceAllUsers()
    getInitialBalances(startDate,endDate);

  }, [])
  
  function formatearMontoEnPesos(monto) {
    // Convertir el monto a un número y asegurarnos de que sea válido
    const montoNumerico = parseFloat(monto);
    if (isNaN(montoNumerico)) {
      return "Monto inválido";
    }

    // Redondear el monto a dos decimales
    const montoRedondeado = montoNumerico.toFixed(2);

    // Verificar si el monto es igual a 0.00
    if (montoRedondeado === "0.00") {
      return "0";
    }

    // Eliminar ceros a la derecha si existen
    const montoFormateado = montoRedondeado.replace(/\.?0+$/, "");

    // Agregar separadores de miles y el símbolo de peso colombiano
    const separadorMiles = ".";
    return (
      "$ " + montoFormateado.replace(/\B(?=(\d{3})+(?!\d))/g, separadorMiles)
    );
  }
  return canViewResumen ? (
    <>
      <div className="flex md:flex-row flex-wrap">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
          className="text-white font-bold py-1  px-4 rounded-full h-8 mr-1 mt-3 bg-gray-500"
        >
          <DateRangePicker
            initialSettings={{
              startDate: startDate,
              endDate: endDate,
              ranges: ranges,
              locale: localeDataPicker,
              maxDate: maxDate,
            }}
            onEvent={handleEvent}
          >
            <button>
              <div className="px-1 text-white float-left">
                <BsFillCalendarFill />
              </div>
              <span className="font-bold text-xs float-left">{labelFecha}</span>
              <div className="float-left text-white">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </button>
          </DateRangePicker>
        </div>
      </div>
      <div className="flex md:flex-row flex-wrap pl-2">

       {/* 
        <BalanceCard
          amount={recargasBalance}
          loading={loadingRecargas}
          label="Recargas"
          movCount={movement_countRecargas}
        /> */}
        <BalanceCard
          amount={usersBalance.total_balance_to_cop}
          loading={loadingUsersBalance}
          label="Total saldo asociados (COP)"
         
        />

        <BalanceCard
          amount={usersBalance.total_balance_to_usd}
          loading={loadingUsersBalance}
          label="Total saldo asociados (USD)"
        />

        <BalanceCard
          amount={cambiosBalance}
          loading={loadingCambios}
          label="Cambios (USD)"
          movCount={movement_countCambios}
        />

        <BalanceCard
          amount={enviosBalance}
          loading={loadingEnvios}
          label="Envios"
          movCount={movement_countEnvios}
        />

        <BalanceCard
          amount={retirosBalance}
          loading={loadingRetiros}
          label="Retiros"
          movCount={movement_countRetiros}
        />

       {/*  <BalanceCard
          amount={RetirosUSDBalance}
          loading={loadingRetirosUSD}
          label="Recargas (USD)"
          movCount={movement_countRecargasUsd}
        /> */}

        <BalanceCard
          amount={enviosUsdBalance}
          loading={loadingEnviosUsd}
          label="Envios (USD)"
          movCount={movement_countEnviosUsd}
        />
         <BalanceCard
          amount={retirosDepositadosBalance}
          loading={loadingRetirosDepositados}
          label="Retiros Depositados"
          movCount={movement_countRetirosDepositado}
        />
        <BalanceCard
          amount={singlePayBalance}
          loading={loadingSinglePay}
          label="Pagos Vinculación"
          movCount={movement_countSinglePay}
        /> 
      </div>
    </>
  ) : (
    <NoView />
  );
};
const BalanceCard = ({ loading = true, amount = 0, label = "" ,movCount=null}) => {
  return (
    <div className="w-full md:w-1/4 p-4 text-center pl-0 ">
      <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg" style={{height:'120px'}}>
        <div className="w-full px-1 py-2">
          <div className="text-gray-700 text-rigth">
            {/* <div className="text-xs text-rigth">
                    <DateRangePicker
                        initialSettings={{
                            startDate: startDate,
                            endDate: endDate,
                            ranges: ranges,
                            locale: localeDataPicker,
                            maxDate: maxDate
                        }}
                        onEvent={handleEventCobrusPaid}
                    >
                        <button>
                            <div className="px-1 text-gray-700 float-left">
                                <BsFillCalendarFill />
                            </div>
                            <span className="font-bold text-xs float-left">{labelCobrus}</span>
                            <div className="float-left text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </button>
                    </DateRangePicker>
                </div> 
                */}
          </div>
        </div>
        <div className="px-2 py-4">
          <div className="font-bold text-xl mb-2 text-black">
            {loading ? <CircularProgress size={28} /> : 
            <>
            {FormatAmount(amount,1)}
            <span className="text-base">{FormatAmount(amount,2)}</span>
            </>
            }
          </div>
          
            <p className="text-gray-700 text-sm">{ movCount!==null?movCount:''} {label}</p>
          
        </div>
      </div>
    </div>
  );
};
export default ResumenMutu;
