import React, { useState } from 'react'

export const ModalPassword = ({handleOK=()=>{},handleCancel=()=>{},error=false}) => {
    const [password, setPassword] = useState('')
    

  return (
   <>
   <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-2 mx-auto max-w-xs">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="relative p-6 flex-auto">

                                        <p className="my-4 text-gray-600 text-lg leading-relaxed">Ingresa tu contraseña</p>
                                        <input aria-label="Password" name="password" value={password} onChange={e => setPassword(e.target.value)} type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Contraseña"></input>
                                        {error &&
                                            <div className="relative flex justify-center py-3 px-4 mx-auto w-auto"><p className="text-red-500 text-xs italic">{error} </p></div>
                                        }
                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 rounded-full"
                                            type="button"
                                            style={{ transition: "all .15s ease" }}
                                            onClick={() => handleCancel()}
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            className="bg-green-600 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 rounded-full"
                                            type="button"
                                            style={{ transition: "all .15s ease" }}
                                            onClick={()=>handleOK(password)}
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" fixed inset-0 z-40 " style={{
                            background: 'rgba(47, 46, 44, 0.5)',
                            backdropFilter: 'blur(10px)',
                        }}>
                        </div>
   </>
  )
}
