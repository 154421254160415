import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { /* getAuthenticatedHeaders, */ FormatAmount, FormatPhoneNumber, FormatDocument } from '../common/functions';
import moment from 'moment';
import Banner from '../../components/Banner';
import { AuthContext } from "../../context/AuthContext";
import _ from 'lodash';
import axios from 'axios';
import AxioInstance from '../../axios/AxiosConfig';




const Alertas = (props) => {
    const [tokenRefresh, setTokenRefresh] = useState('');
    const [totalRows, setTotalRows] = useState(0)
    const [token, setToken] = useState('')
    const [page, setPage] = useState(1)

    const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
    const [pagination, setPagination] = useState(true);
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);
    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const [error, setError] = useState(null)
    const [Accion, setAccion] = useState(null)
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState(null)
    const [pending, setPending] = useState(true);

    useEffect(() => {
        getUserListBanks()
    }, [token, page])
    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const getUserListBanks = async () => {
        setLoading(true);
        setPending(true)
        try {
            const response = await AxioInstance.post(`hunter/logs/`, {
                headers: await {
                    Authorization: `Bearer ${token}`,
                },
                page: page
            });


            if (response.data.status === 200) {
                let data = response.data.data.results;
                setData(data);
                setTotalRows(response.data.data.count)
                setLoading(false);
                setPending(false);
            } else if (response.data.status === 401) {
                TokenRefresh()
            }
            else {
                setData([]);
                setLoading(false);
                setPending(false);
            }
        } catch (error) {

        }

    };
    const handlePageChange = page => {
        setPage(page);
    };
    const TokenRefresh = async () => {

        try {
            const response = await axios.post(`hunter/refresh/`, { refresh: tokenRefresh });


            if (response.data.status === 200) {
                setToken(response.data.data.access)
                getUserListBanks()
            }
            else {
                NewToken()
            }
        } catch (error) {

        }

    };
    const NewToken = async () => {

        try {
            const response = await axios.post(`hunter/token/`,);


            if (response.data.status === 200) {
                setToken(response.data.data.access)
                setTokenRefresh(response.data.data.refresh)

            }
            else {
                setData([]);
                setPending(false);
            }
        } catch (error) {

        }

    };

    const desactivate = async (accion, pk) => {
        setId(pk)
        setLoading(true)
        setAccion(accion)
        const data = {
            id: pk,
            activate: accion ? 1 : 0
        }
        try {
            const response = await axios.post(`/api/desactivate_user/`, {
                headers: await getAuthenticatedHeaders(), data
            });



            if (response.data.status === 200) {

                setError(false)
                setShow(true)
                getUserListBanks()

            } else {
                setShow(true)
                setError(true)
            }
        } catch (error) {

        }
        setId(null)
        setLoading(false)
    };

    const columns = [{
        name: 'REF',
        cell: row => (
            <div >

                {row.id}

            </div>)
        ,
        sortable: true,
    },
    {
        name: 'Nombre',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{row.full_name}</div>
        )
    },
    {
        name: 'username',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{row.username[0] !== "9" ? FormatPhoneNumber(row.username) : FormatDocument(row.username)}</div>
        )
    },

    {

        name: 'Monto',

        cell: row => (<div className="justify-center items-start flex" >
            <span>{FormatAmount(row.amount)}</span>
        </div>
        )
    },
    {

        name: 'IP',

        cell: row => (<div className="justify-center items-start flex" >
            <span>{row.ip}</span>
        </div>
        )
    },
    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */
    {
        name: 'Descripción',
        cell: row => (
            <div>{row.rule?.description}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },
    {
        name: 'Fecha',
        cell: row => (
            <div>{moment(row.create_at).format('YYYY-MM-DD HH:mm:ss A')}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },
        /* {
            name: 'Acciones',
            cell: row => (
                <div>
                    {loading && id === row.id && <div className="mx-4">
    
                        <CircularProgress size={26} />
    
                    </div>
                    }
                    {id !== row.id &&
                        <div>
                            {row.is_active === true &&
                                <button className="button-outline-off my-1 mx-1  rounded-full w-20 h-8 text-red-600 hover:text-white hover:bg-red-600 button-outline-off" onClick={() => desactivate(false, row.id)} >Desactivar</button>
                            }
                            {row.is_active === false &&
                                <button className="button-outline-off  my-1  rounded-full w-20 h-8 text-green-600 hover:text-white hover:bg-green-600 button-outline-off" onClick={() => desactivate(true, row.id)} >Activar</button>
                            }
                        </div>}
                </div>
    
            ),
            //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
            sortable: true,
        }, */




    ];

    const customStyles = {
        rows: {
            style: {
                minWidth: "auto",
                padding: "10px 0",
                minHeight: "auto"
            }
        },

        cells: {
            style: {
                minWidth: '225px !important', // override the cell padding for data cells
            },
        },
    };




    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                loading &&
                <CircularProgress size={75} />
            }
        </div>
    );


    return (
        <>


            <div className="flex md:flex-row flex-wrap p-2">

                {show && <div className="w-full my-3">
                    <Banner
                        title={'Proceso realizado'}
                        description={`el usuario fue ${Accion ? "Activado" : "Desactivado"}`}
                        setShowBanner={setShow}
                        error={error}
                    />
                </div>}

                <div className="w-full">

                    <DataTable

                        noHeader={true}
                        data={data}
                        columns={columns}

                        selectableRowsHighlight={true}
                        fixedHeader={true}
                        pagination={pagination}
                        noDataComponent={"Fuera de Servicio"}
                        paginationComponentOptions={paginationOptions}
                        progressPending={pending}
                        paginationServer
                        customStyles={customStyles}
                        paginationTotalRows={totalRows}
                        progressComponent={<Circular />}
                        onChangePage={handlePageChange}

                    />
                </div>
            </div>


        </>
    )
}

export default Alertas
