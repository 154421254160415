import React, { useState, useContext, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { FormatAmount, FormatDocument, formatoDinero, localeDataPicker } from '../common/functions';
import axios from 'axios';
import Banner from '../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
import Dropzone from 'react-dropzone';
import { /* token, */ isEmpty } from '../common/functions';

import AxioInstance from '../../axios/AxiosConfig';
import { CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { FunctionContext } from '../../context/FuntionsContext';
import ModalDescripcion from '../layouts/ModalDescripcion';
import ModalDescripcionEfecty from '../layouts/ModalDescripcionEfecty';
import variablesDomain from '../../helpers/variablesDomain';

//import { getAuthenticatedHeaders} from 'common/functions';
/* STATE_CREATED = 0
    STATE_IN_PROCESS = 1
    STATE_DEPOSITED = 2
    STATE_CONSIGNED = 3

    STATE_ERROR = 4
    STATE_CANCELLED = 5
    STATE_EXPIRED = 6 */

const filterState = { all: null, created: 0, cancelled: 5, deposited: 2 }
const RetirosEfecty = (props) => {
    const DEV = localStorage.getItem('DEV');


    const [msg, setMsg] = useState("")
    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);

    const [pending, setPending] = useState(true);

    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));


    const [label, setLabel] = useState("");


    const { getAuthenticatedHeaders, permissions, getAccessToken } = useContext(AuthContext)
    const { ReloadSwaps, buscar, buscarRetirosEfecty, opcionRetirosEfecty } = useContext(FunctionContext)
    //const canViewReport = props.canViewReport;
    const canViewReport = permissions.permission_reports;

    const [page, setPage] = useState(1)
    const [newPerPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [pagination, setPagination] = useState(true);
    const [listMovementsFilter, setListMovementsFilter] = useState([]);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [numFilas, setNumFilas] = useState([]);

    const [activeFilter, setActiveFilter] = useState(filterState.all)

    const [idCopiado, setIdCopiado] = useState()
    const [dataModal, setDataModal] = useState([]);
    const [openModal, setOpenModal] = useState(false)

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);





    const getListMovs = async (numPage, paramsReq) => {
        setPending(true);

        try {
            const params = {
                headers: await getAuthenticatedHeaders(),
                pageNum: numPage,
                perPage: newPerPage,
                state: activeFilter,
                ...paramsReq
            }
            const response = await AxioInstance.post(`/api/withdraw_cash`, params);


            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)
                const pendings = response.data.data

                let final = !pendings ? [] : pendings
                setListMovementsFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPending(true);
        try {
            const response = await AxioInstance.post(`/api/withdraw_cash`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: newPerPage,
                state: activeFilter
            });
            if (response.data.status === 200) {
                const pendings = response.data.data
                let final = !pendings ? [] : pendings
                setPerPage(newPerPage)
                setListMovementsFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }
    const ActivarModal = (data) => {
        setDataModal(data)
        setOpenModal(true)
    }
    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                pending &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );

    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'bg-green-500'
        } else if (state === 3) {
            color = 'orange'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        } else if (state === 7) {
            color = 'Color-red'
        }
        return color
    }
    const getDate = (data) => {
        let date = data.date_created
        if (data.state === 0) {
            date = data.date_created
        } else if (data.state === 1) {
            date = data.date_created
        } else if (data.state === 2) {
            date = data.date_deposited
        } else if (data.state === 3) {
            date = data.date_created
        } else if (data.state === 4) {
            date = data.date_created
        } else if (data.state === 5) {
            date = data.date_cancelled
        } else if (data.state === 6) {
            date = data.date_expiration
        }
        return (date)
    }


    const changeTextState = (state) => {
        let text = ''


        if (state.state === 0) {
            text = 'Pendiente'
        } else if (state.state === 1) {
            text = 'En proceso'
        } else if (state.state === 2) {
            text = `Depositado`
        } else if (state.state === 3) {
            text = `Consignado`
        } else if (state.state === 4) {
            text = 'Hubo un problema'
        } else if (state.state === 5) {
            text = 'Cancelado'
        } else if (state.state === 6) {
            text = 'EXPIRADO'

        }
        return text
    }
    const columns = [{
        name: 'REF',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                <div
                    title={row.state === 0 || row.state === 1
                        ? "Pendiente"
                        : row.state === 2
                            ? "Depositado"
                            : row.state === 3
                                ? "Consignado"
                                : row.state === 4 || row.state === 5 || row.state === 6
                                    ? "Cancelado"
                                    : row.state === 7
                                        ? "Reversado"
                                        : ""
                    }
                    className={changeBgColor(row.state) + ' rounded-full h-4 w-4 bg-green mr-3'}
                >

                </div>
                <div className='links cursor-pointer' onClick={() => { ActivarModal(row) }}
                    style={{ fontWeight: 700 }}>
                    {row.pk}
                </div>
            </div>)
        ,
        sortable: true,
    },
    {
        name: 'USUARIO',
        //selector: 'state',
        cell: row => (
            <div> {
                row.username
            }</div>
        ),
        sortable: true,
    },
    {
        name: 'DOCUMENTO',
        //selector: 'state',
        cell: row => (
            <div> {
                FormatDocument(row?.document)
            }</div>
        ),
        sortable: true,
    },
    {
        // name: 'NOMBRE',
        // //selector: 'name',
        // cell: row => (
        //     /* row.payer_name ?  row.payer_name : */ row.account_holder_name ? row.account_holder_name  : ''
        // ),
        // sortable: true,

        name: 'NOMBRE',
        selector: 'name',
        cell: row => (<div >
            <p className="lower">{row.name ? row.name + " -" : ""}</p>
            <div className="flex flex-row">

                {/*  {(row.owner_state === 3 || row.owner_state === 4) && <img className='mr-1' src={approve} width={10} height={10} />}
                {row.owner_state < 3 && <img className='mr-1' src={cancel} width={10} height={10} />} */}
                <a style={{ color: '#007bff' }}
                    target='_blank' href={`${DEV}admin/api/profile/${row.owner_id}/change/`}>
                    {row?.owner_first_name}
                </a>
            </div>

        </div>

        )
    },
    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */
    {
        name: 'A DISPERSAR',
        cell: row => <div >
            <button className="button-outline-off copy-cursor" onClick={() => { }}>
                <span>
                    {` ${FormatAmount(row.payed_amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(row.payed_amount, 2)}`}</span>
                </span>

            </button>


        </div>,
        sortable: true,
    },
    {
        name: 'FECHA',
        cell: row => (
            <div>{/* row.state === 0 ? moment(getDate(row) -18000000).format('YYYY-MM-DD HH:mm:ss A'): */ moment(getDate(row)).format('DD/MM/YYYY HH:mm A')}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },

    {
        name: 'ESTADO',
        //selector: 'state',
        cell: row => (
            <div> {
                row.state !== undefined ?
                    `${changeTextState(row)}` : ''
            }</div>
        ),
        sortable: true,
    },

        /* {
                    name: 'TYPE',
          //selector: 'state_bank',
          cell: row => (
                row.type !== undefined ?
                row.type : ''
                ),
                sortable: true,
        }, */
    ];
    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    const getSearch = async () => {
        if (buscarRetirosEfecty?.length !== 0) {

            setPending(true);


            let paramsReq = {}
            //<option value={2}>Documento</option>
            //<option value={3}>Usuario</option>

            if (parseInt(opcionRetirosEfecty) === 1) {
                paramsReq.withdraw_id = buscarRetirosEfecty
            }
            if (parseInt(opcionRetirosEfecty) === 2) {
                paramsReq.document_number = buscarRetirosEfecty?.replace(/\D/g, "")
            }
            if (parseInt(opcionRetirosEfecty) === 3) {
                paramsReq.username = buscarRetirosEfecty
            }
            if (parseInt(opcionRetirosEfecty) === 4) {
                paramsReq.idempotency_key = buscarRetirosEfecty
            }
            setToggledClearRows(!toggledClearRows)
            getListMovs(1, paramsReq)

        } else {
            setToggledClearRows(!toggledClearRows)
            getListMovs(1)
        }
    }

    useEffect(() => {
        getSearch()
    }, [buscar]);

    useEffect(() => {
        if (buscarRetirosEfecty?.length !== 0) {
            getSearch()
        } else {

            setToggledClearRows(!toggledClearRows)
            getListMovs(1);
        }

    }, [ReloadSwaps, activeFilter])

    useEffect(() => {
        lasfilas()
    }, [totalRows]);


    return canViewReport ? (
        <>
            {openModal === true &&
                <ModalDescripcionEfecty modal={() => setOpenModal()} open={openModal} data={dataModal} />
            }
            {showBanner && <Banner
                title={'Proceso realizado'}
                description={msg}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}

            <div className='flex flex-row text-xs p-1 mb-5 overflow-auto'>
                <button className={`focus:outline-none hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ` + (activeFilter === filterState.all ? `bg-${variablesDomain.classNameColor}` : 'bg-gray-500')} onClick={() => { setActiveFilter(filterState.all) }} key={filterState.all} >
                    <span>Todos</span>
                </button>
                <button className={`focus:outline-none hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ` + (activeFilter === filterState.created ? `bg-${variablesDomain.classNameColor}` : 'bg-gray-500')} onClick={() => { setActiveFilter(filterState.created) }} key={filterState.created}>
                    <span>Creados</span>
                </button>
                <button className={`focus:outline-none hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ` + (activeFilter === filterState.deposited ? `bg-${variablesDomain.classNameColor}` : 'bg-gray-500')} onClick={() => { setActiveFilter(filterState.deposited) }} key={filterState.deposited} >
                    <span>Depositados</span>
                </button>
                <button className={`focus:outline-none hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ` + (activeFilter === filterState.cancelled ? `bg-${variablesDomain.classNameColor}` : 'bg-gray-500')} onClick={() => { setActiveFilter(filterState.cancelled) }} key={filterState.cancelled} >
                    <span>Cancelados</span>
                </button>

            </div>
            <DataTable
                title={``}
                noHeader={true}
                clearSelectedRows={toggledClearRows}
                paginationResetDefaultPage={toggledClearRows}
                data={listMovementsFilter}

                columns={columns}


                selectableRowsHighlight={true}
                fixedHeader={true}
                noDataComponent={"No hay movimientos disponibles"}

                pagination={true}
                paginationComponentOptions={paginationOptions}

                progressPending={pending}
                progressComponent={<Circular />}

                paginationTotalRows={totalRows}
                paginationPerPage={newPerPage}
                paginationServer
                paginationRowsPerPageOptions={numFilas}
                onChangePage={getListMovs}
                onChangeRowsPerPage={handlePerRowsChange}

            />
        </>
    ) : (
        <NoView />
    )

}

export default RetirosEfecty;

