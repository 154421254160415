import moment from 'moment';
import Numeral from "numeral";
import axios from 'axios';
import AxioInstance from '../../axios/AxiosConfig';

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    const response = await AxioInstance.post(`/api/token/refresh`, { refreshToken })



    localStorage.setItem('token', response.data.access)
    localStorage.setItem('expiresAtC', new Date().toString())
  } catch (error) {

  }
};
export const CalcularDv = (nit) => {
  if (nit.length < 10) {



    var arreglo,
      x,
      y,
      z,
      i,
      nit1,
      dv1;
    nit1 = nit.toString().replace(/\D/g, "")
    if (isNaN(nit1)) {


    } else {
      arreglo = new Array(16);
      x = 0;
      y = 0;
      z = nit1.length;
      arreglo[1] = 3;
      arreglo[2] = 7;
      arreglo[3] = 13;
      arreglo[4] = 17;
      arreglo[5] = 19;
      arreglo[6] = 23;
      arreglo[7] = 29;
      arreglo[8] = 37;
      arreglo[9] = 41;
      arreglo[10] = 43;
      arreglo[11] = 47;
      arreglo[12] = 53;
      arreglo[13] = 59;
      arreglo[14] = 67;
      arreglo[15] = 71;
      for (i = 0; i < z; i++) {
        y = (nit1.substr(i, 1));
        x += (y * arreglo[z - i]);

      }
      y = x % 11
      if (y > 1) {
        dv1 = 11 - y;
      } else {
        dv1 = y;
      }

      return `${nit}-${dv1}`;
    }
  } else {
    return nit.slice(0, 9) + "-" + nit.slice(9, 10)

  }
}
export const getAuthenticatedHeaders = async () => {
  return {
    Authorization: await getAccessToken() ? 'Bearer ' + (await getAccessToken()) : null,
  };
};

const DifferenceMinutes = (dt2, dt1) => {

  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export const getAccessToken = async () => {
  let currentTime = new Date();
  let token_created = localStorage.getItem('expiresAtC');

  let minutes_since = DifferenceMinutes(currentTime, new Date(Date.parse(token_created)));

  if (minutes_since > 14) {
    return await refreshAccessToken();
  } else {
    return await localStorage.getItem('token');
  }

};

{/*Nueva funcion desde aca */ }
// const DifferenceMinutes = (dt2, dt1) => {
//   let diff = (dt2.getTime() - dt1.getTime()) / 1000;
//     diff /= 60;
//   return Math.abs(Math.round(diff));
// }

// export const getAccessToken = async () => {
// 	let currentTime = new Date();
// 	let token_created = await localStorage.getItem('token');
//   let minutes_since = DifferenceMinutes(currentTime, new Date(Date.parse(token_created)));

// 	if (minutes_since > 14) {
// 		return await refreshAccessToken();
// 	} else {
// 		return await localStorage.getItem('token');
// 	}
// };

// export const refreshAccessToken = async () => {
//   let refreshToken = localStorage.getItem('refreshToken');
//   await axios.post(`/api/token/refresh`, {refreshToken})
//     .then(response =>  {
//       setStorigTime("token", response.data.access, 70000);

// };
{/*Nueva funcion hasta aca */ }

export const localeDataPicker = {
  format: 'YYYY-MM-DD',
  separator: ' - ',
  applyLabel: 'Aplicar',
  cancelLabel: 'Limpiar',
  weekLabel: 'W',
  customRangeLabel: 'Seleccionar Rango',
  daysOfWeek: [
    "Do",
    "Lu",
    "Ma",
    "Mi",
    "Ju",
    "Vi",
    "Sa"
  ],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ],
  firstDay: moment.localeData().firstDayOfWeek(),
};

export const FormatDocument = (doc) => {
  return doc?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export const FormatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "";
  var cleaned = (phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!match) ? phoneNumberString : "(" + match[1] + ") " + match[2] + "-" + match[3];
}



export function formatoDinero(amount) {
  // Formatea el número como un número decimal con separador de miles y dos decimales en pesos colombianos (COP)
  if (!amount) return "$ 0"
  let digits = amount.toString().replaceAll(/\s/g, '');

  return "$ " + Numeral(parseInt(digits)).format('0,0').replace(/,/g, '.');
}
export const FormatAmount = (amount, id) => {
  if (!amount&&id===1) return "$ 0"
  if (!amount&&id===2) return ""

  const numeroFormateado = parseFloat(amount).toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP'
  });
  const numeroParts = numeroFormateado.split(',');
  const parteEntera = numeroParts[0];
  const parteDecimal = `.${numeroParts[1]}` || '.00';
  if (id == 1) {
    return parteEntera
  } else if (id == 2) {
    return parteDecimal
  } else {
    return numeroFormateado;
  }
  // Devuelve el número formateado

}

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const formatCurrency = (locales, currency, fractionDigits, number) => {
  let formatted = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits
  }).format(number);
  return formatted;
}

function getValueToken() {
  const itemToken = localStorage.getItem('token');
  if (itemToken) {
    //const token = JSON.parse(itemToken);
    return itemToken;
  }
}

export const getUserLogin = () => {
  let token = localStorage.getItem('token');
  if (token) {

    let tokenExpiry = localStorage.getItem('expiresAtC');
    let now = new Date().getTime();
    let nowFormatt = moment(parseInt(now)).format('L');
    let formatt = moment(parseInt(new Date(Date.parse(tokenExpiry)).getTime())).format('L')



    let diffTime = Math.abs(new Date(formatt) - new Date(nowFormatt));
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays >= 1) {
      localStorage.clear();
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }

};
export function FormatPhone(number) {
  if (number != undefined) {


    let string = number.toString();
    let phoneFormat = string.replace(/^(\d{3})(\d{3})(\d{}).*/, "($1) $2-$3"); //quitamos los caracteres que no sean digitos

    phoneFormat = phoneFormat.substring(0, 18); // el maximo de digitos para el numero de telefono es 12
    phoneFormat = phoneFormat.replace(
      /^(\d{3})/g,
      "(" + phoneFormat.substring(0, 3) + ")"
    ); //agregamos los parentesis.
    phoneFormat = phoneFormat.replace(
      /\)(\d{3})/g,
      ") " + phoneFormat.substring(5, 8) + "-"
    ); //agregamos un espacio y el primer guion

    return phoneFormat;
  }
}

export const setStorigTime = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  localStorage.setItem(key, JSON.stringify(item));
}
export function TitleCase(texto) {
  const re =
    /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
  return texto.replace(
    re,
    (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
      const locale = ["es", "gl", "ca", "pt", "en"];
      if (mayuscIntermedias) return mayuscIntermedias.toLocaleLowerCase(locale);
      return (
        caracterPrevio +
        (minuscInicial
          ? minuscInicial.toLocaleUpperCase(locale)
          : mayuscInicial)
      );
    }
  );
}

export function maskCardNumber(cardNumber) {
  // Verificar que el cardNumber sea un string y tenga al menos 8 dígitos
  if (typeof cardNumber !== 'string' || cardNumber.length < 8) {
      throw new Error('El número de tarjeta debe ser un string con al menos 8 dígitos');
  }

  // Obtener los primeros 4 dígitos
  const firstFour = cardNumber.slice(0, 4);
  // Obtener los últimos 4 dígitos
  const lastFour = cardNumber.slice(-4);
  // Calcular la cantidad de asteriscos necesarios
  const numAsterisks =  8;
  // Generar una cadena de asteriscos con la longitud adecuada
  const asterisks = '*'.repeat(numAsterisks);

  // Concatenar los primeros 4 dígitos, los asteriscos y los últimos 4 dígitos
  return `${firstFour}${asterisks}${lastFour}`;
}

export const token = getValueToken();
