import React, { useEffect, useState, useContext } from 'react';


import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalCumplimiento from '../../../containers/layouts/ModalCumplimiento'
import Banner from '../../../components/Banner';
import { FormatDocument/* getAuthenticatedHeaders */ } from '../../common/functions';

import { AuthContext } from '../../../context/AuthContext';
import { FunctionContext } from '../../../context/FuntionsContext';
import AxioInstance from '../../../axios/AxiosConfig';
import variablesDomain from '../../../helpers/variablesDomain.js';



const CumplimientoPagaHoy = (props) => {


    const [showBanner, setShowBanner] = useState(false);


    const [bannerError, setBannerError] = useState(false);
    const [bannerContent, setBannerContent] = useState({
        title: '',
        description: '',
    });

    const [isLoading, setIsLoading] = useState(true);

    const pendingTasks = props.task ? props.task : 0;
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const [msn, setMsn] = useState([]);
    const { getAuthenticatedHeaders, getAccessToken } = useContext(AuthContext);
    const { buscarEnvios, buscar, EnviarLayout, opcionCumplimiento } = useContext(FunctionContext);
    //Pagination
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [reloadData, setReloadData] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [Modal, setModal] = useState(false);

    /*     useEffect(() => {
            getBalance()
            fetchCobrus(1);
        }, [reloadData]) */

    useEffect(() => {
        if (buscar && document !== '' && buscarEnvios !== '') {
            fetchCobrus()
        }
    }, [buscar])

    const OpenModal = (e) => {
        setDataModal(e)
        setModal(true)
    }


    /* 
        useEffect(() => {
            fetchCobrus(1);
        }, [isLoading]) */


    //data table columns
    const columns = [

        {
            name: 'CLIENTE',
            grow: 8,
            cell: row => (
                <div className='links cursor-pointer' onClick={() => OpenModal(row)}>
                    {(row.Nombredelcliente)}
                </div>
            )
        },

        {
            name: 'DOCUMENTO',
            grow: 4,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {`${row.TipoDocIdentidad == "Cédula de ciudadanía" ? "CC" : row.TipoDocIdentidad} ${FormatDocument(row.Documento)}`}</span>
                </div>
            )
        },
        {
            name: 'SCORE',
            grow: 1,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {row.ScoringSarlaft}</span>
                </div>
            )
        },
        {
            name: 'CIUDAD',

            grow: 2,
            sortable: true,
            selector: 'date',
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className="text-gray-700 py-3  flex items-center"> {row.Ciudad} </span>
                </div>
            )
        },

        /* {
            name: 'MENSAJE',
            grow: 4,
            wrap: true,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className='ml-3'> {row.description || ''}</span>
                </div>
            )
        }, */

    ];

    const customStyles = {
        header: {
            style: {
                color: '#000',
                minHeight: '45px'

            },
        },
        headRow: {
            style: {
                whiteSpace: 'nowrap',
                fontSize: '0.75rem',
                textAlign: 'left',

            },
        },
        headCells: {
            style: {
                paddingLeft: '3rem',
                paddingRight: '3rem'
            },
        },
        cells: {
            style: {
                borderStyle: 'dashed',
                borderTopWidth: '1px',
                whiteSpace: 'nowrap',
                paddingLeft: '3rem',
                paddingRight: '3rem'
            },
        },
    };



    //server side pagination
    const fetchCobrus = async page => {

        setLoading(true);

        var document = buscarEnvios
        var typeDocument = document === "" ? "" : opcionCumplimiento


        const response = await AxioInstance.post(`/api/reporte_isolution/`, {
            headers: await getAuthenticatedHeaders(),
            pageNum: page ? page : 1,
            perPage: 1,
            document: document,
            document_type: typeDocument
        });




        if (response.data.status === 200) {

            let final = JSON.parse(response.data.data.data.Entity)

            if (final.length == undefined) {
                setResponseData([final]);
            } else {
                setResponseData(final);
            }
            setLoading(false);

        } else if (response.data.status === 500) {
            let banner = {
                title: 'Ocurrio un error',
                description: "Ha ocurrido un error, intentalo nuevamente",
            }
            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(true)


        }
        else {
            let banner = {
                title: 'Ocurrio un error',
                description: response.data.data.message,
            }
            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(true)
        }
        setLoading(false);

    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await AxioInstance.post(`/api/sends`, {
            headers: await getAuthenticatedHeaders(),
            pageNum: page,
            perPage: 1
        });

        if (response.data.status === 200) {
            //setResponseData(final);

            let final = JSON.parse(response.data.data.data.Entity)
            setResponseData(final);
            setPerPage(newPerPage);
            setLoading(false);

        } else {
            setMsn("Ha ocurrido un error, intentalo nuevamente");
            setIsLoading(true);
        }

    };

    const handlePageChange = page => {
        fetchCobrus(page);
    };



    return (
        <>
            <div className='flex flex-row w-full justify-around'>


            </div>
            {showBanner && bannerContent && (
                <div className="w-65">
                    <Banner title={bannerContent.title} error={bannerError} description={bannerContent.description} setShowBanner={setShowBanner} />
                </div>
            )

            }
            {Modal && < ModalCumplimiento data={dataModal} setModal={setModal} />}
            <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs">
                <button className={` button-outline-off hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` + (isActiveFilter ? `bg-${variablesDomain.classNameColor} ` : 'bg-gray-500')} key="0" value="0">
                    <span>Todos</span>
                </button>

            </div>

            {
                <div style={{ marginTop: '20px' }}>
                    <DataTable
                        data={responseData}
                        columns={columns}
                        customStyles={customStyles}
                        noHeader={true}
                        fixedHeader={true}
                        highlightOnHover={true}
                        pagination={pagination}
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        noDataComponent={"No hay movimientos disponibles"}

                        paginationComponentOptions={
                            {
                                noRowsPerPage: false,
                                rowsPerPageText: 'Filas por página',
                                rangeSeparatorText: 'de'
                            }
                        }

                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressPending={loading}
                        progressComponent={
                            <div className="flex md:flex-row flex-wrap p-4">
                                <div className="w-full">
                                    <div style={{ height: '50vh' }}>
                                        <div className='h-full w-full flex items-center justify-center'>
                                            <CircularProgress size={75} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    />
                </div>
            }
            {/* {
                loadingTable == true &&
                <div className="flex md:flex-row flex-wrap p-4">
                    <div className="w-full">
                        <div style={{ height: '50vh' }}>
                            <div className='h-full w-full flex items-center justify-center'>
                                <CircularProgress size={75} />
                            </div>
                        </div>
                    </div>
                </div>
            } */}

        </>
    )
}

export default CumplimientoPagaHoy;