import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import { /* getAuthenticatedHeaders, */  formatCurrency } from '../common/functions';
import moment from 'moment';
export default function ModalAntecedentes(props) {

    return (
        <>
            {(
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">


                        <div className="relative w-pop-up my-6 mx-auto max-w-md">

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => props.modal(false)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">
                                    <p className="text-2xl text-center mb-2">Antecedentes</p>
                                    <p>El ciudadano {props.DataDocumentos[0].first_name} {props.DataDocumentos[0].last_name} con documento{props.Data.cc} {props.Data.cc} {props.Data.status === "no_records" ? props.Data.message : "Presenta antecedentes"}</p>
                                    {!!props.Data.records && props.Data.records.Delitos != "" &&
                                        props.Data.records.Delitos.map((item, i) => {
                                            return (
                                                <div >
                                                    <div className="">
                                                        <div md={12}>
                                                            <p style={{ fontSize: '18px', opacity: '0.7' }}>
                                                                Descripción del Delito
                                                            </p>
                                                            <p style={{ fontSize: '16px', }}>
                                                                <b>
                                                                    {(
                                                                        item["Descripción del Delito"].toLowerCase()
                                                                    )}
                                                                </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {!!props.Data.records && props.Data.records.INHABILIDADES != "" &&
                                        props.Data.records.INHABILIDADES.map((item, i) => {
                                            return (
                                                <div >
                                                    <div className="">
                                                        <div >
                                                            <p style={{ fontSize: '18px', opacity: '0.7' }}>
                                                                Inhabilidad legal:
                                                            </p>
                                                            <p style={{ fontSize: '16px', }}>
                                                                <b>
                                                                    {(
                                                                        item["Inhabilidad legal"].toLowerCase()
                                                                    )}
                                                                </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {!!props.Data.records && props.Data.records.Instancias != "" &&
                                        props.Data.records.Instancias.map((item, i) => {
                                            return (
                                                <div style={{ marginBottom: 8 }}>
                                                    <div >
                                                        <div >
                                                            <p style={{ fontSize: '18px', opacity: '0.7' }}>
                                                                Fecha providencia:
                                                            </p>
                                                            <p style={{ fontSize: '16px', }}>
                                                                <b>
                                                                    {(item["Fecha providencia"]).toLowerCase()}
                                                                </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {!!props.Data.records && props.Data.records.Sanciones != "" &&
                                        props.Data.records.Sanciones.map((item, i) => {
                                            return (
                                                <div >
                                                    <div >
                                                        <div >
                                                            <p style={{ fontSize: '18px', opacity: '0.7' }}>
                                                                Sanción:
                                                            </p>
                                                            <p style={{ fontSize: '16px', }}>
                                                                <b>
                                                                    {(item["Sanción"]).toLowerCase()}
                                                                </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            )}
        </>
    );
}
