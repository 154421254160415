import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { /* getAuthenticatedHeaders, */ FormatAmount, FormatPhoneNumber, FormatDocument } from '../common/functions';
import moment from 'moment';
import Banner from '../../components/Banner';
import { AuthContext } from "../../context/AuthContext";
import _ from 'lodash';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import AxioInstance from '../../axios/AxiosConfig';
import File from '../../assets/files/plantilla-Asociados.csv';

import { /* token, */ isEmpty } from '../common/functions';
import variablesDomain from '../../helpers/variablesDomain';


const CargarUsuarios = (props) => {
    const [show, setShow] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [lote, setLote] = useState(true)
    const [bannerError, setBannerError] = useState(false)
    const [BannerContent, setBannerContent] = useState("")
    const { getAuthenticatedHeaders, getAccessToken, permissions } = useContext(AuthContext)
    const {brand=null}= props
    const onDrop = async (acceptedFiles) => {
        setShow(false)
        if (!isEmpty(acceptedFiles)) {

            const DEV = localStorage.getItem('DEV');

            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            if (brand!=null) {
             formData.append('brand', brand) 
            
        }
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
                'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

            };
            
            axios.post((DEV) + 'profile/batch_create_profiles/', formData, { headers }, {
            }).then(res => {
                console.log("🚀 ~ file: CargarUsuarios.js:47 ~ axios.post ~ res:", res)
                if (res.data.code_transaction === "OK") {

                    setBannerContent('Se subio el archivo con exito')
                    setShow(true)
                    setBannerError(false)

                } else {

                    setBannerContent('No se pudo realizar la operacion')
                    setShow(true)
                    setBannerError(true)


                }

            }).catch((e) => {
            console.log("🚀 ~ file: CargarUsuarios.js:64 ~ axios.post ~ e:", e)
                
                setBannerContent('No se pudo realizar la operacion')
                setShow(true)
                setBannerError(true)


            })
        }


    }



    return (
        <>


            <div className="flex md:flex-row flex-wrap p-2">

                {show && <div className="w-full my-3">
                    <Banner
                        title={'Proceso realizado'}
                        description={BannerContent}
                        setShowBanner={setShow}
                        error={bannerError}
                    />
                </div>}

                <div className="w-full ">
                    {lote &&
                        <div className="flex w-full justify-between mb-4" style={{ background: "#f6f5f6" }}>
                        <div className="flex md:flex-row  mr-4 bg-white p-4 w-full items-center justify-center">
                            <div className="font-bold text-xl mb-2 text-black  text-center">
                                <p>Descargar aquí plantilla</p>
                                <a href={File}>
                                    <button className={`mt-2 bg-${variablesDomain.classNameColor} hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm`} onClick={() => {/* callApiReport() */ }}>{(false) ? 'Generando...' : 'Descargar'}</button>
                                </a>
                            </div>
                        </div>
                        <div className="flex md:flex-row w-full   bg-white p-4 justify-center items-center">
                            <div className="mt- border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 w-10/12 justify-center items-center flex  cursor-pointer h-full"
                                style={{ height: "200px" }}
                            >
 <Dropzone
                                    onDrop={onDrop}
                                    className="w-10/12"
                            accept={[".csv", ".xlsx"]}
                                    

                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                        <div {...getRootProps()} >
                                            {!isDragActive && <p> ¡Haga clic aquí o arrastre un archivo csv para cargarlo!!</p>}
                                            <input {...getInputProps()} />
                                            {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                            <ul className="list-group mt-2">
                                                {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                    <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                        {acceptedFile.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    )}
                                </Dropzone>
                                {isLoading &&
                                    <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </div>
        
                    </div>
/*                         <div className="text-center py-4 justify-center items-center flex flex-col px-2 bg-white">


                            <div className="mt- border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 w-10/12 justify-center items-center flex  cursor-pointer h-full"
                                style={{ height: "200px" }}
                            >
                                <Dropzone
                                    onDrop={onDrop}
                                    className="w-10/12"
                                    accept=".csv"
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                        <div {...getRootProps()} >
                                            {!isDragActive && <p> ¡Haga clic aquí o arrastre un  <a className='font-body text-lg links text-center py-2 mt-2 cursor-pointer' href={File} target='blank'>archivo csv</a> para cargarlo!!</p>}
                                            <input {...getInputProps()} />
                                            {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                            <ul className="list-group mt-2">
                                                {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                    <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                        {acceptedFile.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    )}
                                </Dropzone>
                                {isLoading &&
                                    <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>


                        </div> */
                        
                        }

                </div>
            </div>


        </>
    )
}

export default CargarUsuarios
