import React, { useState, useEffect, useCallback, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import { /* getAuthenticatedHeaders, */ FormatAmount, formatCurrency } from '../common/functions';
import moment from 'moment';
import { AuthContext } from '../../context/AuthContext';

import CircularProgress from '@material-ui/core/CircularProgress';
import cancel from "../../assets/images/cancel.svg"
import approve from "../../assets/images/approve.svg"

import { AiOutlineFilePdf } from "react-icons/ai";
export default function ModalDocumentos(props) {
    const { isOperador, } = useContext(AuthContext)

    var ids = props.IDS
    var isLoading = false;
    const [dataM, setDataM] = useState([props.Data])
    const [browserName, setBrowserName] = useState("");
    const [showModal, setShowModal] = useState(props.open);
    const [note, setNote] = useState("");
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", escFunction);
    }, []);
    const escFunction = useCallback((event) => {


        if (event.keyCode === 27) {
            props.modal(false)
        } else if (event.keyCode === 37) {
            isLoading = true
            if (ids > 0) {
                if (props.Data[ids - 1] != undefined) {
                    ids -= 1
                }

            }




        } else if (event.keyCode === 39) {
            if (ids < 2) {
                if (props.Data[ids + 1] != undefined) {
                    ids += 1
                }

            }


        }

        if (!!props.Data[ids]) {
            props.setIDS(ids)
        } else {


        }
        isLoading = false

    })
    const render = (x) => {
        props.setID(props.Data[ids].id)
        props.setStatus(x)
        props.CambioStatus(x, props.Data[ids].id, note)
    }
    const Enter = (e) => {
        if (e.key === 'Enter') {
            render(3);
        }

    }

    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                isLoading &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );

    return (
        <>

            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">


                    <div className="relative w-full my-6 mx-auto max-w-4xl">

                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.modal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                            <div className="relative px-6 pb-1 flex justify-center items-center">
                                {isLoading && <Circular />}

                                {!isLoading && props.Data[ids] && (props.Data[ids].type_document.id === 7 || props.Data[ids].type_document.id === 3 || props.Data[ids].type_document.id === 6) && props.Data[ids].url[props.Data[ids].url.length - 1] == "f" ?
                                    <div className=" flex justify-center items-center h-full"><a className="w-full justify-center items-center flex flex-col" href={props.Data[ids].url}><AiOutlineFilePdf size="100px" style={{ color: 'rgb(119, 119, 119)' }} /> Descargar PDF</a></div> : < img style={{ maxHeight: 650 }} src={`${props.Data[ids] != undefined ? props.Data[ids].url : ""}`} />}
                            </div>
                            <div className="text-2xl centrar-contenido">

                            </div>
                            {showText &&
                                <div className="py-2 px-6  border-gray-300 rounded-b  flex justify-center bg-gray flex-row">
                                    <input onKeyPress={(e) => Enter(e)} onChange={(e) => setNote(e.target.value)} placeholder="Motivo de rechazo" className="px-0.5 block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white" style={{ resize: "none", border: '1px solid', width: "100%", }} />
                                    <button onClick={() => render(3)} className="Button-cancelar-enviar ml-2 px-2">Confirmar</button>
                                </div>
                            }

                            {props.Data[ids] && props.Data[ids].document_status == 1 && showText == false && <div className=" p-2 border-gray-300 rounded-b flex-row flex  justify-around">
                                {!isOperador && <div onClick={() => setShowText(true)} className='flex flex-row items-center cursor-pointer'>
                                    <img className='mr-2' src={cancel} width={50} height={50} />
                                    <p className=" font-bold	text-lg" style={{ color: "#777777" }}>Rechazar</p>
                                </div>}
                                {!isOperador && <div onClick={() => render(2)} className='flex flex-row items-center cursor-pointer'>
                                    <img className='mr-2' src={approve} width={50} height={50} />
                                    <p className=" font-bold	text-lg" style={{ color: "#777777" }}>Aprobar</p>
                                </div>}
                            </div>}
                            {props.Data[ids] && props.Data[ids].document_status == 2 && showText == false && <div className="  p-2 justify-center items-center flex">
                                <img className='mr-2' src={approve} width={50} height={50} />
                                <p className=" font-bold	text-lg" style={{ color: "#777777" }}>{`Aprobado por ${props.Data[ids]?.owner_approved?.username}`}</p>
                            </div>
                            }
                            {props.Data[ids] && props.Data[ids].document_status == 3 && showText == false && <div className="  p-2 justify-center items-center flex">
                                <img className='mr-2' src={cancel} width={50} height={50} />
                                <p className=" font-bold	text-lg" style={{ color: "#777777" }}>Rechazado</p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className=" fixed inset-0 z-40 " style={{
                    background: 'rgba(47, 46, 44, 0.5)',
                    backdropFilter: 'blur(10px)',
                }}></div>
            </>

        </>
    );
}
