import React, { useState, useEffect } from "react";
import { IoMdDownload } from "react-icons/io"
import pending from "../../assets/images/pending-tasks.png"
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";



export default function ModalDownload({ data, downloadTxt, loading }) {

    function formatDate(x) {
        // get current timestamp
        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }
    function Name(name) {
        if (name.search("bancolombia") == 10) {
            return "D-B-Bancolombia"

        } else if (name.search("bancolombiap") == 10) {
            return "D-P-Bancolombia"
        }
        else if (name.search("avvillas") == 10) {
            return "D-B-Avvillas"
        } else if (name.search("avvillas") == 10) {
            return "D-P-Avvillas"
        }
        else {
            return "Dispersión"
        }


    }
    return (
        <>
            <div className="object-right-bottom absolute right-0 flex  mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10  pt-4  flex-col overflow-auto" style={{ width: "50rem" }}>
                {!loading && data.map((item, i) => i < 7 ? (<div className="flex flex-row justify-between  mb-4 items-center">
                    <p className="ml-4 " style={{ width: 365 }}>{(item.name_file)}</p>
                    <p>{formatDate(item.date_created)}</p>
                    {item.file !== null ?
                        <IoMdDownload className="mr-4 cursor-pointer" style={{ width: 25, height: 25, color: "#2E86C1" }} onClick={() => downloadTxt(item)} /> : <div className=""><img className="mr-4" src={pending} style={{ width: 25, height: 25 }} /></div>
                    }
                </div>
                ) : null)}
                {loading && <div className="h-full w-full flex items-center justify-center pb-3">
                    <CircularProgress size={45} />
                </div>}
                {/* <button className=" text-trixel-green rounded-b-lg py-2">Mas</button> */}
            </div>


        </>

    );
}