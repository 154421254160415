import CryptoJS from 'crypto-js';
import moment from 'moment';

export default (type_file='') => {
    const HASH_KEY = process.env.REACT_APP_HASH_KEY;
    
    const myDate = moment().format("YYYY-MM-DD")
    const signature = `${myDate}-${HASH_KEY}-${type_file}`;
    // Convierte HASH_KEY en un objeto WordArray
    const hash_key = CryptoJS.enc.Utf8.parse(HASH_KEY);

    // Crea un objeto HmacSHA1 y actualiza con la firma
    const hash = CryptoJS.HmacSHA1(signature, hash_key);
    // Convierte el resultado en una cadena hexadecimal
    const sign = CryptoJS.enc.Hex.stringify(hash);
    return sign;
}