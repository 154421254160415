import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { /* getAuthenticatedHeaders, */ FormatAmount, formatCurrency } from '../common/functions';
import moment from 'moment';
import Banner from '../../components/Banner';
import approve from "../../assets/images/approve.svg"
import cancel from "../../assets/images/cancel.svg"
import Button from '../../components/Button';
import _, { filter } from 'lodash';
import axios from 'axios';
import { FunctionContext } from '../../context/FuntionsContext';
import { AuthContext } from '../../context/AuthContext';
import ModalAddBlackList from "../layouts/ModalAddBlackList"
import { RiContactsBookUploadLine } from 'react-icons/ri';
import { useHistory, } from "react-router-dom";
import AxioInstance from '../../axios/AxiosConfig';


const BlackList = (props) => {
    const [showBanner, setShowBanner] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [numFilas, setNumFilas] = useState([]);
    const [loading, setLoading] = useState(false);

    const { modalBlackList, setModalBlackList } = useContext(FunctionContext)
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);


    useEffect(() => {
        getListBlackList()
    }, []);




    const getListBlackList = async () => {

        try {
            const response = await AxioInstance.post(`/api/list/blacklist/`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: 1,
                perPage: 50,
            });

            if (response.data.status === 200) {


                setData(response.data.data.data)

            }
        } catch (error) {


        }


    };
    const columns = [
        {
            name: "REF",
            sortable: true,
            selector: "payer_name",
            grow: 1,
            cell: (row) => (
                <div className="text-gray-700 py-3 flex items-center  ">
                    <span> {row.id}</span>
                </div>
            ),
        },
        {
            name: "DOCUMENTO",
            sortable: true,
            selector: "payer_name",
            grow: 2,
            cell: (row) => (
                <div className="text-gray-700 py-3 flex items-center  ">
                    <span> {row.document_number}</span>
                </div>
            ),
        },
        {
            name: "NOTE",
            sortable: true,
            selector: "payer_name",
            grow: 3,
            cell: (row) => (
                <div className="text-gray-700 py-3 flex items-center  ">
                    <span> {row.note}</span>
                </div>
            ),
        },
        {
            name: "TIPO",
            sortable: true,
            selector: "payer_name",
            grow: 3,
            cell: (row) => (
                <div className="text-gray-700 py-3 flex items-center  ">
                    <span> {row.black_list_type === "WITHDRAW" ? "Retiro" : row.black_list_type}</span>
                </div>
            ),
        },






    ];
    const customStyles = {
        header: {
            style: {
                color: "#000",
                minHeight: "45px",
            },
        },
        headRow: {
            style: {
                whiteSpace: "nowrap",
                fontSize: "0.75rem",
                textAlign: "left",
            },
        },
        headCells: {
            style: {
                paddingLeft: "3rem",
                paddingRight: "3rem",
            },
        },
        cells: {
            style: {
                borderStyle: "dashed",
                borderTopWidth: "1px",
                whiteSpace: "nowrap",
                paddingLeft: "3rem",
                paddingRight: "3rem",
            },
        },
    };
    const handleSort = (column, sortDirection) => {

    };
    const handlePerRowsChange = async (newPerPage, page) => {

    };
    const handlePageChange = (page) => {

    };
    return (
        <>



            <div className="flex md:flex-row flex-wrap p-2">


                <div className="w-full mt-5 ">
                    {modalBlackList === true &&
                        <ModalAddBlackList modal={() => setModalBlackList()} open={modalBlackList} setShowBanner={setShowBanner} getListBlackList={getListBlackList} />
                    }
                    {
                        showBanner && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`Agregado a la BlackList`}
                                setShowBanner={setShowBanner}
                                error={false}
                            />
                        )
                    }

                    <DataTable
                        data={data}
                        columns={columns}
                        customStyles={customStyles}
                        noHeader={true}
                        fixedHeader={true}
                        highlightOnHover={true}
                        pagination={true}
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={numFilas}
                        paginationComponentOptions={{
                            noRowsPerPage: false,
                            rowsPerPageText: "Filas por página",
                            rangeSeparatorText: "de",
                        }}
                        onSort={handleSort}

                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressPending={loading}
                        progressComponent={
                            <div className="flex md:flex-row flex-wrap p-4">
                                <div className="w-full">
                                    <div style={{ height: "50vh" }}>
                                        <div className="h-full w-full flex items-center justify-center">
                                            <CircularProgress size={75} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    />

                </div>
            </div>

        </>
    )
}

export default BlackList
