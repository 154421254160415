import React, { useState, useEffect, useContext, useRef } from 'react'
import cobruNew from "../../assets/payment_methods/cobruNew.png"
import logoPath from '../../helpers/logoPath'
const Loading = (props) => {
    return (
        <>
            <div className="flex justify-center items-center w-full h-screen">
                <img src={logoPath} className='w-1/2' />
            </div>
        </>
    )
}

export default Loading
