import React, { useEffect, useState, useContext } from 'react';
import { FaFileExcel } from "react-icons/fa";
import File from '../../assets/files/cobru_plantilla_envios.xlsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import Numeral from "numeral";
import ModalCumplimiento from '../../containers/layouts/ModalCumplimiento'
import ModalEnviar from "../../containers/layouts/ModalEnviar.js"
import Banner from '../../components/Banner';
import { token, isEmpty, FormatAmount, FormatDocument/* getAuthenticatedHeaders */ } from '../common/functions';
import { FORMAT } from '../../helpers';
import { AuthContext } from '../../context/AuthContext';
import { FunctionContext } from '../../context/FuntionsContext';
import { Button } from '@material-ui/core';
import AxioInstance from '../../axios/AxiosConfig';
import variablesDomain from '../../helpers/variablesDomain.js';



const Cumplimiento = (props) => {
    const [totalBalance, setTotalBalance] = useState(props.totalBalance);
    const [ModalCheck, setModalCheck] = useState(false);
    const [ModalCheckData, setModalCheckData] = useState([]);

    const [showBanner, setShowBanner] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [description, setDescription] = useState('');

    const [monto, setMonto] = useState('');

    const [bannerError, setBannerError] = useState(false);
    const [bannerContent, setBannerContent] = useState({
        title: '',
        description: '',
    });
    const [loadingCancelled, setLoadingCancelled] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDrop, setIsLoadingDrop] = useState(false);
    const pendingTasks = props.task ? props.task : 0;
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [msn, setMsn] = useState([]);
    const { getAuthenticatedHeaders, getAccessToken } = useContext(AuthContext);
    const { buscarEnvios, buscar, EnviarLayout, setEnviarLayout, opcionCumplimiento } = useContext(FunctionContext);
    //Pagination
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [reloadData, setReloadData] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [Modal, setModal] = useState(false);

    useEffect(() => {
        //getUserWithDraws();
        getBalance()
        fetchCobrus(1);
    }, [reloadData])

    useEffect(() => {
        if (buscar) {
            fetchCobrus()
        }
    }, [buscar])
    const getBalance = async () => {
        try {
            const response = await AxioInstance.post(`/api/getBalance`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                let pendingBalance = "$0";
                let pendingSaved = "$0";
                let data = response.data.data;

                if (data.pendingBalance > 0) {
                    pendingBalance = FormatAmount(Math.round(data.pendingBalance));
                };
                if (data.saved > 0) {
                    pendingSaved = FormatAmount(Math.round(data.saved));
                };

                setTotalBalance({
                    balance: FormatAmount(data.balance),
                    pendingBalance: pendingBalance,
                    pendingSaved: pendingSaved
                })

            }

        } catch (error) {

        }
    };

    const OpenModal = (e) => {
        setDataModal(e)
        setModal(true)
    }
    const FormatAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.toString().replace(/\D/g, "")
        );

        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        return withdrawAmount;
    };
    const RequestSend = async (data) => {
        try {
            setModalCheckData(data)
            let banner = {}

            const response = await AxioInstance.post(`/api/send/`, {
                headers: await getAuthenticatedHeaders(),
                data
            });

            if ((response.data.status === 200 || response.data.status === 201) && response.data.data.error != "USER_TO_DOES_NOT_EXIST") {
                setModalCheck(true)
                setReloadData(!reloadData)
            } else {
                banner = {
                    title: 'Ocurrio un error',
                    description: 'No se pudo realizar la operacion',
                }
                setBannerContent(banner)
                setShowBanner(true)
                setBannerError(true)
                setReloadData(!reloadData)
            }
            setEnviarLayout(false)
        } catch (error) {

        }
    };
    const onDrop = async (acceptedFiles) => {
        setShowBanner(false)
        let banner = {}
        if (!isEmpty(acceptedFiles)) {
            const DEV = localStorage.getItem('DEV');

            setIsLoadingDrop(true)
            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            formData.append('typeTask', 'send')
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
            };

            axios.post(DEV + 'panel/', formData, { headers }, {
            }).then(res => {
                if (res.data.result === "OK") {
                    banner = {
                        title: 'Ok',
                        description: 'Envios en proceso'
                    }

                    setIsLoadingDrop(false)
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(false)
                    setReloadData(!reloadData)
                } else {
                    banner = {
                        title: 'Ocurrio un error',
                        description: 'No se pudo realizar la operacion',
                    }
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(true)
                    setReloadData(!reloadData)

                }
            }).catch((e) => {
                banner = {
                    title: 'Ocurrio un error',
                    description: 'No se pudo realizar la operacion',
                }
                setBannerContent(banner)
                setShowBanner(true)
                setBannerError(true)
                setReloadData(!reloadData)

            })
        }
        setIsLoadingDrop(false)
    }

    useEffect(() => {
        //fetchData();
        fetchCobrus(1);
    }, [isLoading])


    //data table columns
    const columns = [

        {
            name: 'CLIENTE',
            grow: 8,
            cell: row => (
                <div className='links cursor-pointer' onClick={() => OpenModal(row)}>
                    {(row.Nombredelcliente)}
                </div>
            )
        },

        {
            name: 'DOCUMENTO',
            grow: 4,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {`${row.TipoDocIdentidad == "Cédula de ciudadanía" ? "CC" : row.TipoDocIdentidad} ${FormatDocument(row.Documento)}`}</span>
                </div>
            )
        },
        {
            name: 'SCORE',
            grow: 1,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {row.ScoringSarlaft}</span>
                </div>
            )
        },
        {
            name: 'CIUDAD',

            grow: 2,
            sortable: true,
            selector: 'date',
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className="text-gray-700 py-3  flex items-center"> {row.Ciudad} </span>
                </div>
            )
        },

        /* {
            name: 'MENSAJE',
            grow: 4,
            wrap: true,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className='ml-3'> {row.description || ''}</span>
                </div>
            )
        }, */

    ];

    const customStyles = {
        header: {
            style: {
                color: '#000',
                minHeight: '45px'

            },
        },
        headRow: {
            style: {
                whiteSpace: 'nowrap',
                fontSize: '0.75rem',
                textAlign: 'left',

            },
        },
        headCells: {
            style: {
                paddingLeft: '3rem',
                paddingRight: '3rem'
            },
        },
        cells: {
            style: {
                borderStyle: 'dashed',
                borderTopWidth: '1px',
                whiteSpace: 'nowrap',
                paddingLeft: '3rem',
                paddingRight: '3rem'
            },
        },
    };



    //server side pagination
    const fetchCobrus = async page => {

        setLoading(true);

        var document = buscarEnvios
        var typeDocument = document === "" ? "" : opcionCumplimiento


        const response = await AxioInstance.post(`/api/reporte_isolution/`, {
            headers: await getAuthenticatedHeaders(),
            pageNum: page ? page : 1,
            perPage: perPage,
            document: document,
            document_type: typeDocument
        });




        if (response.data.status === 200) {

            let final = JSON.parse(response.data.data.data.Entity)

            if (final.length == undefined) {
                setResponseData([final]);
            } else {
                setResponseData(final);
            }
            setLoading(false);

        } else if (response.data.status === 500) {
            let banner = {
                title: 'Ocurrio un error',
                description: "Ha ocurrido un error, intentalo nuevamente",
            }
            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(true)


        }
        else {
            let banner = {
                title: 'Ocurrio un error',
                description: response.data.data.message,
            }
            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(true)
        }
        setLoading(false);

    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await AxioInstance.post(`/api/sends`, {
            headers: await getAuthenticatedHeaders(),
            pageNum: page,
            perPage: newPerPage
        });

        if (response.data.status === 200) {
            //setResponseData(final);

            let final = JSON.parse(response.data.data.data.Entity)
            setResponseData(final);
            setPerPage(newPerPage);
            setLoading(false);

        } else {
            setMsn("Ha ocurrido un error, intentalo nuevamente");
            setIsLoading(true);
        }

    };

    const handlePageChange = page => {
        fetchCobrus(page);
    };

    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setMonto(amount)
        if (amount > 999) {

        }
        let current_balance = parseInt(totalBalance.toString().replace(/\D/g, ""));
        if (amount > current_balance) {
            amount = current_balance;

        } else if (amount < amountValue) {
            amount = amountValue;

        }
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setMonto(withdrawAmount);
    };

    return (
        <>
            <div className='flex flex-row w-full justify-around'>


                {EnviarLayout &&
                    <div className="flex md:flex-row flex-wrap  w-1/2">
                        <div className="w-full md:w-full pb-2 pr-2 text-center text-gray-200">
                            <div className="rounded overflow-hidden shadow-lg bg-white">
                                <div className="px-2 py-8">
                                    <div className="font-bold text-xl mb-2 text-black">
                                        {totalBalance.balance}
                                    </div>
                                    <p className="text-gray-700 text-sm">Saldo Disponible</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-full bg-white p-4 text-center text-gray-200 shadow-lg mr-2">
                            <p className="font-body text-lg text-gray-800 text-left py-2">Envios en lote</p>
                            <div className="text-center py-4">
                                <FaFileExcel size="2em" className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-green-600" />
                                <a className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700" href={File} target="blank">
                                    <svg className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700 fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                    <span>Descargar aquí plantilla Envios </span>
                                </a>

                                <div className="mt-5 border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 cursor-pointer">
                                    <Dropzone
                                        onDrop={onDrop}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                        {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {!isDragActive && acceptedFiles.length == 0 && '¡Haga clic aquí o arrastre un archivo xlsx para cargarlo!!'}
                                                {isDragActive && !isDragReject && "¡Sueltalo!"}
                                                {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                                <ul className="list-group mt-2">
                                                    {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                        <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                            se subio el archivo: {acceptedFile.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        )}
                                    </Dropzone>
                                    {isLoadingDrop &&
                                        <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                            </div>


                        </div>


                    </div>}

                {EnviarLayout &&
                    <div className="flex md:flex-row shadow-lg  w-1/2 ">
                        <div className="w-full md:w-full bg-white p-4 text-center ">
                            <p className="font-body text-lg text-gray-800 text-left py-2">Envios personales</p>
                            <div className="w-full ">
                                <div className=" mx-3 mb-6 mt-3 flex-col flex items-center ">
                                    <label className="mx-3">Número de cuenta</label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder="Usuario"
                                        type="text"
                                        value={usuario}
                                        onChange={(e) => setUsuario(e.target.value)}
                                    />
                                    <label className="mx-3">Número de cuenta</label>
                                    <input
                                        className="appearance-none mt-3 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="withdraw_amount"
                                        value={"$" + monto}
                                        onChange={handleChangeWithDrawAmount}
                                        type="tel"
                                        keyboard="numeral"
                                        placeholder="0"
                                        pattern="[0-9]*"

                                    ></input>
                                    <label className="mx-3">Número de cuenta</label>
                                    <input className="appearance-none mt-3 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder="Descripción"
                                        type="text"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <button
                                        className="w-full mt-3 flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                        type="button"


                                    >
                                        {"Enviar"}
                                    </button>
                                </div>
                            </div>


                        </div>


                    </div>}

            </div>
            {showBanner && bannerContent && (
                <div className="w-65">
                    <Banner title={bannerContent.title} error={bannerError} description={bannerContent.description} setShowBanner={setShowBanner} />
                </div>
            )

            }
            {Modal && < ModalCumplimiento data={dataModal} setModal={setModal} />}
            <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs">
                <button className={` button-outline-off hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` + (isActiveFilter ? `bg-${variablesDomain.classNameColor} ` : 'bg-gray-500')} key="0" value="0">
                    <span>Todos</span>
                </button>

            </div>

            {
                <div style={{ marginTop: '20px' }}>
                    <DataTable
                        data={responseData}
                        columns={columns}
                        customStyles={customStyles}
                        noHeader={true}
                        fixedHeader={true}
                        highlightOnHover={true}
                        pagination={pagination}
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        noDataComponent={"No hay movimientos disponibles"}

                        paginationComponentOptions={
                            {
                                noRowsPerPage: false,
                                rowsPerPageText: 'Filas por página',
                                rangeSeparatorText: 'de'
                            }
                        }

                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressPending={loading}
                        progressComponent={
                            <div className="flex md:flex-row flex-wrap p-4">
                                <div className="w-full">
                                    <div style={{ height: '50vh' }}>
                                        <div className='h-full w-full flex items-center justify-center'>
                                            <CircularProgress size={75} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    />
                </div>
            }
            {/* {
                loadingTable == true &&
                <div className="flex md:flex-row flex-wrap p-4">
                    <div className="w-full">
                        <div style={{ height: '50vh' }}>
                            <div className='h-full w-full flex items-center justify-center'>
                                <CircularProgress size={75} />
                            </div>
                        </div>
                    </div>
                </div>
            } */}

        </>
    )
}

export default Cumplimiento;