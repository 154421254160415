import React, { useContext }from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const PublicRoute = ({component: Component, ...rest}) => {
    const { isAuth } = useContext(AuthContext)
    return (
        <Route {...rest} render={props => (
           /*  localStorage.getItem('token') */ isAuth === true ?
                <Redirect to="/" />
            : <Component {...props} />
        )} />
    );
};

export default PublicRoute;