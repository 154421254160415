import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useIdleTimer } from 'react-idle-timer'
import Loading from "../../containers/pages/Loading"
import { FormatPhoneNumber, FormatAmount, /* getAuthenticatedHeaders, getAccessToken*/ } from '../common/functions';
import Routes from '../../components/Route';
import Admin from '../../components/Route/admin';
import Mutual from '../../components/Route/mutual';
import moment from 'moment';
import { AuthContext } from '../../context/AuthContext';
import Others from '../../components/Route/other'
import AxioInstance from '../../axios/AxiosConfig';
import ModalRecuperarPassword from './ModalRecuperarPassword';
import ModalChangePassword from './ModalChangePassword';
import variablesDomain from '../../helpers/variablesDomain';
import RoutesBrands from '../../components/Route/brands/RoutesBrands';


const Dashboard = (props) => {
  const { getAuthenticatedHeaders, getAccessToken, isSuperUser, checkSuperUser, checkPermissions, others, LoadingLogin, mutual, userDetailsContext, setUserDetailsContext, is_changed_password, set_is_changed_password } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true);
  const [state1, setState1] = useState(0);
  const [state0, setState0] = useState(0);
  const [state3, setState3] = useState(0);

  const pathname = props.match.params.path;
  const [userDetails, setUserDetails] = useState([]);

  const [userBalance, setUserBalance] = useState({
    balance: "$0",
    pendingBalance: "$0",
    pendingSaved: "$0",
  });
  const [userPendingTasks, setUserPendingTasks] = useState([]);
  const [userCobrus, setUserCobrus] = useState([]);
  //const { isSuperUser } = useContext(AuthContext)
  //const [ isSuperUser, setIsSuperUser ] = useState(false)
  const [loadData, setLoadData] = useState(null)

  //const [userSuper, setUserSuper] = useState(false);



  const getInitialData = async () => {
    await Promise.all([getUserDetail(), getBalance(), getPendingTasks(),/*  getdowladpdf() *//* checUserType() */])
  }
  const lol = async () => {
    AxioInstance.post(`/api/get_business/`, {
      headers: await getAuthenticatedHeaders(),
    })
      .then(response => {
        if (response.data.status === 200) {

          localStorage.setItem("business_constants", JSON.stringify(response.data.data))




        }
      }, error => {

      });
  };
  const LoginAsync = async (contentType = { 'Content-Type': 'application/json' }) => {

    try {

      //we send the login rquest.
      let response = await fetch(`https://panel.validu.co/token/`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          ...contentType,
          'X-Api-Key': '7tGAnJEt.mYLI45qvqeKEI9kMG681i9TQ636q0FWn',
        },
        body: JSON.stringify({ username: 'cobru', password: 'Trixelco321' }),
        mode: 'cors'
      });




      if (response.status.toString() === "200") {

        let responseJson = await response.json();



        if (responseJson.refresh) {
          localStorage.setItem("access_token_validu", responseJson.access);
          localStorage.setItem("refresh_token_validu", responseJson.refresh);
          localStorage.setItem("token_created_validu", new Date());
          localStorage.setItem("loginOnce", "true");
        }
      }

    } catch (error) {
      console.error(error);

    }
  }
  useEffect(() => {
    LoginAsync()
  }, [])

  const getUserDetail = async () => {
    AxioInstance.post(`/api/user`, {
      headers: await getAuthenticatedHeaders(),
    })
      .then(response => {
        if (response.data.status === 200) {
          set_is_changed_password(response?.data?.data?.is_changed_password)

          setUserDetails(response.data.data);
          setUserDetailsContext(response.data.data);

          localStorage.setItem('userDetails', JSON.stringify(response.data.data))
          checkPermissions()
          setIsLoading(false);
        }
      }, error => {

      });
  };
  /* const getdowladpdf = async () =>   {
    AxioInstance.post(`/api/download/withdraw`, {
      headers: await getAuthenticatedHeaders(),
      
    })
      .then(response => {
        
        if (response.data.status==200) {
          
        console.log("🚀 ~ getdowladpdf ~ response.data:",)
        const buffer = new Uint8Array(response.data?.data?.data);

        const blob = new Blob([ buffer], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'archivo-descargado.pdf'); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }

      
      }, error => {
      console.log("🚀 ~ getdowladpdf ~ error:", error)

      });
  };
 */
  /*  const getUserCobrus = async () => {
     try {
       const response = await axios.post(`/api/cobrus/`, {
         headers: await getAuthenticatedHeaders(),
         pageNum: 1,
         perPage: 50
       });
 
       if (response.data.status === 200 && response.data.data != undefined) {
 
         let data = response.data.data
 
         data.map((cobru) => {
           try {
             cobru.payment_method_enabled = JSON.parse(cobru.payment_method_enabled);
             cobru.payer_phone = FormatPhoneNumber(cobru.payer_phone);
             cobru.amount = FormatAmount(cobru.amount);
             cobru.expiration_date = new moment(cobru.expiration_date);
             cobru.date_created = new moment(cobru.date_created);
             cobru.date_payed = new moment(cobru.date_payed);
           } catch (error) {

           }
         });

 
         setUserCobrus(data);
         setIsLoading(false);
       }
 
     } catch (error) {

     }
   }; */

  const getBalance = async () => {
    try {
      const response = await AxioInstance.post(`/api/getBalance`, {
        headers: await getAuthenticatedHeaders(),
      });

      if (response.data.status === 200) {
        let pendingBalance = "$0";
        let pendingSaved = "$0";
        let data = response.data.data;

        if (data.pendingBalance > 0) {
          pendingBalance = FormatAmount(Math.round(data.pendingBalance));
        };
        if (data.saved > 0) {
          pendingSaved = FormatAmount(Math.round(data.saved));
        };

        setUserBalance({
          balance: FormatAmount(data.balance),
          pendingBalance: pendingBalance,
          pendingSaved: pendingSaved
        })
        setIsLoading(false);
      }

    } catch (error) {

    }
  };


  const getPendingTasks = async () => {

    try {
      const response = await AxioInstance.post(`/api/pending/tasks`, {
        headers: await getAuthenticatedHeaders(),
      });
      if (response.data.status === 200) {
        setUserPendingTasks(response.data.data);
        setIsLoading(false);
      }

    } catch (error) {

    }
  };

  const getState0 = async () => {

    try {
      const response = await AxioInstance.post(`/api/get_state/`, {
        headers: await getAuthenticatedHeaders(),
        state: 0
      });
      if (response.data.status === 200) {
        return (response.data.data);

      }

    } catch (error) {

    }
  };
  const getState1 = async () => {

    try {
      const response = await AxioInstance.post(`/api/get_state/`, {
        headers: await getAuthenticatedHeaders(),
        state: 1
      });
      if (response.data.status === 200) {
        return (response.data.data);

      }

    } catch (error) {

    }
  };
  const getState3 = async () => {

    try {
      const response = await AxioInstance.post(`/api/get_state/`, {
        headers: await getAuthenticatedHeaders(),
        state: 3
      });
      if (response.data.status === 200) {
        return (response.data.data);
      }

    } catch (error) {

    }
  };




  const handleOnIdle = event => {

    getAccessToken();
  }

  const handleOnActive = event => {
    getAccessToken();

  }

  const handleOnAction = (e) => {

  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
  useEffect(() => {
    getInitialData();

    lol()
  }, []);
  useEffect(() => {
    checkSuperUser()
  }, [])
  useEffect(() => {
    setTimeout(() => {
      setLoadData(true)
    }, 500);
  }, [isSuperUser])


  if (!loadData) {
    return null
  }

  return !LoadingLogin ? (

    <>
      {others ?
        <div>
          <Others user={userDetails} pathname={pathname} totalBalance={userBalance} pendingTasks={userPendingTasks} balance_saved={userDetails.balance_saved} />
        </div>
        :
        mutual === true ?
          <Mutual user={userDetails} pathname={pathname} totalBalance={userBalance} pendingTasks={userPendingTasks} balance_saved={userDetails.balance_saved} />
          :
          isSuperUser === true ?
            variablesDomain.brand ?
              <RoutesBrands user={userDetails} pathname={pathname} totalBalance={userBalance} pendingTasks={userPendingTasks} balance_saved={userDetails.balance_saved} />
              :
              <Admin user={userDetails} isSuperUser={isSuperUser} pathname={pathname} totalBalance={userBalance} pendingTasks={userPendingTasks} />
            :
            /* is_changed_password */
            false
              ?
              <ModalChangePassword />
              :
              <Routes user={userDetails} state1={state1} state3={state3} state0={state0} pathname={pathname} totalBalance={userBalance} pendingTasks={userPendingTasks} balance_saved={userDetails.balance_saved} />
      }
    </>
  ) : (
    <Loading />
  )

}

export default Dashboard;