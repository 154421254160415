import React, { useState, useEffect, useContext } from 'react';
import Collaborator from '../../components/Collaborator';
import axios from 'axios';
import { useForm } from "react-hook-form";
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { getAuthenticatedHeaders } from '../common/functions';
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';

import AxioInstance from '../../axios/AxiosConfig';

const Colaboradores = (props) => {
    const [pending, setPending] = useState(true);
    const [isHidden, setIsHidden] = useState(true);

    const [collaborators, setCollaborators] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [msn, setMsn] = useState();
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)
    const canViewUser = permissions.permission_users;

    const { register, handleSubmit, reset, errors, getValues } = useForm();
    const [modoEditar, setModoEditar] = useState(false)
    const [data, setData] = useState([]);
    const [dataEditar, setDataEditar] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [Apellido, setApellido] = useState(null);
    const [correo, setCorreo] = useState(null);
    const [rol, setRol] = useState(null);
    const [permisosCobrar, setPermisosCobrar] = useState(null);
    const [permisosRetirar, setPermisosRetirar] = useState(null);
    const [permisosCrear, setPermisosCrear] = useState(null);
    const [AccesoConsultas, setAccesoConsultas] = useState(null);
    const [AccesosReportes, setAccesoReportes] = useState(null);
    const [permisosUser, setpermisosUsers] = useState(null);
    const [estado, setEstado] = useState(null);
    const [permisosEnviar, setPermisosEnviar] = useState(null);
    const [render, setRender] = useState(false);

    //const canViewUser = props.canViewUser;
    useEffect(() => {
        if (render) {
            getCollaborator()
            setRender(false)
        }

    },
        [render])

    const volver = (e) => {
        setModoEditar(false)
    }
    const actulizarColaborador = (e) => {
        e.preventDefault();

        var datos = {
            nombre: nombre,
            apellido: Apellido,
            correo: correo,
            Rol: rol,
            Cobrar: permisosCobrar,
            Retirar: permisosRetirar,
            Crear: permisosCrear,
            Acceso_Consultas: AccesoConsultas,
            Accesos_Reportes: AccesosReportes,
            permisos_User: permisosUser,
            Estado: estado,
            Enviar: permisosEnviar,
            id: dataEditar.pk

        }

        editarColaboradores(datos)

    }
    const irActualizar = (data) => {
        setNombre(data.first_name)
        setApellido(data.last_name)
        setCorreo(data.email)
        setRol(data.title)
        setPermisosCobrar(data.permission_cobru)
        setPermisosRetirar(data.permission_withdraw)
        setPermisosCrear(data.permission_create_colaborador)
        setAccesoConsultas(data.permission_query)
        setAccesoReportes(data.permission_reports)
        setpermisosUsers(data.permission_users)
        setEstado(data.active)
        setPermisosEnviar(data.permission_send)

        setModoEditar(true)
        setDataEditar(data)
    }

    const toggleHidden = (msn) => {

        setIsHidden(!isHidden);
    }

    const getCollaborator = async () => {
        setPending(true)
        await AxioInstance.post(`/api/colaborator`, {
            headers: await getAuthenticatedHeaders(),
        }).then(response => {
            if (response.data.status === 200) {
                setCollaborators(response.data.data);
                setIsLoading(false);
            }
        }).catch(error => {

        });
        setPending(false)
    }
    const editarColaboradores = async (data) => {

        await AxioInstance.post(`/api/editar_colaborador/`, {
            data, headers: await getAuthenticatedHeaders()
        }).then(response => {
            if (response.data.status === 200) {

                setModoEditar(false)
                setRender(true)
            }
        }).catch(error => {

        });
    }

    useEffect(() => {
        getCollaborator()
    }, [])

    const onSubmit = (data, e) => {

        createCollaborator(data);
        e.target.reset();
    }

    const createCollaborator = async (data) => {
        AxioInstance.post(`/api/create/collaborator`, { data, headers: await getAuthenticatedHeaders() })
            .then(response => {

                if (response.data.pk) {

                    setRender(true)

                }
            }).catch(error => {

            });

    }
    const columns = [{
        name: 'NOMBRE',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                {`${row.first_name} ${row.last_name}`}
            </div>)
        ,
        sortable: true,
    },
    {
        name: 'CORREO ELECTRÓNICO',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                {row.email}
            </div>)
        ,
        sortable: true,

    },
    {
        name: 'ROL',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                {row.title}
            </div>)
        ,
        sortable: true,

    },
    {
        name: 'REF',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'} style={{ display: "contents" }}>
                {row.active ? "ACTIVO" : "DESACTIVADO"}
            </div>)
        ,
        sortable: true,
    },
    {
        name: 'ACCIONES',
        cell: row => (
            <button
                className="bg-green-600 hover:bg-green-600 border-green-600 hover:border-green-600 text-sm  text-white py-2 px-4 rounded-full float-right button-outline-off" onClick={() => irActualizar(row)}>
                Editar
            </button>
        )
        ,
        sortable: true,

    },
    ]
    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                isLoading &&
                <CircularProgress size={75} />
            }
        </div>
    );


    return canViewUser ? (
        <>

            <div className=" md:grid md:grid-cols-12 gap-2" >



                {isHidden && modoEditar == false &&
                    <div className=" col-span-4 " >
                        <div className=" bg-white p-4 text-center overflow-x-auto mt-6" style={{ boxShadow: " 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%)" }}>
                            {!modoEditar && <p style={{ fontSize: "27px", marginBottom: "23px" }}>Agregar Colaborador</p>}
                            {modoEditar && <p style={{ fontSize: "27px", marginBottom: "23px" }}>{modoEditar ? "Editar" : "Agregar"} Colaborador</p>}
                            <form className="w-full" onSubmit={handleSubmit(onSubmit)} >
                                <div className="flex flex-wrap mx-1 mb-6" >


                                    <input className={"px-0.5 block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 m leading-tight focus:outline-none focus:bg-white" + (errors.firstname ? " border-red-500" : "")} id="grid-first-name" type="text" placeholder="Nombre"
                                        name="firstname"
                                        ref={register({ required: true })}
                                    />
                                    {errors.firstname && <p className="text-red-500 text-xs italic">Campo requerido</p>}


                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">

                                    <input className={"px-0.5 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" + (errors.lastname ? " border-red-500" : "")} id="grid-last-name" type="text" placeholder="Apellido"
                                        name="lastname"
                                        ref={register({ required: true })}
                                    />
                                    {errors.lastname && <p className="text-red-500 text-xs italic">Campo requerido</p>}

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6" >

                                    <input className={"px-0.5 block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white" + (errors.email ? " border-red-500" : "")} id="grid-email" type="text" placeholder="nombre@correo.com"
                                        name="email"
                                        ref={register({
                                            required: "Correo electrónico requerido",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Correo electrónico invalido"
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <p className="text-red-500 text-xs italic">
                                            {errors.email.message}
                                        </p>
                                    )}




                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <select name="title" ref={register({ required: true })} className="block  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="" >
                                        <option disabled value=""> Rol</option>
                                        <option value="Administrador">Administrador</option>
                                        <option value="Contable">Contable</option>
                                        <option value="Usuario">Usuario</option>
                                        <option value="Otros">Otros</option>
                                    </select>

                                    {errors.title && <p className="text-red-500 text-xs italic">Campo requerido</p>}
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6" >
                                    <select name="permission_cobru" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="">
                                        <option disabled value="">Permisos para Cobrar</option>
                                        <option value="0">No permitido</option>
                                        <option value="1">Puede ver</option>
                                        <option value="3">Puede ver y crear</option>
                                    </select>
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <select name="permission_withdraw" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="">
                                        <option defaultValue disabled value="">Permisos para Retirar</option>
                                        <option value="0">No permitido</option>
                                        <option value="1">Puede ver</option>
                                        <option value="3">Puede ver y crear</option>
                                    </select>
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6" >
                                    <select name="permission_create_colaborador" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" style={{ fontSize: "auto" }} defaultValue="">
                                        <option defaultValue disabled value="">Permisos para crear colaboradores</option>
                                        <option value="false">No</option>
                                        <option value="true">SI</option>
                                    </select>

                                </div>

                                <div className="flex flex-wrap mx-1 mb-6">
                                    <select name="permission_send" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="" >
                                        <option disabled value="">Permisos para enviar</option>
                                        <option value="0">No permitido</option>
                                        <option value="1">Puede ver</option>
                                        <option value="3">Puede ver y crear</option>
                                    </select>

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <select name="permission_users" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="" >
                                        <option disabled value="">Acceso a usuarios </option>

                                        <option value="false">NO</option>
                                        <option value="true">SI</option>
                                    </select>
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <select name="permission_query" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="">
                                        <option defaultValue disabled value="">Acceso a Consulta</option>
                                        <option value="false">NO</option>
                                        <option value="true">SI</option>
                                    </select>
                                </div>

                                <div className="flex flex-wrap mx-1 mb-6">
                                    <select name="permission_reports" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue="">
                                        <option defaultValue disabled value="">Acceso a reportes</option>
                                        <option value="false">NO</option>
                                        <option value="true">SI</option>
                                    </select>
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6 ">
                                    <input ref={register({ required: "Contraseña requerida!" })} name="password" autocomplete="new-password" className="px-0.5 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="password" placeholder="Contraseña" />
                                    {errors.password && (
                                        <p className="text-red-500 text-xs italic">{errors.password.message}</p>
                                    )}
                                </div>




                                <div style={{ display: "flex", width: "100%" }} >
                                    <button
                                        type="submit"
                                        className="flex-shrink-0 bg-green-600 hover:bg-green-600 border-green-600 hover:border-green-600 text-sm border-4 text-white py-2 px-4 rounded-full float-right button-outline-off" style={{ width: "100%" }}
                                    >
                                        Guardar
                                    </button>



                                </div>


                            </form>

                        </div>

                    </div>
                }
                {isHidden && modoEditar &&
                    <div className=" col-span-4 " >
                        <div className=" bg-white p-4 text-center overflow-x-auto mt-6" style={{ boxShadow: " 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%)" }}>

                            {modoEditar && <p style={{ fontSize: "27px", marginBottom: "23px" }}>Editar Colaborador</p>}
                            <form className="w-full" onSubmit={(e) => actulizarColaborador(e)}  >
                                <div className="flex flex-wrap mx-1 mb-6" >

                                    <label className="px-4" >Nombre</label>
                                    <input value={nombre} className={"px-0.5 block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 m leading-tight focus:outline-none focus:bg-white" + (errors.firstname ? " border-red-500" : "")} id="grid-first-name" type="text"
                                        onChange={(e) => setNombre(e.target.value)}
                                        name="firstname"
                                        ref={register({ required: true })}
                                    />

                                    {errors.firstname && <p className="text-red-500 text-xs italic">Campo requerido</p>}


                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Apellido</label>
                                    <input value={Apellido} className={"px-0.5 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" + (errors.lastname ? " border-red-500" : "")} id="grid-last-name" type="text" placeholder="Apellido"
                                        onChange={(e) => setApellido(e.target.value)}
                                        name="lastname"
                                        ref={register({ required: true })}
                                    />
                                    {errors.lastname && <p className="text-red-500 text-xs italic">Campo requerido</p>}

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6" >
                                    <label className="px-4" >Correo Electrónico</label>
                                    <input value={correo} className={"px-0.5 block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white" + (errors.email ? " border-red-500" : "")} id="grid-email" type="text" placeholder="nombre@correo.com"
                                        onChange={(e) => setCorreo(e.target.value)}
                                        name="email"
                                        ref={register({
                                            required: "Correo electrónico requerido",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Correo electrónico invalido"
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <p className="text-red-500 text-xs italic">
                                            {errors.email.message}
                                        </p>
                                    )}

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Rol</label>
                                    <select name="title" ref={register({ required: true })} className="block  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={rol}
                                        onChange={(e) => setRol(e.target.value)}>
                                        <option disabled value={rol}>{rol}</option>
                                        <option value="Administrador">Administrador</option>
                                        <option value="Contable">Contable</option>
                                        <option value="Usuario">Usuario</option>
                                        <option value="Otros">Otros</option>

                                    </select>

                                    {errors.title && <p className="text-red-500 text-xs italic">Campo requerido</p>}
                                </div>

                                <div className="flex flex-wrap mx-1 mb-6" >
                                    <label className="px-4" >Permisos para Cobrar</label>
                                    <select name="permission_cobru" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={permisosCobrar}
                                        onChange={(e) => setPermisosCobrar(e.target.value)}>
                                        <option disabled value={permisosCobrar}>{permisosCobrar == 0 ? "No permitido" : permisosCobrar == 1 ? "Puede ver" : "Puede ver y crear"}</option>
                                        <option value={0}>No permitido</option>
                                        <option value={1}>Puede ver</option>
                                        <option value={3}>Puede ver y crear</option>
                                    </select>
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Permisos para Retirar</label>
                                    <select name="permission_withdraw" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={permisosRetirar}
                                        onChange={(e) => setPermisosRetirar(e.target.value)}>
                                        <option disabled value={permisosRetirar}>{permisosRetirar == 0 ? "No permitido" : permisosRetirar == 1 ? "Puede ver" : "Puede ver y crear"}</option>
                                        <option value={0}>No permitido</option>
                                        <option value={1}>Puede ver</option>
                                        <option value={3}>Puede ver y crear</option>
                                    </select>
                                </div>

                                <div className="flex flex-wrap mx-1 mb-6" >
                                    <label className="px-4" >Permisos para crear colaboradores</label>
                                    <select name="permission_create_colaborador" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={permisosCrear}
                                        onChange={(e) => setCollaborators(e.target.value)}>
                                        <option disabled value={permisosCrear}>{permisosCrear == true ? "SI" : "NO"}</option>
                                        <option value="false">No</option>
                                        <option value="true">SI</option>
                                    </select>

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Permisos a usuario </label>
                                    <select name="permission_users" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={permisosUser}
                                        onChange={(e) => setpermisosUsers(e.target.value)}>
                                        <option disabled value={permisosUser}>{permisosUser == true ? "SI" : "NO"}</option>
                                        <option value="false">NO</option>
                                        <option value="true">SI</option>

                                    </select>

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Permisos para Enviar </label>
                                    <select name="permission_send" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={permisosEnviar}
                                        onChange={(e) => setPermisosEnviar(e.target.value)} >
                                        <option disabled value={permisosEnviar}>{permisosEnviar == 0 ? "No permitido" : permisosEnviar == 1 ? "Puede ver" : "Puede ver y crear"}</option>
                                        <option value={0}>No permitido</option>
                                        <option value={1}>Puede ver</option>
                                        <option value={3}>Puede ver y crear</option>
                                    </select>

                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Acceso a Consulta</label>
                                    <select name="permission_query" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-consult" defaultValue={AccesoConsultas}
                                        onChange={(e) => setAccesoConsultas(e.target.value)}>
                                        <option disabled value={AccesoConsultas}>{AccesoConsultas ? "SI" : "NO"}</option>
                                        <option value="false">NO</option>
                                        <option value="true">SI</option>
                                    </select>
                                </div>

                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Acceso a reportes</label>
                                    <select name="permission_reports" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-reports" defaultValue={AccesosReportes}
                                        onChange={(e) => setAccesoReportes(e.target.value)}>
                                        <option disabled value={AccesosReportes}>{AccesosReportes ? "SI" : "NO"}</option>
                                        <option value="false">NO</option>
                                        <option value="true">SI</option>
                                    </select>
                                </div>
                                <div className="flex flex-wrap mx-1 mb-6">
                                    <label className="px-4" >Estado del Colaborador </label>
                                    <select name="estado" ref={register} className="block px-0.5 w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={estado}
                                        onChange={(e) => setEstado(e.target.value)}>
                                        <option disabled value={estado}>{estado == true ? "Activo" : "Desactivado"}</option>
                                        <option value={true}>Activar</option>
                                        <option value={false}>Desactivar</option>
                                    </select>

                                </div>

                                {!modoEditar && <div style={{ display: "flex", width: "100%" }} >
                                    <button
                                        type="submit"
                                        className="flex-shrink-0 bg-green-600 hover:bg-green-600 border-green-600 hover:border-green-600 text-sm border-4 text-white py-2 px-4 rounded-full float-right" style={{ width: "100%" }}
                                    >
                                        Guardar
                                    </button>
                                </div>
                                }
                                {modoEditar && <div style={{ display: "grid", width: "100%" }} >
                                    <button
                                        type="submit"
                                        className="flex-shrink-0 bg-green-600 hover:bg-green-600 border-green-600 hover:border-green-600 text-sm border-4 text-white py-2 px-4 rounded-full float-right button-outline-off" style={{ width: "100%" }}
                                        onClick={(e) => actulizarColaborador(e)}
                                    >
                                        Actulizar
                                    </button>
                                    <button

                                        type="submit"
                                        className="flex-shrink-0 bg-white-600 hover:bg-white-600 border-green-600 hover:border-white-600 text-sm border-2 text-green py-2 px-4 rounded-full float-right button-outline-off"
                                        style={{ width: "100%", marginTop: "10px" }}

                                        onClick={(e) => volver(e)}
                                    >
                                        ir a Agregar
                                    </button>



                                </div>
                                }
                            </form>

                        </div>

                    </div>
                }

                <div className="col-span-8 w-full bg-white p-4 text-center overflow-x-auto mt-6 " style={{ height: "fit-content", boxShadow: "  rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px" }}>
                    <DataTable
                        title="Colaboradores Agregados"
                        className="h-full"
                        data={collaborators}
                        columns={columns}

                        selectableRowsHighlight={true}
                        fixedHeader={true}
                        progressPending={pending}
                        noDataComponent={"No has agregado usuarios a tu cuenta, agrega usuarios y asignales permisos para que colaboren contigo."}

                        progressComponent={<Circular />}




                    />
                </div>
                {/* <table className="shadow-lg bg-white w-full" >
                        <thead>
                            <tr className="text-left bg-white text-black text-xs bg-white text-black" >
                                <th className="bg-white border text-black text-left px-2 py-2" >NOMBRE</th>
                                <th className="bg-white border text-black text-left px-2 py-1" >CORREO 	</th>
                                <th className="bg-white border text-black text-left px-2 py-1" >ROL</th>
                                <th className="bg-white border text-black text-left px-2 py-1" >ESTADO</th>
                            </tr>
                        </thead>
                        <tbody className="text-sm font-normal text-gray-700">
                            {collaborators.length > 0 ?
                                collaborators.map((collaborator, index) => (

                                    <tr onClick={() => setModoEditar(true)} key={index}>

                                        <td className="border text-gray-700 px-2 py-1 text-left" >{collaborator.first_name + " " + collaborator.last_name}</td>
                                        <td className="border text-gray-700 px-2 py-1 text-left" >{collaborator.email}</td>
                                        <td className="border text-gray-700 px-2 py-1 text-left" >{collaborator.title}</td>
                                        <td className="border text-gray-700 px-2 py-1 text-left" >{collaborator.active === true ? 'ACTIVO' : 'DESACTIVADO'}</td>
                                    </tr>

                                )) :
                                <tr><td className="border text-gray-700 px-8 py-4"></td></tr>
                            }
                        </tbody>
                    </table> */}

            </div>

        </>
    ) : (
        <NoView />
    )

}

export default Colaboradores;