import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
export default function ModalConfirmar(props) {


    const [browserName, setBrowserName] = useState("");
    const [showModal, setShowModal] = useState(true);
    const [sistemaOP, setSistemaOP] = useState("");
    const title = "Retiro solicitado";
    const userIp = props.info.userIp
    const today = new Date();
    var ampm = today.getHours() >= 12 ? 'pm' : 'am';
    const comision_retiro = props.info.fee;
    const comision_iva_retiro = props.info.fee_iva;
    const gmf = props.info.gmf;
    const payed_amount = props.info.payed_amount;
    const amount = props.info.amount;
    useEffect(() => {
        getBrowserInfo()
    }, [])
    const handle = (e) => {

        props.confirmar(e);
    }
    const getBrowserInfo = async () => {

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            setBrowserName('Opera');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            setBrowserName('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            setBrowserName('Safari');
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            setBrowserName('Firefox');
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
            setBrowserName('IE');
        } else {
            setBrowserName('Unknown');
        }

        if (navigator.appVersion.indexOf("Win") != -1) {
            setSistemaOP("Windows")
        }
        else if (navigator.appVersion.indexOf("Mac") != -1) {
            setSistemaOP("MacOS")
        }
        else if (navigator.appVersion.indexOf("X11") != -1) {
            setSistemaOP("UNIX")
        }
        else if (navigator.appVersion.indexOf("Linux") != -1) {
            setSistemaOP("Linux")
        }
        else if (navigator.appVersion.indexOf("Android") != -1) { setShowModal("Android") }
        else {
            setShowModal('Unknown')
        }
    }
    function FormatCOPNumber(numbers, commas = false) {
        try {
            let number = Math.round(numbers);
            //algunos inputs de la aplicacion envian textos con "$" para formatearlos,
            //pero estos no se les puede hacer parse int. Por eso lo quitamos si no funciona.
            if (number.toString().includes("$")) {
                number = number.toString().replace(/\D/g, "");
            }
            //si number esta vacio en este momento devolvemos "$0"
            if (!number) {
                return "$0"
            }

            //esta linea mira si debemos agregar un signo negativo al numero cuando es menor a 0
            let prefix = parseInt(number) >= 0 ? "$" : "-$";
            if (!commas) {
                return prefix + numeral(number.toString().replace(/\D/g, "")).format('0,0').replace(/,/g, '.');
            }
            else {
                return prefix + numeral(number).format('0,0');
            }
        }
        catch (error) {

            return "";

        }
    }
    function xd(n) {
        var entero = Math.round(n);


        return entero;

    }

    const date =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear() +
        " - " +
        today.getHours() +
        ":" +
        today.getMinutes() +
        " " +
        ampm;

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-pop-up my-6 mx-auto max-w-md">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => props.modal(false)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">
                                    <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                                        {title}
                                    </h3>

                                    <div className="my-4 py-2 mb-1  text-lg leading-relaxed border border-solid text-gray-700 rounded">
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Fecha y hora
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {date}
                                            </p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Monto a debitar
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {(FormatCOPNumber(payed_amount))}
                                            </p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Monto a transferir
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {FormatCOPNumber(amount)}
                                            </p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Comisión retiro
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {FormatCOPNumber(comision_retiro)}
                                            </p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                IVA comisión retiro
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {FormatCOPNumber(comision_iva_retiro)}
                                            </p>
                                        </div>
                                        <div className="flex items-center px-1 leading-7">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                GMF
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {FormatCOPNumber(gmf)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-2xl centrar-contenido">
                                    <button
                                        style={{ width: "350px" }}
                                        className="  flex-shrink-0 bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off"
                                        onClick={(e) => handle(e)}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                                <div className="items-center justify-end p-2 border-gray-300 rounded-b">
                                    <p className="text-gray-700 text-center text-xs font-hairline">
                                        {userIp} -{browserName} {sistemaOP}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            ) : null}
        </>
    );
}
