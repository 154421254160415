import React, { useState, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
//import { getAuthenticatedHeaders } from '../common/functions';
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
import Banner from "../../components/Banner";
import { Button, CircularProgress } from "@material-ui/core";
import { FaSearch } from "react-icons/fa"
import AxioInstance from '../../axios/AxiosConfig';


const Consultar = (props) => {

    const [ref, setRef] = useState('');
    const [retiros, setRetiros] = useState(null);
    const [info, setInfo] = useState([]);
    const [msn, setMsn] = useState('');
    const [error, setError] = useState(true);
    const [loading, setLoading] = useState(false)
    const [Activatebanner, setActivatebanner] = useState(false);

    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)
    //const canViewQuery = props.canViewQuery;
    const canViewQuery = permissions.permission_query;


    const handleQuery = async () => {
        setRetiros(null)
        setLoading(true)
        setInfo([])
        if (isNaN(ref)) {


            try {
                //let url = process.env.REACT_APP_API_URL + ref;

                let url = ref;
                const response = await AxioInstance.post(`/api/consultar/`,
                    { url },
                    {
                        headers: await getAuthenticatedHeaders(),
                    });


                if (response.data.status === 200) {
                    let data = response.data.data["cobru"]
                    if (data !== undefined) {
                        data = JSON.parse(response.data.data["cobru"]);
                        if (data[0].fields.state === 3 || data[0].fields.state === 1 || data[0].fields.state === 5) {
                            setInfo(data);
                            setRetiros(false)

                            setMsn("");
                        } else {
                            setActivatebanner(true)
                            setError(true)
                            setMsn("Este cobru no ha sido procesado o pagado!")
                        }
                    } else {
                        setActivatebanner(true)
                        setError(true)
                        setMsn("Este cobru no existe")
                    }

                } else {
                    setActivatebanner(true)
                    setError(true)
                    setMsn("error..")
                }
                setLoading(false)

            } catch (error) {
                setLoading(true)

            }

        } else {
            const response = await AxioInstance.post(`/api/list/withdraw/search`, {
                headers: await getAuthenticatedHeaders(),
                withdraw_id: ref,

            });

            //-------

            /*             const response = await AxioInstance.post(`/api/thirdpartywithdraw`, {
                            headers: await getAuthenticatedHeaders(),
                            pageNum: 1,
                            perPage: 10,
                            document_number: "",
                            withdraw_id: ref,
                            state: null
            
                        }); */
            const withdraws = response?.data?.data?.data?.data;
            console.log("🚀 ~ handleQuery ~ withdraws:", withdraws)

            if (withdraws?.length > 0) {
                setInfo(withdraws);
                setRetiros(true)
            } else {
                setActivatebanner(true)
                setError(true)
                setMsn("No hay resultados")
            }


        }

        setLoading(false)


    }

    const resendCallbackCobru = async (url, e) => {


        try {
            const response = await AxioInstance.post(`/api/resend_callback/`, {
                url,
                headers: await getAuthenticatedHeaders(),
            }
            );
            if (response.data.status === 200) {

                if (response.data.data.message === "Pendiente") {
                    setActivatebanner(true)
                    setError(false)
                    setMsn("El cobru todavia esta pendiente al pago, pero se reenvio el callback.")
                } else {
                    setActivatebanner(true)
                    setError(false)
                    setMsn("Ya se hizo el reenvio del callback")
                }
            } else {
                setActivatebanner(true)
                setError(true)
                setMsn("Algo salio mal, intentalo de nuevo")
            }

        } catch (error) {

        }
    }
    const SendCallback = async (id) => {


        try {
            const response = await AxioInstance.post(`/api/resend_callback_retiros/`, {
                id,
                headers: await getAuthenticatedHeaders(),
            }
            );
            if (response.data.status === 200) {

                if (response.data.data.message === "Pendiente") {
                    setActivatebanner(true)
                    setError(false)
                    setMsn("El cobru todavia esta pendiente al pago, pero se reenvio el callback.")
                } else {
                    setActivatebanner(true)
                    setError(false)
                    setMsn("Ya se hizo el reenvio del callback")
                }
            } else {
                setActivatebanner(true)
                setError(true)
                setMsn("Algo salio mal, intentalo de nuevo")
            }

        } catch (error) {

        }
    }

    function pulsar(e) {

        if (e.key === "Enter") {

            handleQuery()
        }
    }
    const Circular = () => (
        <div className="flex items-center justify-center my-2">
            {
                <CircularProgress size={75} />
            }
        </div>
    )
    return canViewQuery ? (
        <>
            {Activatebanner &&
                (
                    <div className="p-5">
                        <Banner
                            title={msn}
                            error={error}
                            description={msn}
                            setShowBanner={setActivatebanner}
                        />
                    </div>
                )
            }
            <div className="flex md:flex-row flex-wrap p-4 justify-end ">
                <div className="w-full bg-white text-center flex justify-start">
                    <div className="max-w-sm rounded overflow-hidden mt-10 w-1/2 pr-12">

                        <div className="px-2 py-4">
                            <p className="text-gray-700 text-sm">
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10 w-full  text-base focus:outline-none' id="ref" type="text" placeholder="URL del Cobro o REF del retiro" onKeyPress={(e) => pulsar(e)} value={ref} onChange={event => setRef(event.target.value)} />
                                    <button className='focus:outline-none' style={{ marginLeft: -30, }} > <FaSearch color={'#a0aec0'} size={20} onClick={handleQuery} /></button>
                                </div>
                                {/*<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="ref" type="text" placeholder="URL del Cobru o id del retiro" value={ref} onChange={event => setRef(event.target.value)}> </input>
 */}                            </p>
                        </div>
                    </div>
                </div>
                {/*  <div className="w-full md:w-3/6 bg-white text-center ">
                    <div className="max-w-sm rounded overflow-hidden mt-10">
                        <div className="px-2 py-4">
                            <div className="font-bold text-xl mb-2 text-black">
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="flex md:flex-row flex-wrap p-4">
                <div className="w-full">
                    <div className="overflow-x-auto bg-white rounded-lg shadow relative">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left bg-white text-black text-xs bg-white text-black">
                                    <th className="py-2 px-8 sticky top-0 border-b border-gray-200 bg-white text-black text-xs">REF</th>
                                    <th className="py-2 px-8 sticky top-0 border-b border-gray-200 bg-white text-black text-xs">NOMBRE</th>
                                    <th className="py-2 px-8 sticky top-0 border-b border-gray-200 bg-white text-black text-xs">DOCUMENTO</th>
                                    <th className="py-2 px-8 sticky top-0 border-b border-gray-200 bg-white text-black text-xs">MÉTODO</th>
                                    <th className="py-2 px-8 sticky top-0 border-b border-gray-200 bg-white text-black text-xs">FECHA</th>
                                    <th className="py-2 px-8 sticky top-0 border-b border-gray-200 bg-white text-black text-xs">ACCIÓN</th>
                                </tr>
                            </thead>
                            <tbody className="text-sm font-normal text-gray-700">


                                {info.length > 0 && retiros === false &&
                                    <tr className="text-xs">
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].fields.reference_cobru}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].fields.payer_name ? info[0].fields.payer_name : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].fields.payer_id_type + " " + info[0].fields.payer_id ? info[0].fields.payer_id : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].fields.payment_method ? info[0].fields.payment_method : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].fields.date_payed ? moment(info[0].fields.date_payed).format('YYYY-MM-DD HH:mm:ss A') : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">
                                            <button className="bg-green-600 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full text-sm" onClick={(e) => resendCallbackCobru(info[0].fields.confirmation_url, e)}
                                            > Enviar Callback</button>
                                        </td>
                                    </tr>

                                }
                                {info.length > 0 && retiros &&
                                    <tr className="text-xs">
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].pk}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].owner_first_name ? info[0].owner_last_name : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].account_holder_document ? info[0].account_holder_document : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].account_bank ? info[0].account_bank : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">{info[0].date_created ? moment(info[0].date_created[0]).format('YYYY-MM-DD HH:mm:ss A') : ""}</td>
                                        <td className="border-dashed border-t border-gray-700 px-8 p-1">
                                            <button className="bg-green-600 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full text-sm" onClick={(e) => retiros ? SendCallback(info[0].pk) : resendCallbackCobru(info[0].fields.confirmation_url, e)}
                                            > Enviar Callback</button>
                                        </td>
                                    </tr>
                                }


                            </tbody>
                        </table>
                    </div>
                    {
                        loading
                            ?
                            <Circular />
                            :
                            false
                    }
                </div>
            </div>
        </>
    ) : (
        <NoView />
    )
}

export default Consultar;