import React, { useState, useContext, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { FormatAmount, localeDataPicker } from '../../common/functions';
import axios from 'axios';
import Banner from '../../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../../layouts/NoView';
import { AuthContext } from '../../../context/AuthContext';
import Dropzone from 'react-dropzone';
import { /* token, */ isEmpty } from '../../common/functions';
import File from '../../../assets/files/format_reload.xlsx';

import AxioInstance from '../../../axios/AxiosConfig';
import { CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { FunctionContext } from '../../../context/FuntionsContext';
import variablesDomain from '../../../helpers/variablesDomain';

//import { getAuthenticatedHeaders} from '../../common/functions';
const filterSwaps = { pending: 'PENDING', approved: 'APPROVED', failed: 'FAILED' }
const RecargasMutu = (props) => {

    const DEV = localStorage.getItem('DEV');

    const getPermissions = localStorage.getItem('userDetails')
    const [msg, setMsg] = useState("")
    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [data, setData] = useState([]);

    const [pending, setPending] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState("transactions");
    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));
    const ranges = {
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    const locale = localeDataPicker;
    const maxDate = moment();
    const [label, setLabel] = useState("");
    const csvLink = React.useRef();

    const { getAuthenticatedHeaders, permissions, getAccessToken } = useContext(AuthContext)
    const { reloadRetiros, ReloadSwaps, buscar, buscarDocument, optionSearchClient } = useContext(FunctionContext)
    //const canViewReport = props.canViewReport;
    const canViewReport = permissions.permission_reports;

    const [page, setPage] = useState(1)
    const [newPerPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [listWithdraw, setListWithdraw] = useState([]);
    const [pagination, setPagination] = useState(true);
    const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [numFilas, setNumFilas] = useState([]);

    const [activeFilter, setActiveFilter] = useState(null)

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);



    const handleClickDowloaded = (DATA) => {

        const contenidoCSV = DATA;

        const blob = new Blob([contenidoCSV], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const enlaceDescarga = document.createElement('a');
        enlaceDescarga.href = url;
        enlaceDescarga.download = 'archivo.csv';

        document.body.appendChild(enlaceDescarga);
        enlaceDescarga.click();
        document.body.removeChild(enlaceDescarga);

        URL.revokeObjectURL(url);


    }

    const callApiReport = async (start_date, end_date, type) => {
        setIsLoading(true);
        AxioInstance.post(`/api/swap_download_pending/`, { start_date, end_date, type, headers: await getAuthenticatedHeaders(), })
            .then(response => {

                if (response.data.status === 200) {
                    if (response.data.statusText === "OK") {
                        setErrorBanner(false)
                        setMsg("Mira tu bandeja de descargas")
                        setShowBanner(true)

                        handleClickDowloaded(File)
                    }

                    /*  setData(response.data.data);
                     csvLink.current.link.click(); */
                    setIsLoading(false);
                }

            }).catch(error => setIsLoading(false), setError(error));

    }

    const onDrop = async (acceptedFiles) => {

        if (!isEmpty(acceptedFiles)) {

            const DEV = localStorage.getItem('DEV');

            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            formData.append('typeTask', 'reload')

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
                'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

            };

            axios.post((DEV) + 'panel/', formData, { headers }, {
            }).then(res => {
                console.log("🚀 ~ file: RecargasMutu.js:140 ~ axios.post ~ res:", res)
                if (res.status === 200 || res.data.result == "OK") {
                    setErrorBanner(false)
                    setMsg("Se subio el archivo")
                    setShowBanner(true)
                } else {
                    setErrorBanner(true)
                    setMsg("No se pudo subir el archivo")
                    setShowBanner(true)
                }

            }).catch((e) => {
                console.log("🚀 ~ file: RecargasMutu.js:151 ~ axios.post ~ e:", e)
                setErrorBanner(true)
                setMsg("Ups, hubo un problema")
                setShowBanner(true)
            })
        }
    }

    const getListMovements = async (numPage = 1, paramsReq = {}) => {
        setPending(true);

        try {
            const params = {
                headers: await getAuthenticatedHeaders(),
                pageNum: numPage,
                perPage: newPerPage,
                mutu: 'True',
                ...paramsReq
            }
            const response = await AxioInstance.post(`/api/mutu_reloads`, params);


            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)
                const pendings = response.data.data

                let final = !pendings ? [] : pendings
                setListWithdraw(final);
                setListWithdrawFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPending(true);
        try {
            const response = await AxioInstance.post(`/api/mutu_reloads`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: newPerPage,
                mutu: 'True',
                filter: activeFilter
            });
            if (response.data.status === 200) {
                const pendings = response.data.data
                let final = !pendings ? [] : pendings
                setPerPage(newPerPage)
                setListWithdraw(final);
                setListWithdrawFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }
    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                pending &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );

    const formatStatus = (status) => {
        let newFormatStatus = ''
        switch (status) {
            case filterSwaps.approved:
                newFormatStatus = 'Aprobado'
                break;
            case filterSwaps.pending:
                newFormatStatus = 'Pendiente'
                break;
            case filterSwaps.failed:
                newFormatStatus = 'Rechazada'
                break;
            default:
                newFormatStatus = ''
                break;
        }
        return newFormatStatus
    }
    function quitarPrefijo(numero) {
        // Utilizamos una expresión regular para encontrar y eliminar el prefijo
        // El patrón busca una cadena seguida por un guion y luego captura el resto
        const patron = /^.*?-(.*)$/;
        const resultado = numero?.replace(patron, '$1');

        return resultado;
    }
    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'Color-red'
        } else if (state === 3) {
            color = 'bg-green-500'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        }
        return color
    }
    const columns = [{
        name: 'REF',

        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                <div
                    title={row.state === 1
                        ? "Pendiente"
                        : row.state === 2
                            ? "Rechazado"
                            : row.state === 3
                                ? "Aprobado"
                                : ""
                    }
                    className={changeBgColor(row.state) + ' rounded-full h-4 w-4 bg-green mr-3'}
                >

                </div>
                <div
                    style={{ fontWeight: 700 }}>
                    <p className={!!row.payer_redirect_url ? "links cursor-pointer" : "notlinks"} onClick={() => {/* AbrirModal(row.payer_redirect_url) */ }}>
                        {row.pk}
                    </p>
                </div>
            </div>),

        sortable: true,
    },
    {
        name: 'Nombre',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{row.name}</div>
        )
    },
    {
        name: 'User',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div >
                <a target='_blank' href={`${DEV}admin/api/profile/?q=${row.owner}`}>
                    {quitarPrefijo(row.owner)}
                </a>
            </div>
        )
    },
    {
        name: 'Banco',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (


            <p >{row.bank_withdraw}</p>
        )
    },
    {
        name: 'Moneda',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (


            <p >{row.currency_code}</p>
        )
    },
    {
        name: 'Monto',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{FormatAmount(row.amount)}</div>

        )
    },

    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */

    {
        name: 'Fecha',
        cell: row => (
            <div>{moment(row.date_created).format('YYYY-MM-DD HH:mm:ss A')}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },




    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    const getSearch = async () => {
        if (buscarDocument?.length !== 0) {

            setPending(true);
            let paramsReq = {}
            //<option value={2}>Documento</option>
            //<option value={3}>Usuario</option>

            if (parseInt(optionSearchClient) === 2) {
                paramsReq.reload_id = buscarDocument
            }
            if (parseInt(optionSearchClient) === 3) {
                paramsReq.document_number = buscarDocument
            }
            //buscarusuario
            if (parseInt(optionSearchClient) === 4) {
                paramsReq.username = 'MUTUAL-'+buscarDocument
            }
            setToggledClearRows(!toggledClearRows)
            getListMovements(1, paramsReq)

        } else {
            setToggledClearRows(!toggledClearRows)
            getListMovements(1)
        }
    }
    useEffect(() => {
        getSearch()
    }, [buscar]);
    useEffect(() => {
        setToggledClearRows(!toggledClearRows)
        getListMovements(1);
    }, [ReloadSwaps, activeFilter])

    useEffect(() => {
        lasfilas()
    }, [totalRows]);


    return canViewReport ? (
        <>
            {showBanner && <Banner
                title={'Proceso realizado'}
                description={msg}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}
            <div className="flex w-full justify-between mb-4" style={{ background: "#f6f5f6" }}>
                <div className="flex md:flex-row  mr-4 bg-white p-4 w-full items-center justify-center">
                    <div className="font-bold text-xl mb-2 text-black  text-center">
                        <p>Descargar aquí plantilla de Recargas</p>
                        <a href={File}>
                            <button className={`mt-2 bg-${variablesDomain.classNameColor} text-white font-bold py-2 px-4 rounded text-sm`} onClick={() => {/* callApiReport() */ }} disabled={isLoading}>{(isLoading) ? 'Generando...' : 'Descargar'}</button>
                        </a>
                    </div>
                </div>
                <div className="flex md:flex-row w-full   bg-white p-4 justify-center items-center">
                    <div className="mt- border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 w-10/12 justify-center items-center flex  cursor-pointer h-full"
                        style={{ height: "200px" }}
                    >
                        <Dropzone
                            onDrop={onDrop}
                            className="w-10/12"
                            accept={[".csv", ".xlsx"]}
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                <div {...getRootProps()} >
                                    <input {...getInputProps()} />
                                    {!isDragActive && '¡Haga clic aquí o arrastre un archivo csv para cargarlo!!'}
                                    {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                    <ul className="list-group mt-2">
                                        {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                            <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                {acceptedFile.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                            )}
                        </Dropzone>
                        {isLoading &&
                            <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </div>
                </div>

            </div>
            {/*  <div className='flex flex-row text-xs p-1 mb-5 overflow-auto'>
                <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter === null ? 'bg-green-600' : 'bg-gray-500')} onClick={() => { setActiveFilter(null) }} key="TODOS" >
                    <span>Todos</span>
                </button>
                <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter === filterSwaps.pending ? 'bg-green-600' : 'bg-gray-500')} onClick={() => { setActiveFilter(filterSwaps.pending) }} key="PENDIENTES" >
                    <span>Pendientes</span>
                </button>
                <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter === filterSwaps.approved ? 'bg-green-600' : 'bg-gray-500')} onClick={() => { setActiveFilter(filterSwaps.approved) }} key="APROBADOS" >
                    <span>Aprobados</span>
                </button>
                <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter === filterSwaps.failed ? 'bg-green-600' : 'bg-gray-500')} onClick={() => { setActiveFilter(filterSwaps.failed) }} key="RECHADOS" >
                    <span>Rechazados</span>
                </button>
            </div> */}
            <DataTable
                title={``}
                noHeader={true}
                clearSelectedRows={toggledClearRows}
                paginationResetDefaultPage={toggledClearRows}
                data={listWithdrawFilter}
                columns={columns}
                selectableRowsHighlight={true}
                fixedHeader={true}
                noDataComponent={"No hay movimientos disponibles"}
                pagination={true}
                paginationComponentOptions={paginationOptions}
                progressPending={pending}
                progressComponent={<Circular />}
                paginationTotalRows={totalRows}
                paginationPerPage={newPerPage}
                paginationServer
                paginationRowsPerPageOptions={numFilas}
                onChangePage={getListMovements}
                onChangeRowsPerPage={handlePerRowsChange}
            />
        </>
    ) : (
        <NoView />
    )

}

export default RecargasMutu;

