import numeral from "numeral";

export const FORMAT =
{
    FormatPhoneNumber(text) {

        // let t = text.replace(/\D/g,""); //quitamos los caracteres que no sean digitos

        // t = t.substring(0, 11);// el maximo de digitos para el numero de telefono es 12
        // t = t.replace(/^(\d{3})/g, "("+t.substring(0,3)+")" ); //agregamos los parentesis. 
        // t = t.replace(/\)(\d{3})/g, ") "+t.substring(5,8)+"-" ); //agregamos un espacio y el primer guion

        let t = text.toString().replace(/^(\d{3})(\d{3})(\d{}).*/, '($1) $2-$3'); //quitamos los caracteres que no sean digitos

        t = t.substring(0, 14);// el maximo de digitos para el numero de telefono es 12
        t = t.replace(/^(\d{3})/g, "(" + t.substring(0, 3) + ")"); //agregamos los parentesis. 
        t = t.replace(/\)(\d{3})/g, ") " + t.substring(5, 8) + "-"); //agregamos un espacio y el primer guion

        return t

    },

    FormatCOPNumber(number, commas = false) {
        try {
            //algunos inputs de la aplicacion envian textos con "$" para formatearlos, 
            //pero estos no se les puede hacer parse int. Por eso lo quitamos si no funciona. 
            if (number.toString().includes("$")) {
                number = number.toString().replace(/\D/g, "");
            }
            //si number esta vacio en este momento devolvemos "$0"
            if (!number) {
                return "$0"
            }

            //esta linea mira si debemos agregar un signo negativo al numero cuando es menor a 0
            let prefix = parseInt(number) >= 0 ? "$" : "-$";
            if (!commas) {
                return prefix + numeral(number.toString().replace(/\D/g, "")).format('0,0').replace(/,/g, '.');
            }
            else {
                return prefix + numeral(number).format('0,0');
            }

        }
        catch (error) {
            return "";
        }
    },

    GetDigits(text) {
        return text.toString().replace(/\D/g, "");
    },

    GetDigitsNumeric(text) {
        return parseInt(text.toString().replace(/\D/g, ""));
    },

    FormatCurrencyNumber(number) {
        return numeral(number).format('0,0').replace(/\,/g, ".");
    },

    FormatTvNumber(number) {

        var l = number.slice(-1);
        if (l == ")" || l == "-") return number.slice(0, -1);

        var t = number.replace(/\D/g, ""); //quitamos los caracteres que no sean digitos

        t = t.substring(0, 12);// el maximo de digitos para el numero de telefono es 12
        t = t.replace(/^(\d{4})/g, "(" + t.substring(0, 4) + ")"); //agregamos los parentesis. 
        t = t.replace(/\)(\d{4})/g, ") " + t.substring(6, 10) + "-"); //agregamos un espacio y el primer guion

        return t;
    },
    FormatDocument(num) {
        try {
            var num = num.replace(/\./g, '');
            if (!isNaN(num)) {
                num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
                num = num.split('').reverse().join('').replace(/^[\.]/, '');
                return num;
            }

            else {
                return "";
            }
        }
        catch (error) {
            return "";
        }
    },
    GetDigitsRegister(text, isDataNumeric = false) {
        return isDataNumeric ? parseInt(text.toString().replace(/\D/g, "")) : text.toString().replace(/\D/g, "");
    },


}