import React, { useContext, } from 'react'
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';

// views
import Resumen from '../../containers/pages/Resumen';
import Cobrar from '../../containers/pages/Cobrar';
import Retirar from '../../containers/pages/Retirar';
import Reporte from '../../containers/pages/Reporte';
import Colaboradores from '../../containers/pages/Colaboradores';
import Ayuda from '../../containers/pages/Ayuda';
import Integracion from '../../containers/pages/Integracion';
import Cobrus from '../../containers/pages/Cobrus';
import Recarga from "../../containers/pages/Recarga"
import Documentos from "../../containers/pages/Documentos"
import Sidebar from '../Sidebar/Sidebar';
import Nav from '../NavigationBar';

import Operacion from '../../containers/pages/Operacion';
import Retiros from '../../containers/pages/Retiros';
import { AuthContext } from '../../context/AuthContext';
import Alertas from '../../containers/pages/Alertas';


const NoMatch = () => {
    let location = useLocation();
    return (
        <div className="flex md:flex-row flex-wrap p-4">
            <div className="w-full bg-white p-4 text-center ">
                No encontramos nada para <code>{location.pathname}</code>
            </div>
        </div>
    );
}

const Other = (props) => {

    const { isSuperUser } = useContext(AuthContext)
    const user = props.user;
    const pathname = props.pathname;
    const cobrus = props.cobrus;
    const totalBalance = props.totalBalance;
    const isCanViewDashboard = props.user.mother_account ? props.user.permission_dashboard : true;
    const isCanViewCobrus = props.user.mother_account ? props.user.permission_cobru : 3;
    const isCanViewWithdraw = props.user.mother_account ? props.user.permission_withdraw : 3;
    const isCanViewQuery = props.user.mother_account ? props.user.permission_query : true;
    const isCanViewReport = props.user.mother_account ? props.user.permission_reports : true;
    const isCanViewUser = props.user.mother_account ? props.user.permission_users : true;
    const pendingTasks = props.pendingTasks;


    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/15 bg-trixel-sidebar">
                    <Sidebar user={user} pathname={pathname} superUser={props.isSuperUser} />
                </div>
                <div className="w-full md:w-1/84 bg-trixel-content p-2">
                    <Nav user={user} pathname={pathname} />
                    <Switch>
                        <Route exact path="/resumen" component={() => <Redirect to="/dashboard" />} />
                        <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
                        <Route path="/dashboard" component={Operacion} />
                        <Route path="/alertas" component={Alertas} />
                        {/*  <Route path="/otros" component={Alertas} /> */}
                        <Route path="/cobrus"  >
                            <Cobrus user={user} />
                        </Route>
                        <Route path="/retiros"  >
                            <Retiros user={user} />
                        </Route>
                        <Route path="/recargas" >
                            <Recarga user={user} />
                        </Route>

                        {/* <Route exact path="/" component={() => <Resumen cobrus={cobrus} totalBalance={totalBalance} canViewDashboard={isCanViewDashboard} />} /> */}
                        <Route path="/documentos" component={() => <Documentos user={user} />} />
                        <Route path="*" component={() => NoMatch} />
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default Other;
