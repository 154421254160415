import React from 'react'

function Button (props) {

    const {disabled, sendProcessBackend, loading } = props
        
    return (
        <button className={"focus:outline-none bg-green-600 hover:bg-green-600 text-white text-base font-bold py-1 px-4 rounded-full flex " + (disabled &&  "opacity-50 cursor-not-allowed")} onClick={sendProcessBackend}>      
            {loading ? '...':'Enviar'}
        </button>
    )
    
}


export default Button;

