import React, { useState, createContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import Numeral from "numeral";
import axios from 'axios';
import { getAuthenticatedHeaders } from '../containers/common/functions';
import AxioInstance from '../axios/AxiosConfig';

const domain = window.location.hostname;
const mutuDomain='panel.mutuahorro.com'

export const AuthContext = createContext()

const Provider = ({ children }) => {
  const [isSuperUser, setIsSuperUser] = useState(false)

  const [others, setOthers] = useState(false)
  const [mutual, setMutual] = useState(false)
  const [pendingRecargas, setPendingRecargas] = useState(0)
  const [LoadingLogin, setLoadingLogin] = useState(true)
  const [LevelUser, setLevelUser] = useState(null)
  const [isOperador, setIsOperador] = useState(null)
  const [isValidator, setIsValidator] = useState(null)
  const [IsDev, setIsDev] = useState(false);
  const [userDetailsContext, setUserDetailsContext] = useState({})
  const [is_changed_password, set_is_changed_password] = useState(false)

  const [isAuth, setIsAuth] = useState(() => {

    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false

    }
  })
  /**
   *hola las variables bolsabaja tiene 4 estados 
  0 = las bolsas estan okey
  1 = la bolsa de efecty esta baja 
  2 = la bolsa de baloto esta baja 
  3 = las dos bolsas estan bajas
   */

  const logOut = () => {

    localStorage.clear()
    setIsAuth(false)
    return <Redirect to="/login" />;
  }

  const okey = async () => { }

  const [montoBolsaEfecty, setMontoBolsaEfecty] = useState({})
  const [montoBolsaBaloto, setMontoBolsaBaloto] = useState({})


  const [permissions, setPermisions] = useState({
    permission_cobru: null,
    permission_dashboard: null,
    permission_query: null,
    permission_reports: null,
    permission_users: null,
    permission_withdraw: null

  })
  const checkLevel = async () => {
    const response = await AxioInstance.post('/api/level/', {
      headers: await getAuthenticatedHeaders(),
    })
    if (response.data.status === 201) {
      setLevelUser(response.data.data.level);

    } else {
      setLevelUser(null);
    }
  }



  useEffect(() => {
    checkPermissions()
    checkLevel()
  }, [])

  const checkPermissions = async () => {

    try {
      let newObj = {}
      const getPermissions = localStorage.getItem('userDetails')

      if (getPermissions) {


        newObj = {
          permission_cobru: JSON.parse(getPermissions).mother_account ? JSON.parse(getPermissions).permission_cobru : 3,
          permission_dashboard: JSON.parse(getPermissions).mother_account ? JSON.parse(getPermissions).permission_dashboard : true,
          permission_query: JSON.parse(getPermissions).mother_account ? JSON.parse(getPermissions).permission_query : true,
          permission_reports: JSON.parse(getPermissions).mother_account ? JSON.parse(getPermissions).permission_reports : true,
          permission_users: JSON.parse(getPermissions).mother_account ? JSON.parse(getPermissions).permission_users : true,
          permission_withdraw: JSON.parse(getPermissions).mother_account ? JSON.parse(getPermissions).permission_withdraw : 3,
          permission_collaborators: JSON.parse(getPermissions).mother_account == undefined ? true : false,
        }
        setPermisions(newObj)
      }
    } catch (error) {

    }
  }

  const checkSuperUser = async () => {
    try {
      const response = await AxioInstance.post('/api/check_user/', {
        headers: await getAuthenticatedHeaders(),
      })


      if (response.data.status === 200) {
        
      if (response.data.data.roll_user[0] != "mutual"&&domain===mutuDomain){
         logOut()
        }
        
        setMutual(response.data.data.roll_user[0] == "mutual" ? true : false)
        setIsOperador(response.data.data.roll_user[0] == "operators" ? true : false)
        setIsValidator(response.data.data.roll_user[0] == "validators" ? true : false)
        setOthers(response.data.data.roll_user[0] == "asistente_comercial" || response.data.data.roll_user[0] == "comercial" ? true : false)
        setIsSuperUser(response.data.data.is_superuser)
      } else {
        setIsSuperUser(false)
      }

    } catch (error) {
      setIsSuperUser(false)

    }
    setLoadingLogin(false)
  }

  /* const getAccessToken = async () => {


    let currentTime = new Date();
    let token = localStorage.getItem('token')
    let token_created = localStorage.getItem('expiresAtC');
    let minutes_since = DifferenceMinutes(currentTime, new Date(Date.parse(token_created)));


    if (minutes_since > 4) {

    } else {
      if (token) {
        setIsAuth(true)
        return token
      } else {
          localStorage.clear()
          setIsAuth(false)
          return <Redirect to="/login" />;
      }
    }
  } */

  const getAccessToken = async () => {

    let token = localStorage.getItem('token')

    const currentTime = new Date()
    const token_created = localStorage.getItem('expiresAtC');
    let minutes_since = DifferenceMinutes(currentTime, new Date(Date.parse(token_created)));

    if (minutes_since >= 5) {
      return await refreshAccessToken();
    } else {
      if (token) {
        setIsAuth(true)
        return token
      } else {
        setIsAuth(false)
      }
    }
  }

  // const getAuthenticatedHeaders = async () => {


  //   if (await getAccessToken()) {

  //       Authorization: `Bearer ${localStorage.getItem('token')}`
  //     });

  //     return {
  //       Authorization: `Bearer ${localStorage.getItem('token')}`
  //     }

  //   } else {
  //     localStorage.clear()
  //     setIsAuth(false)
  //     return <Redirect to="/login" />;
  //   }

  //   /* return {
  //     Authorization:await getAccessToken() ?  'Bearer ' + (await getAccessToken()) : '',
  //   }; */
  // };

  const getAuthenticatedHeaders = async (x = false) => {
    try {
      const access_token = await getAccessToken()

      //const access_token = await localStorage.getItem('token')

      if (x === true) {

        return {

          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,


        }

      } else {

        return {
          Authorization: `Bearer ${access_token}`,
        }
      }

    } catch (error) {

    }
  }


  const refreshAccessToken = async () => {
    try {

      const refreshToken = localStorage.getItem('refreshToken');
      const response = await AxioInstance.post(`/api/token/refresh`, { refreshToken })

      if (response.data.access) {
        localStorage.setItem('token', response.data.access)
        localStorage.setItem('expiresAtC', new Date().toString())
        setIsAuth(true)
        return response.data.access
      } else {
        setIsAuth(false)
      }
    } catch (error) {

      setIsAuth(false)
    }
  }

  const DifferenceMinutes = (dt2, dt1) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const value = {
    isSuperUser,
    logOut,
    isAuth,
    permissions,
    checkPermissions,
    checkSuperUser: () => checkSuperUser(),
    setIsAuth,
    getAccessToken: () => getAccessToken(),
    getAuthenticatedHeaders: (x) => getAuthenticatedHeaders(x),
    montoBolsaEfecty,
    setMontoBolsaEfecty,
    setMontoBolsaBaloto,
    montoBolsaBaloto,
    others,
    LevelUser,
    pendingRecargas,
    setPendingRecargas,
    LoadingLogin,
    isOperador,
    isValidator,
    setIsDev,
    IsDev,
    mutual,
    userDetailsContext,
    setUserDetailsContext:x=>setUserDetailsContext(x),
    is_changed_password,
     set_is_changed_password
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default {
  Provider,
  Consumer: AuthContext.Consumer
}
