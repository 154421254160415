import React, { useState, useEffect, useContext } from 'react';
import { Link, Redirect } from "react-router-dom";
import Logo from '../../assets/images/h-logo-cobru.png';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { FunctionContext } from '../../context/FuntionsContext';
import AxioInstance from '../../axios/AxiosConfig';
import logoPath from '../../helpers/logoPath';
import variablesDomain from '../../helpers/variablesDomain';

const Sidebar = (props) => {
  const { setBuscador,
    buscar,
    setBuscar,
    buscarDocument,
    setBuscarDocument,
    showModalRetirar,
    setShowModalRetirar,
    setBuscarRetiro,
    buscarRetiro,
    setBuscarCobrar,
    buscarCobrar,
    buscarEnvios,
    setBuscarEnvios,
    setRenderDataDocuments,
    renderDataDocuments


  } = useContext(FunctionContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userIp, setUserIp] = useState(false);

  //const [superUser, setSuperUser ] = useState(false);
  const lastLogin = formatDate(Date.parse(props.user.last_login));
  const pathname = props.pathname;
  /*  const canViewResumen = props.user.mother_account ? props.user.permission_dashboard : true;
   const canViewReport = props.user.mother_account ? props.user.permission_reports : true;
   const canViewUsers = props.user.mother_account ? props.user.permission_users : true;
   const canViewQuery = props.user.mother_account ? props.user.permission_query : true; */
  //const superUser = props.superUser ? props.superUser : false;
  const { isSuperUser, logOut, permissions, others, pendingRecargas, isOperador, LevelUser, isValidator } = useContext(AuthContext)
  const canViewResumen = permissions.permission_dashboard
  const canViewReport = permissions.permission_reports;
  const canViewUsers = permissions.permission_users;
  const canViewQuery = permissions.permission_query;
  const canViewWithdraw = permissions.permission_withdraw === 0 ? false : true
  const canViewCobru = permissions.permission_cobru === 0 ? false : true
  const canViewcollaboration = permissions.permission_collaborators;


  function formatDate(string) {
    var options = { year: 'numeric', month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(string).toLocaleDateString([], options);
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    const getIp = async () => {
      try {


        const response = await axios.get(`https://ipapi.co/json/`)
        setUserIp(response.data.ip);
        return

      } catch (error) {

        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    }
    getIp()
    return () => {
      source.cancel()
    }

  }, []);

  useEffect(() => {
    setBuscador('')
    setBuscarDocument('')
    setBuscarRetiro('')
    setBuscarCobrar('')
    setBuscarEnvios('')
  }, [menuOpen])




  return others === false ? (
    <>
      <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0" x-data="{ open: false }">
          <div className="flex-shrink-0 px-4 py-4 mt-3 flex flex-row items-center justify-between">

            <Link to="/" className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
              <img className="mx-auto h-10 w-auto" src={logoPath} alt="Logo Cobru Pro"></img>
            </Link>
            <button
              className="text-gray-900 cursor-pointer text-xl leading-none px-3 py-2 border border-solid border-teal-light rounded  block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
            </button>
          </div>
          <nav>
            <div className={
              "flex-grow md:block px-3 pb-4 md:pb-0 md:overflow-y-auto" +
              (menuOpen ? " flex-grow" : " hidden")
            }
              id="navbar-info"
            >
              <ul className="flex-grow flex-col lg:flex-row list-none lg:ml-auto">
                {isSuperUser && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor}dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'dashboard' || pathname === '' ? ` bg-${variablesDomain.classNameColor} text-white` : ``)} to="/dashboard">Dashboard</Link>
                  </li>
                )}
                {isSuperUser && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm   rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'cobrus' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/cobrus">Cobrus</Link>
                  </li>
                )}

                {isSuperUser && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'retiros' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/retiros">Retiros</Link>
                  </li>
                )}
 {isSuperUser && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'efecty' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/efecty">Efecty</Link>
                  </li>
                )}

                {canViewResumen && isSuperUser === false && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'resumen' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/resumen">Resumen</Link>
                  </li>
                )}
                {isSuperUser === false && canViewCobru &&
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'cobrar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/cobrar">Cobros</Link>
                  </li>
                }

                {isSuperUser === false &&
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'enviar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/enviar">Envios</Link>
                  </li>
                }

                {isSuperUser === false && LevelUser > 1 &&
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={"block px-4 py-2 mt-2 text-sm  rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none " + (pathname === 'recargar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/recargar">Recargas</Link>
                  </li>
                }
                {isSuperUser &&
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'enviar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/enviar">Envios</Link>
                  </li>
                }
                {isSuperUser === false &&
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm  rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'retirar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/retirar">Retiros</Link>
                  </li>
                }


                {isSuperUser && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)}
                      className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'recargas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")}
                      to="/recargas">Recargas</Link>
                  </li>
                )}
                 {(isOperador) && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'efecty' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/efecty">Efecty</Link>
                  </li>
                )}
                 {(isOperador) && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'tarifas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/tarifas">Tarifas</Link>
                  </li>
                )}
                 {isSuperUser && (
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)}
                      className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'productos' ? ` bg-${variablesDomain.classNameColor} text-white` : "")}
                      to="/productos">Compras</Link>
                  </li>
                )}



                <div className="py-3 border-b-2 border-gray-300 text-gray-700">
                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'clientes' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/clientes">Clientes</Link>
                    </li>
                  )}
                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => {
                        setMenuOpen(!menuOpen)
                        setRenderDataDocuments(!renderDataDocuments)
                      }} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'documentos' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/documentos">Documentos</Link>
                    </li>
                  )}
                  {isSuperUser || (isOperador || isValidator) && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => {
                        setMenuOpen(!menuOpen)
                        setRenderDataDocuments(!renderDataDocuments)
                      }} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'documentos' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/documentos">Documentos</Link>
                    </li>
                  )}

                  {isSuperUser && !isOperador && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'validacion' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/validacion">Validación</Link>
                    </li>
                  )}

                  {isSuperUser && !isOperador && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'alertas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/alertas">Alertas</Link>
                    </li>
                  )}
                  {isSuperUser && !isOperador && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'debitar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/debitar">Debitar</Link>
                    </li>
                  )}
                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'blacklist' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/blacklist">Blacklist</Link>
                    </li>
                  )}
                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'tarifas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/tarifas">Tarifas</Link>
                    </li>
                  )}
                  {isSuperUser && !isOperador && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'consultar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/consultar">Callbacks</Link>
                    </li>
                  )}
                   {window.location.hostname==='panel.colocapayments.com' && (
                    <li className="nav-item w-9/12">
                      <a onClick={(event) => {
                        event.target.blur()
                      }} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600  dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900  hover:bg-gray-200 focus:text-white focus:outline-none `} href="https://cotizador.colocapayments.com" target="_blank" rel="noopener noreferrer">Envíos internacionales</a>
                     
                    </li>
                  )}
                  {canViewReport && isSuperUser === false && isOperador === false && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'reportes' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/reportes">Reportes</Link>
                    </li>
                  )}
                  {canViewQuery && isSuperUser === false && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'consultar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/consultar">Consultar</Link>
                    </li>
                  )}

                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'reportesdate' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/reportesdate">Reportes</Link>
                    </li>
                  )}
                  {isOperador && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'reportesdate' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/reportesdate">Reportes</Link>
                    </li>
                  )}

                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'cumplimiento' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/cumplimiento">Cumplimiento</Link>
                    </li>
                  )}
                  {isSuperUser && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'cargar' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/cargar">Cargar Usuarios</Link>
                    </li>
                  )}


                  {canViewUsers && isSuperUser === false && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'colaboradores' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/colaboradores">Colaboradores</Link>
                    </li>
                  )}


                  {isSuperUser === false && canViewcollaboration === true && LevelUser >= 2 && <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'integracion' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/integracion">Integración</Link>
                  </li>}
                  {isSuperUser === false && (
                    <li className="nav-item w-9/12">
                      <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'mistarifas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/mistarifas">Mis Tarifas</Link>
                    </li>
                  )}
                  {isSuperUser === false && <li className="nav-item w-9/12">
                    <a onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-xs  rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-${variablesDomain.classNameColor} dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900  hover:bg-gray-200  focus:outline-none `} href="https://www.cobru.co/contactanos/" target="_blank" rel="noopener noreferrer">Ayuda</a>
                  </li>}
                  <li className="nav-item w-9/12">
                    <Link onClick={logOut} className="block px-4 py-2 mt-2 text-xs text-red-500  rounded-full dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none" to="login">
                      Cerrar Sesión
                    </Link>
                  </li>
                </div>
                <div className="text-left px-4 py-2">
                  <p className="text-xs font-semibold mt-3 text-gray-700">Último Ingreso</p>
                  <p className="text-tiny text-gray-700">{lastLogin}</p>
                  <p className="text-tiny text-gray-700">IP: {userIp}</p>
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0" x-data="{ open: false }">
          <div className="flex-shrink-0 px-4 py-4 mt-3 flex flex-row items-center justify-between">

            <Link to="/" className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
              <img className="mx-auto h-10 w-auto" src={Logo} alt="Logo Cobru Pro"></img>
            </Link>
            <button
              className="text-gray-900 cursor-pointer text-xl leading-none px-3 py-2 border border-solid border-teal-light rounded  block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
            </button>
          </div>
          <nav>
            <div className={
              "flex-grow md:block px-3 pb-4 md:pb-0 md:overflow-y-auto" +
              (menuOpen ? " flex-grow" : " hidden")
            }
              id="navbar-info"
            >
              <ul className="flex-grow flex-col lg:flex-row list-none lg:ml-auto">
                {(
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-green-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'dashboard' || pathname === '' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/dashboard">Dashboard</Link>
                  </li>
                )}
                {(
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-green-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'alertas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/alertas">Alertas</Link>
                  </li>
                )}
                {(
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-green-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'cobrus' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/cobrus">Cobrus</Link>
                  </li>
                )}
                {(
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-green-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'retiros' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/retiros">Retiros</Link>
                  </li>
                )}
                {(
                  <li className="nav-item w-9/12">
                    <Link onClick={() => setMenuOpen(!menuOpen)}
                      className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-green-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'recargas' ? ` bg-${variablesDomain.classNameColor} text-white` : "")}
                      to="/recargas">Recargas</Link>
                  </li>
                )}
                {(
                  <li className="nav-item w-9/12">
                    <Link onClick={() => {
                      setMenuOpen(!menuOpen)
                      setRenderDataDocuments(!renderDataDocuments)
                    }} className={`block px-4 py-2 mt-2 text-sm rounded-full dark-mode:hover:bg-gray-600 dark-mode:focus:bg-green-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:bg-${variablesDomain.classNameColor} hover:bg-gray-200 focus:text-white focus:outline-none ` + (pathname === 'documentos' || pathname === '' ? ` bg-${variablesDomain.classNameColor} text-white` : "")} to="/documentos">Documentos</Link>
                  </li>
                )}




                <div className="py-3 border-b-2 border-gray-300 text-gray-700">






                  <li className="nav-item w-9/12">
                    <Link onClick={logOut} className="block px-4 py-2 mt-2 text-xs text-red-500  rounded-full dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none" to="login">
                      Cerrar Sesión
                    </Link>
                  </li>
                </div>
                <div className="text-left px-4 py-2">
                  <p className="text-xs font-semibold mt-3 text-gray-700">Último Ingreso</p>
                  <p className="text-tiny text-gray-700">{lastLogin}</p>
                  <p className="text-tiny text-gray-700">IP: {userIp}</p>
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Sidebar;